import React, { Component } from "react";
import { Tabs } from "antd";
import "./index.less";
import ReceivingTab from "./ReceivingTab";
import TransferTab from "./TransferTab";
import PickingTab from "./PickingTab";
import TransferLocationTab from "./TransferLocationTab";

class Receiving extends Component {
  items = () => [
    {
      label: "RECEIVING",
      key: "item-1",
      children: <ReceivingTab navigate={this.props.navigate} />,
    },
    {
      label: "TRANSFER ITEM WISE",
      key: "item-2",
      children: <TransferTab navigate={this.props.navigate} />,
    },
    {
      label: "TRANSFER LOCATION WISE",
      key: "item-3",
      children: <TransferLocationTab />,
    },
    {
      label: "PICKING",
      key: "item-4",
      children: <PickingTab />,
    },
  ];

  render() {
    return (
      <div className="common-section-container p-0">
        <Tabs className="receving-tabs" items={this.items()} />
      </div>
    );
  }
}

export default Receiving;
