import moment from "moment";
import { dataType, dateFormats } from "../constants";

export const getObjectDeepCopy = (object) => {
  let newObject = JSON.stringify(object);
  return JSON.parse(newObject);
};

export const getDateSeperatedByT = (dateString) => {
  if (dateString) {
    let arry = dateString.split("T");
    if (arry.length > 0) return arry[0];
  } else return "";
};

export const getDateSeperatedByTinYYYYMMDDSlash = (dateString) => {
  if (dateString) {
    let dateSeperated = getDateSeperatedByT(dateString);
    let dateInYYMMDDSalsh = dataFormatterYYYYMMDD(dateSeperated);
    return dateInYYMMDDSalsh;
  } else return "";
};

export const dataFormatterMMDDYYYY = (dateString) => {
  if (isObjectNotEmpty(dateString)) {
    var d = dateString.slice(0, 10).split("-");
    return d[1] + "/" + d[2] + "/" + d[0];
  } else {
    return "";
  }
};

export const dataFormatterMMDDYYYY2 = (dateYYYYMMDD) => {
  if (isObjectNotEmpty(dateYYYYMMDD)) {
    var d = dateYYYYMMDD.slice(0, 10).split("/");
    return d[1] + "/" + d[2] + "/" + d[0];
  } else {
    return "";
  }
};

export const dataFormatterYYYYMMDD = (dateString) => {
  if (isObjectNotEmpty(dateString)) {
    var d = dateString.slice(0, 10).split("-");
    return d[0] + "/" + d[1] + "/" + d[2];
  } else {
    return "";
  }
};

export const isObjectNotEmpty = (obj) => {
  return !isObjectEmpty(obj);
};

export const isObjectEmpty = (obj) => {
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  // null and undefined are "empty"

  if (typeof obj === "number") {
    if (isNaN(obj)) return true;

    return false;
  }

  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const getObjectValue = (obj, property) => {
  return isObjectEmpty(obj) ? "" : obj[property];
};

export const isValueAnInteger = (value) => {
  if (value > 0) {
    return true;
  } else {
    return false;
  }
};

export const tableColumnSorter = (
  obj1,
  obj2,
  filterColumn,
  columnType = dataType.STRING
) => {
  const string1 = isObjectEmpty(obj1[filterColumn]) ? "" : obj1[filterColumn];
  const string2 = isObjectEmpty(obj2[filterColumn]) ? "" : obj2[filterColumn];
  if (columnType === dataType.STRING) return string1.localeCompare(string2);
  else if (columnType === dataType.NUMBER) return string1 - string2;
  else if (columnType === dataType.DATE)
    return (
      moment(string1, dateFormats.YYYY_MM_DD).unix() -
      moment(string2, dateFormats.YYYY_MM_DD).unix()
    );
};

export const formDataSerializeOptions = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,

  /**
   * store arrays even if they're empty
   * defaults to false
   */
  allowEmptyArrays: false,
};

export const getCurretDateWithYYYYMMDDTHHMMSS = () => {
  var currentDate = new Date();

  let month = prefixZeroToNumber(currentDate.getMonth() + 1, 2); // Months start at 0!
  let days = prefixZeroToNumber(currentDate.getDate(), 2);

  let hours = prefixZeroToNumber(currentDate.getHours(), 2);

  let minutes = prefixZeroToNumber(currentDate.getMinutes(), 2);
  let seconds = prefixZeroToNumber(currentDate.getSeconds(), 2);

  const currentDateString = [
    currentDate.getFullYear(),
    month,
    days,
    hours,
    minutes,
    seconds,
  ].join("");

  return currentDateString;
};
export const prefixZeroToNumber = (number, count) => {
  return ("0000000000000" + number).slice(-count);
};

export const getCurrentDateYYYYMMDD = () => {
  var date = new Date();

  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });

  const dateString = year + "/" + month + "/" + day;
  return dateString;
};
