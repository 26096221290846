import React, { Component } from "react";
import { Button, Modal, Select, Input, Form, DatePicker } from "antd";
import { SvgIcon, Row, Col } from "../../../../components/common";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { dateFormats } from "../../../../constants";
import "./index.less";

const orderFilters = [
  {
    title: "Job #",
    fromKey: "str15",
    toKey: "str16",
  },
  {
    title: "Shipping Date",
    fromKey: "dt1",
    toKey: "dt2",
  },
  { title: "Rush Order Type", fromKey: "str13", toKey: "str14" },
  { title: "Warehouse", fromKey: "str7", toKey: "str8" },
  { title: "Item Code", fromKey: "str3", toKey: "str4" },
  { title: "Item Category", fromKey: "str17", toKey: "str18" },
  { title: "Imprint Type", fromKey: "str5", toKey: "str6" },
];

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedFilter: {},
      searchText: "",
    };
  }
  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  isDateFilterSelected = (selectedFilter = this.state.selectedFilter) => {
    return isObjectNotEmpty(selectedFilter) && selectedFilter.fromKey === "dt1";
  };

  clearSearch = () => {
    this.setState({
      selectedFilter: {},
      searchText: "",
    });
  };

  onSearchButtonClicked = () => {
    let filters = {};
    if (
      isObjectNotEmpty(this.state.searchText) &&
      isObjectNotEmpty(this.state.selectedFilter)
    ) {
      //                   var d = new Date();
      //  d.setDate(d.getDate()-5);

      filters = {
        [this.state.selectedFilter.fromKey]: this.state.searchText,
        [this.state.selectedFilter.toKey]:
          this.state.searchText +
          (this.isDateFilterSelected() ? "T23:59:59.999Z" : "ZZZZZ"),
      };
    }
    this.props.getInboxList(filters);
    this.handleCancel();
  };

  componentDidUpdate(prevProps) {
    if (this.props.clearFilter !== prevProps.clearFilter) {
      this.setState({ searchText: "", selectedFilter: {} }, () =>
        this.onSearchButtonClicked()
      );
    }
  }

  render() {
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          <SvgIcon name="search" viewbox="0 0 12 13" />
        </Button>
        <Modal
          title="Search"
          className="picksearch-modal"
          centered
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          footer={[
            <Button
              key={"search"}
              type="primary"
              block
              form="my-form"
              onClick={this.onSearchButtonClicked}
            >
              SEARCH
            </Button>,
          ]}
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="card">
            <div className="card-body">
              <Row>
                <Col>
                  <Form layout="vertical">
                    <Form.Item className="mb-4">
                      <Select
                        suffixIcon={
                          <SvgIcon
                            name="select-arrow"
                            viewbox="0 0 19.124 12.357"
                          />
                        }
                        placeholder="Select"
                        onSelect={(value, option) => {
                          let searchText =
                            this.isDateFilterSelected(option.filter) ===
                            this.isDateFilterSelected()
                              ? this.state.searchText
                              : "";

                          this.setState({
                            selectedFilter: option.filter,
                            searchText: searchText,
                          });
                        }}
                        value={
                          isObjectEmpty(this.state.selectedFilter)
                            ? undefined
                            : this.state.selectedFilter.title
                        }
                      >
                        {orderFilters.map((filter) => (
                          <Select.Option
                            title={filter.title}
                            key={filter.title}
                            filter={filter}
                          >
                            {filter.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      {this.isDateFilterSelected() ? (
                        <DatePicker
                          name="shipDate"
                          allowClear={false}
                          onChange={(moment) => {
                            const dateString = moment.format(
                              dateFormats.YYYYMMDD_SLASH
                            );
                            this.setState({ searchText: dateString });
                          }}
                          format={dateFormats.MM_DD_YYYY}
                        />
                      ) : (
                        <Input
                          name="search"
                          suffix={<SvgIcon name="search" viewbox="0 0 12 13" />}
                          value={this.state.searchText}
                          onChange={(event) =>
                            this.setState({ searchText: event.target.value })
                          }
                          disabled={isObjectEmpty(this.state.selectedFilter)}
                          onPressEnter={this.onSearchButtonClicked}
                        />
                      )}
                    </Form.Item>
                    <div className="text-right">
                      <Button
                        type="danger"
                        ghost
                        className="clear-btn"
                        onClick={this.clearSearch}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default SearchModal;
