import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const stockItemLocationsReducer = (state = [], action) => {
  const actionName = serverApis.getStockItemLocations.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.rows;

    case getResetAction(actionName):
      return [];
    default:
      return state;
  }
};
