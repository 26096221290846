import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const availableStockQtyObjectReducer = (state = {}, action) => {
  const actionName = serverApis.getMultipleItemAvailableStockQty.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return {
        ...state,
        [action.referenceObject]: {
          catalogItemCodes: action.json.result.catalog_item_codes,
          availableItemlocations: action.json.result.sales_order_locations,
        },
      };

    case getResetAction(actionName):
      return {};
    default:
      return state;
  }
};
