import React, { Component } from "react";
import { Button, Form, Input, Spin } from "antd";
import { SvgIcon } from "../../components/common";
import { componentPaths } from "../../constants/component-paths";
import { connect } from "react-redux";
import { createLoadingSelector, loginAction, resetLoginDataAndStore } from "../../redux/actions";
import { serverApis } from "../../constants/server-apis";
import { isObjectNotEmpty } from "../../common/utils";
import { NAVIGATION_REFERENCE } from "../../constants";
import "./index.less";

class LoginView extends Component {
  onFinish = (values) => {
    this.props.loginAction(values);
  };

  componentDidMount() {
    resetLoginDataAndStore();
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.userDetails)) {
      var referer = componentPaths.RECEIVING;
      if (
        isObjectNotEmpty(this.props.location.state) &&
        isObjectNotEmpty(this.props.location.state[NAVIGATION_REFERENCE])
      ) {
        referer = this.props.location.state[NAVIGATION_REFERENCE];
      }
      this.props.navigate(referer, {});
    }
  }

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="common-section-container">
          <div className="login-screen pt-4">
            <h2>Log In</h2>
            <div className="form-inner">
              <Form layout="vertical" onFinish={this.props.loginAction}>
                <Form.Item
                  label="Login ID"
                  className="mb-4"
                  name="login"
                  rules={[
                    { required: true, message: "Please enter Log in ID!" },
                  ]}
                >
                  <Input
                    prefix={<SvgIcon name="email" viewbox="0 0 26 18" />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Enter your Password"
                  rules={[
                    { required: true, message: "Please enter password!" },
                  ]}
                >
                  <Input
                    type="password"
                    prefix={<SvgIcon name="lock" viewbox="0 0 19 26" />}
                  />
                </Form.Item>
                <div className="mt-3">
                  <Button className="primary-btn w-100" htmlType="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([serverApis.login.actionName]);

const mapStateToProps = (state) => ({
  userDetails: state.loginDetails,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  loginAction,
  resetLoginDataAndStore,
})(LoginView);
