import {
  dispatchWithResponseAndType,
  getResetAction,
} from "../../constants/actions-methods";
import _ from "lodash";
// eslint-disable-next-line no-unused-vars
import { baseServerAdress } from "../../constants/server-address";
import {
  clearLoginData,
  saveLoggedInUserDetails,
  getActionCreatorObject,
  loggedInUserDetail,
} from "../../common/methods";
import { serverApis } from "../../constants/server-apis";
import { showAlert } from "../../common/alert-messages";
import { getActionNameWithStatus } from "../../constants/actions-methods";
import { requestStatus, resetActions } from "../../constants/request";
import { componentPaths } from "../../constants/component-paths";
import {
  formDataSerializeOptions,
  getCurrentDateYYYYMMDD,
} from "../../common/utils";
import { serialize } from "object-to-formdata";

const REQUEST_TIMEOUT = 180000;

//////////////  COMMON ACTIONS START ////////////////////

export function setStoreState(stateName, referenceObject) {
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, stateName, referenceObject));
  };
}

export function resetStoreState(stateName, referenceObject) {
  return async (dispatch) => {
    dispatch(
      dispatchWithResponseAndType(
        null,
        getResetAction(stateName),
        referenceObject
      )
    );
  };
}

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return (
    _(actions)
      .map((action) => _.get(state, `errorMessages.${action}`))
      .compact()
      .first() || ""
  );
};

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return _(actions).some((action) => {
    return _.get(state, `loadingApis.${action}`);
  });
};

export async function requestCreator(requestObject, dispatch) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
  let responseData;
  dispatch(
    dispatchWithResponseAndType(
      null,
      getActionNameWithStatus(requestObject.actionType, requestStatus.REQUEST)
    )
  );
  let responseJson = await fetch(requestObject.requestURL, {
    method: requestObject.requestMethod,
    headers: requestObject.requestHeader,
    body: requestObject.requestPayload,
    signal: controller.signal,
    // withCredentials: true,
    //credentials: "same-origin",
  })
    .then((data) => {
      clearTimeout(timeoutId);
      responseData = data;
      return data.json();
    })
    .catch((error) => {
      if (error.name === "AbortError") {
        responseData = { status: 1001 }; ///server request time out error
      } else {
        responseData = {
          status: 1000, /////other errors
          error: error.toString(),
        };
      }
    });

  return { responseData, responseJson };
}

export async function actionCreator(requestObject, dispatch, referenceObject) {
  // if (requestObject.actionType !== Type.DASHBOARD_DETAILS) {
  //   dispatch(getDashboardDetails());
  // }
  const { responseData, responseJson } = await requestCreator(
    requestObject,
    dispatch
  );

  checkResponseStatusAndDispatchAction(
    dispatch,
    responseData,
    responseJson,
    requestObject.actionType,
    referenceObject
  );
}

function checkResponseStatusAndDispatchAction(
  dispatch,
  data,
  response,
  actionType,
  referenceObject
) {
  let errorMessage;
  switch (data.status) {
    case 200:
      // if (actionType === Type.LOGIN) {
      //   history.push(componentPaths.dashboard);
      // }
      if (response && response.message.toLowerCase() === "success") {
        dispatch(
          dispatchWithResponseAndType(
            response.data,
            getActionNameWithStatus(actionType, requestStatus.SUCCESS),
            referenceObject
          )
        );
        return;
      } else {
        errorMessage = response.error;
      }

      break;
    case 422:
    case 405: ///405 422
      errorMessage = response.error;
      dispatch(
        dispatchWithResponseAndType(
          errorMessage,
          getActionNameWithStatus(actionType, requestStatus.FAILURE)
        )
      );
      showAlert.error(errorMessage);
      if (
        errorMessage === "Invalid Session or Not Authorized. Please re-login."
      ) {
        //// case of authentication failed
        resetLoginDataAndStore();
        if (window.location.pathname !== componentPaths.LOGIN) {
          window.location = componentPaths.LOGIN;
        }
      }

      return;
    // case 400:
    // case 405:
    // case 404:
    //   errorMessage = response.error;
    //   break;
    case 1000:
      errorMessage = data.error; //// error code
      break;

    case 1001:
      errorMessage = "Request Time Out!"; ///  request time out error code
      break;

    default:
      errorMessage = response ? response.error : data.statusText;
  }
  showAlert.error(errorMessage);
  dispatch(
    dispatchWithResponseAndType(
      errorMessage,
      getActionNameWithStatus(actionType, requestStatus.FAILURE)
    )
  );
}

//////////////  COMMON ACTIONS ENDS ////////////////////

export function resetLoginDataAndStore() {
  clearLoginData();
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, resetActions.RESET_ALL_STATES));
  };
}

export function loginAction(params) {
  const apiInfo = serverApis.login;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);

  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );

    if (responseData.status === 200) {
      saveLoggedInUserDetails(responseJson.data.user);
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      serverApis.login.actionName
    );
  };
}

export function logoutAction() {
  const apiInfo = serverApis.logout;

  let actionCreatorObject = getActionCreatorObject(apiInfo);

  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getVendorListAction(isShippingVendor) {
  const userDetails = loggedInUserDetail();

  const params = {
    params: {
      action: "limitedlookup",
      value: "",
      lookup_name: isShippingVendor ? "ShippingVendor" : "Vendor",
      filter_key_name: isShippingVendor ? "shippingvendor_flag" : "",
      filter_key_value: isShippingVendor ? "Y" : "",
      company_id: userDetails.company_id,
      user_id: "1", //userDetails.id,
    },
  };
  const apiInfo = serverApis.getVendorList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getReceiveTypeListAction() {
  const params = { type_cd: "Trans_Type", subtype_cd: "PR" };
  const apiInfo = serverApis.getReceiveTypeList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getShipmentNumberListAction(vendorId) {
  const userDetails = loggedInUserDetail();
  const params = {
    criterias: [
      {
        default_request: "N",
        int1: vendorId, //shipment id
        int2: vendorId, //shipment id
        company_id: userDetails.company_id,
      },
    ],
  };

  const apiInfo = serverApis.getShipmentNumberList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getShipmentLineListAction(shipmentId) {
  const userDetails = loggedInUserDetail();

  const params = {
    criterias: [
      {
        default_request: "N",
        int1: shipmentId, //shipment id
        int2: shipmentId, //shipment id
        company_id: userDetails.company_id,
      },
    ],
  };

  const apiInfo = serverApis.getShipmentLineList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getOpenPurchaseLineListAction(vendorCode) {
  const userDetails = loggedInUserDetail();

  const params = {
    criterias: [
      {
        default_request: "N",
        str1: vendorCode,
        str2: vendorCode + "zzzz",
        company_id: userDetails.company_id,
        str3: "",
        str4: "zzzz",
      },
    ],
  };

  const apiInfo = serverApis.getOpenPurchaseLineList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getBarCodeShipmentLineListAction(
  barcode,
  vendorId,
  shipmentNumber
) {
  const params = {
    barcode: barcode,
    vendor_id: vendorId,
    shipment_no: shipmentNumber,
  };

  const apiInfo = serverApis.getBarCodeShipmentLineList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getAttachmentTypeListAction() {
  const params = { type_cd: "purchase_receive", subtype_cd: "attachment_type" };

  const apiInfo = serverApis.getAttachmentTypeList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getFloorPlanAction(params) {
  const apiInfo = serverApis.getFloorPlan;
  let requestObject = { ...params, flat_response: "Y" };

  let actionCreatorObject = getActionCreatorObject(apiInfo, requestObject);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function uploadAttachmentAction(attachmentDetails) {
  const apiInfo = serverApis.uploadAttachment;

  const params = serialize(attachmentDetails, formDataSerializeOptions);

  let actionCreatorObject = getActionCreatorObject(apiInfo, params, true);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function submitPurchaseReceiveAction(requestObject) {
  const params = {
    ...requestObject,
  };

  const apiInfo = serverApis.submitPurchaseReceive;
  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getAcountPeriodCodeAction() {
  const userDetails = loggedInUserDetail();

  const params = {
    params: {
      action: "accountperiod",
      id: getCurrentDateYYYYMMDD(),
      company_id: userDetails.company_id,
      user_id: userDetails.id,
    },
  };

  const apiInfo = serverApis.getAccountPeriodCode;
  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getStockItemListAction() {
  const userDetails = loggedInUserDetail();

  const params = {
    params: {
      action: "limitedlookup",
      value: "",
      lookup_name: "AvailableStockItem",
      filter_key_name: "",
      filter_key_value: "",
      company_id: userDetails.company_id,
      user_id: userDetails.id,
    },
  };
  const apiInfo = serverApis.getStockItemList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getStockItemInfoAction(itemId) {
  const userDetails = loggedInUserDetail();

  const params = {
    params: {
      action: "iteminfo",
      item_id: itemId,
      trans_date: getCurrentDateYYYYMMDD(),
      trans_type: "Y",
      trans_id: 1,
      stock_check: "N",
      company_id: userDetails.company_id,
      user_id: userDetails.id,
    },
  };
  const apiInfo = serverApis.getStockItemInfo;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getStockItemLocationsAction(itemCode) {
  const userDetails = loggedInUserDetail();

  const params = {
    criterias: [
      {
        default_yn: "N",
        default_request: "N",
        company_id: 1,
        criteria_type: "U",
        user_id: userDetails.id,
        document_id: 622,
        str1: itemCode, //item code
        str2: itemCode,
        str3: "",
        str4: "",
        str5: "",
        str6: "",
        str7: "",
        str8: "",
        str9: "",
        str10: "",
        str11: "",
        str12: "",
        all1: "Y",
        all2: "Y",
        all3: "",
        all4: "",
        all5: "Y",
        all6: "",
        all7: "",
        all8: "",
        all9: "",
        all10: "",
        multiselect1: "",
        multiselect2: "",
        multiselect3: "",
        multiselect4: "",
        multiselect5: "",
        multiselect6: "",
      },
    ],
  };
  const apiInfo = serverApis.getStockItemLocations;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function submitLocationTransferAction(requestObject) {
  const params = {
    ...requestObject,
  };

  const apiInfo = serverApis.submitLocationTransfer;
  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getWarehouseListAction() {
  const userDetails = loggedInUserDetail();

  const params = {
    criterias: [
      {
        default_yn: "Y",
        company_id: userDetails.company_id,
        criteria_type: "U",
        user_id: 1,
        document_id: 8663,
        str1: "",
        str2: "zzzz",
        str3: "",
        str4: "zzzz",
        all1: "Y",
        all2: "Y",
        default_request: "N",
      },
    ],
  };
  const apiInfo = serverApis.getWarehouseList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getProductionPickInboxListAction(
  filters = {},
  pageNumber,
  pageSize
) {
  let user = loggedInUserDetail();
  let params = {
    criteria: {
      // trans_flag: "Y",
      // name: "Select View",
      // criteria_type: "T",
      // document_id: "5517",
      company_id: user.company_id,
      user_id: user.id,
      default_yn: "Y",
      default_request: "N",
      str1: "",
      str2: "zzzz",
      str3: "",
      str4: "zzzz",
      str5: "",
      str6: "zzzz",
      str7: "",
      str8: "zzzz",
      str9: "ALL",
      str10: "ALL",
      str11: "ALL",
      dt1: "1989-12-31T13:30:00.000-05:00",
      dt2: "2025-12-11T13:30:00.000-05:00",
      dec1: "-9999999999.99",
      dec2: "9999999999.99",
      all1: "Y",
      all2: "Y",
      all3: "Y",
      all4: "Y",
      all5: "Y",
      all6: "Y",
      all7: "N",
      all8: "N",
      all9: "N",
      all10: "",

      ...filters,
      page_number: pageNumber,
      rows_per_page: pageSize,
    },
  };

  const apiInfo = serverApis.productionPickInboxList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getProductionUnPickInboxListAction(
  filters = {},
  pageNumber,
  pageSize
) {
  let user = loggedInUserDetail();
  let params = {
    criteria: {
      // document_id: 5517,
      company_id: user.company_id,
      user_id: user.id,
      default_yn: "Y",
      default_request: "N",
      str1: "",
      str2: "zzzz",
      str3: "",
      str4: "zzzz",
      str5: "",
      str6: "zzzz",
      str7: "",
      str8: "zzzz",
      str9: "ALL",
      str10: "ALL",
      str11: "ALL",
      dt1: "1989-12-31T13:30:00.000-05:00",
      dt2: "2025-12-11T13:30:00.000-05:00",
      dec1: "-9999999999.99",
      dec2: "9999999999.99",
      all1: "Y",
      all2: "Y",
      all3: "Y",
      all4: "Y",
      all5: "Y",
      all6: "Y",
      all7: "N",
      all8: "N",
      all9: "N",
      all10: "",
      page_number: pageNumber,
      rows_per_page: pageSize,

      ...filters,
    },
  };

  const apiInfo = serverApis.productionUnPickInboxList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getAvailableStockQtyAction(order) {
  const params = {
    trans_no: order.trans_no,
    item_qty: order.item_qty,
  };
  const apiInfo = serverApis.getAvailableStockQty;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch, order.trans_no);
  };
}

export function getMultipleItemAvailableStockQtyAction(order) {
  const params = {
    params: [
      {
        inventory_items: order.inventory_items,
        sales_order_id: order.sales_order_id,
      },
    ],
  };
  const apiInfo = serverApis.getMultipleItemAvailableStockQty;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch, order.trans_no);
  };
}

export function saveProductionPickInbox(orders) {
  const apiInfo = serverApis.saveProductionPickInbox;
  let params = {
    sales_orders: orders,
  };

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveProductionUnPickInboxAction(salesOrderId) {
  const apiInfo = serverApis.saveProductionUnPickInbox;
  let params = {
    params: {
      sales_order_id: salesOrderId,
    },
  };

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch, salesOrderId);
  };
}

export function getProductionPickInboxScanItemAction(jobNumber) {
  let user = loggedInUserDetail();
  let params = {
    criteria: {
      // trans_flag: "Y",
      // name: "Select View",
      // criteria_type: "T",
      // document_id: "5517",
      company_id: user.company_id,
      user_id: user.id,
      default_yn: "Y",
      default_request: "N",
      str1: "",
      str2: "zzzz",
      str3: "",
      str4: "zzzz",
      str5: "",
      str6: "zzzz",
      str7: "",
      str8: "zzzz",
      str9: "ALL",
      str10: "ALL",
      str11: "ALL",
      dt1: "1989-12-31T13:30:00.000-05:00",
      dt2: "2025-12-11T13:30:00.000-05:00",
      dec1: "-9999999999.99",
      dec2: "9999999999.99",
      all1: "Y",
      all2: "Y",
      all3: "Y",
      all4: "Y",
      all5: "Y",
      all6: "Y",
      all7: "N",
      all8: "N",
      all9: "N",
      all10: "",

      str15: jobNumber,
      str16: jobNumber,
      page_number: 1,
      rows_per_page: 10,
    },
  };

  const apiInfo = serverApis.productionPickInboxScanItem;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getProductionUnPickInboxScanItemAction(jobNumber) {
  let user = loggedInUserDetail();
  let params = {
    criteria: {
      // document_id: 5517,
      company_id: user.company_id,
      user_id: user.id,
      default_yn: "Y",
      default_request: "N",
      str1: "",
      str2: "zzzz",
      str3: "",
      str4: "zzzz",
      str5: "",
      str6: "zzzz",
      str7: "",
      str8: "zzzz",
      str9: "ALL",
      str10: "ALL",
      str11: "ALL",
      dt1: "1989-12-31T13:30:00.000-05:00",
      dt2: "2025-12-11T13:30:00.000-05:00",
      dec1: "-9999999999.99",
      dec2: "9999999999.99",
      all1: "Y",
      all2: "Y",
      all3: "Y",
      all4: "Y",
      all5: "Y",
      all6: "Y",
      all7: "N",
      all8: "N",
      all9: "N",
      all10: "",

      str15: jobNumber,
      str16: jobNumber,
      page_number: 1,
      rows_per_page: 10,
    },
  };

  const apiInfo = serverApis.productionUnPickInboxScanItem;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getAllBinLocationsListAction() {
  const userDetails = loggedInUserDetail();

  const params = {
    params: {
      action: "limitedlookup",
      value: "",
      lookup_name: "Location",
      filter_key_name: "company_id",
      filter_key_value: 1,
      company_id: userDetails.company_id,
      user_id: userDetails.id,
    },
  };
  const apiInfo = serverApis.getAllBinLocationsList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getAllItemsInBinAction(binCode) {
  const userDetails = loggedInUserDetail();

  const params = {
    criterias: [
      {
        default_yn: "N",
        default_request: "N",
        company_id: userDetails.company_id,
        user_id: userDetails.id,
        str1: "",
        str2: "",
        str3: "",
        str4: "",
        str5: "",
        str6: "",
        str7: "",
        str8: "",
        str9: "",
        str10: "",
        str11: binCode,
        str12: binCode,
        all1: "   Y",
        all2: "Y",
        all3: "",
        all4: "",
        all5: "Y",
        all6: "",
        all7: "",
        all8: "",
        all9: "",
        all10: "",
        multiselect1: "",
        multiselect2: "",
        multiselect3: "",
        multiselect4: "",
        multiselect5: "",
        multiselect6: "",
      },
    ],
  };
  const apiInfo = serverApis.getAllItemsInBin;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch, binCode);
  };
}

export function getBinLocationByBarcodeAction(barcode) {
  // const userDetails = loggedInUserDetail();

  const params = {
    criteria: {
      str1: barcode,
    },
  };
  const apiInfo = serverApis.getBinLocationByBarcode;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getShippmentAttachmentListAction(shippmentId) {
  // const userDetails = loggedInUserDetail();

  console.log(shippmentId);

  const params = {
    criteria: {
      default_request: "N",
      str1: "vendor_shipment",
      int1: shippmentId,
    },
  };

  const apiInfo = serverApis.getShippmentAttachmentList;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

//new
export function getPalletBarcodeSlipAction(shipmentId) {
  const params = {
    params: {
      id: shipmentId,
    },
  };
  const apiInfo = serverApis.getPalletBarcode;

  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getInnerCartonsAction(shipmentId) {
  const params = {
    params: {
      id: shipmentId,
    },
  };
  const apiInfo = serverApis.getInnerCartons;
  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getMasterCartonsAction(shipmentId) {
  const params = {
    params: {
      id: shipmentId,
    },
  };
  const apiInfo = serverApis.getMasterCartons;
  let actionCreatorObject = getActionCreatorObject(apiInfo, params);
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
