import React, { Component } from "react";
import {
  Button,
  Modal,
  Input,
  Checkbox,
  Form,
  Slider,
  Spin,
  AutoComplete,
  Select,
} from "antd";
import { SvgIcon, Row, Col } from "../../../../components/common";
import FloorPlansBox from "../FloorPlansBox";
import {
  createLoadingSelector,
  getFloorPlanAction,
  submitLocationTransferAction,
  getWarehouseListAction,
  resetStoreState,
} from "../../../../redux/actions";
import { serverApis } from "../../../../constants/server-apis";
import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { getFormatedFloorPlan, isFlagTrueY } from "../../../../common/methods";
import "./index.less";

const marks = {
  1: ".35",
  25: ".50",
  35: ".75",
  50: "1",
  65: "1.5",
  75: "2",
  100: "3",
};

class FloorPlaneView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      inputValue: 1,
      warehouse: "",
      selectedRack: {},
    };
  }

  showModal = () => {
    this.setState({ isModalOpen: true }, () => {
      if (isObjectEmpty(this.props.warehouseList)) {
        this.props.getWarehouseListAction();
      }
    });
  };

  getFloorPlan = (warehouse = this.state.warehouse) => {
    const requestObject = {
      catalog_item_code: this.props.selectedStockItem.store_code,
      warehouse_code: warehouse,
    };
    this.props.getFloorPlanAction(requestObject);
  };

  handleCancel = () => {
    this.props.resetStoreState(serverApis.getFloorPlan.actionName);
    this.setState({ isModalOpen: false, warehouse: "", selectedRack: {} });
  };

  onCloseFloorPlansBox = () => {
    this.setState({ selectedRack: {} });
  };

  onChange = (newValue) => {
    if (isNaN(newValue)) {
      return;
    }
    this.setState({ inputValue: newValue });
  };

  getFloorPlanBoxName = (containerDetails) => {
    const alreadySelected = containerDetails.bins.some((bin) =>
      isObjectNotEmpty(this.props.selectedDestinationLocations[bin.code])
    );

    if (!isFlagTrueY(containerDetails.valid_section_aisle)) {
      return "red-box";
    } else if (alreadySelected) {
      return "blue-box-checked";
    } else if (isFlagTrueY(containerDetails.item_already_placed)) {
      return "green-box";
    } else {
      return "";
    }
  };

  componentDidUpdate() {
    // if (isObjectNotEmpty(this.props.submitLocationTransferSuccessMessage)) {
    //   this.setState({ isModalOpen: false });
    //   this.props.resetStoreState(serverApis.getFloorPlan.actionName);
    // }
  }

  render() {
    let floorPlan = [];
    let floorPlanRacks = [];

    if (this.state.isModalOpen) {
      floorPlan = getFormatedFloorPlan(
        this.props.floorPlan,
        this.props.selectedStockItem.store_code
      );

      floorPlan.forEach((row) => {
        if (isObjectNotEmpty(row.floor_plan_rows)) {
          row.floor_plan_rows.forEach((rack) => {
            floorPlanRacks.push({
              ...rack,
              rackLocationTitle: `${rack.aisle}-${rack.section}`,
            });
          });
        }
      });
    }

    return (
      <>
        <Button
          onClick={() => this.showModal()}
          // className="primary-btn bottom-btn"
          className="primary-btn"
          htmlType="submit"
          block
          disabled={!(Number(this.props.totalItemsToTransfer) > 0)}
        >
          Choose Destination
        </Button>
        <Modal
          title="Floor Plan"
          className="floor-model"
          centered
          open={this.state.isModalOpen}
          onCancel={this.handleCancel}
          footer={null}
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          maskClosable={false}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="floor-wrapper">
              <div className="floor-head-wrapper">
                <Row>
                  <Col>
                    <AutoComplete
                      options={this.props.warehouseList.map((warehouse) => {
                        return {
                          value: warehouse.code,
                        };
                      })}
                      placeholder="Select warehouse"
                      // filterOption={(inputValue, option) =>
                      //   option.value
                      //     .toUpperCase()
                      //     .indexOf(inputValue.toUpperCase()) !== -1
                      // }
                      onSelect={(value, option) => {
                        this.setState({ warehouse: value });
                        this.getFloorPlan(value);
                      }}
                      value={this.state.warehouse}
                    />
                  </Col>
                  <Col>
                    <Select
                      placeholder="Select Rack"
                      className="bin-select"
                      onSelect={(item, selectedOption) => {
                        this.setState({
                          selectedRack: selectedOption.rack,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.selectedRack.rackLocationTitle}
                      showSearch
                      disabled={isObjectEmpty(this.state.warehouse)}
                    >
                      {floorPlanRacks.map((rack) => (
                        <Select.Option
                          title={rack.rackLocationTitle}
                          key={rack.rackLocationTitle}
                          value={rack.rackLocationTitle}
                          rack={rack}
                        >
                          {rack.rackLocationTitle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <div className="d-flex align-items-center mt-2 pt-1">
                  <Checkbox
                    className="floorplan-list checkbox-cus ml-0"
                    checked={true}
                  >
                    Contains Block
                  </Checkbox>
                  <Checkbox
                    className="floor-checkbox checkbox-cus ml-0"
                    checked={false}
                  >
                    N Quantity' Space Available
                  </Checkbox>
                  <Checkbox
                    className="floor-checkbox checkbox-cus checkbox-red ml-0"
                    checked={false}
                  >
                    Not Available
                  </Checkbox>
                </div>
              </div>
              <div className="floorplan-list-wrapper">
                <div
                  style={{
                    zoom: this.state.inputValue,
                  }}
                >
                  {isObjectNotEmpty(floorPlan) &&
                    floorPlan.map((columns, columnIndex) => (
                      <div className="floorplan-list" key={columnIndex}>
                        {columns.floor_plan_rows.map(
                          (containerDetails, rowIndex) => (
                            <FloorPlansBox
                              key={rowIndex}
                              className={this.getFloorPlanBoxName(
                                containerDetails
                              )}
                              selectedSourceLocations={
                                this.props.selectedSourceLocations
                              }
                              containerDetails={containerDetails}
                              totalItemsToTransfer={
                                this.props.totalItemsToTransfer
                              }
                              totalItemsAssigned={this.props.getItemQuantityAssigned(
                                this.props.selectedStockItem.store_code
                              )}
                              selectedStockItem={this.props.selectedStockItem}
                              selectedDestinationLocations={
                                this.props.selectedDestinationLocations
                              }
                              updateDestinationLocations={
                                this.props.updateDestinationLocations
                              }
                              selectedOtherItemDestinationLocations={
                                this.props.selectedOtherItemDestinationLocations
                              }
                              onCloseFloorPlansBox={this.onCloseFloorPlansBox}
                              selectedRack={this.state.selectedRack}
                              removeDestinationBinRow={
                                this.props.removeDestinationBinRow
                              }
                            />
                          )
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="floorplan-footer">
              <div className="slider-with-sign">
                <div className="icons">-</div>
                <Slider
                  tooltip={{ open: false }}
                  marks={marks}
                  step={0.25}
                  min={0.25}
                  max={3}
                  defaultValue={50}
                  onChange={this.onChange}
                  value={this.state.inputValue}
                />
                <div className="icons">+</div>
              </div>
              <div className="footer-top  py-2 d-flex">
                <Form.Item label="Items to Transfer" className="w-50 mb-0 mr-2">
                  <Input value={this.props.totalItemsToTransfer} disabled />
                </Form.Item>
                <Form.Item
                  label="Items Assigned"
                  className="w-50 mb-0 justify-content-end d-flex"
                >
                  <Input
                    value={this.props.getItemQuantityAssigned(
                      this.props.selectedStockItem.store_code
                    )}
                    disabled
                  />
                </Form.Item>
              </div>
              <Button type="primary" block onClick={this.handleCancel}>
                Aisle Assignment Complete
              </Button>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getFloorPlan.actionName,
  serverApis.getWarehouseList.actionName,
  serverApis.submitLocationTransfer.actionName,
]);

const mapStateToProps = (state) => ({
  stockItemInfo: state.stockItemInfo,
  floorPlan: state.floorPlan,
  warehouseList: state.warehouseList,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getFloorPlanAction,
  submitLocationTransferAction,
  getWarehouseListAction,
  resetStoreState,
})(FloorPlaneView);
