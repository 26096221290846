import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Spin } from "antd";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import {
  createLoadingSelector,
  resetStoreState,
} from "../../../../redux/actions";
import { serverApis } from "../../../../constants/server-apis";
import { CustomTable, SvgIcon } from "../../../../components/common";
import { showAlert } from "../../../../common/alert-messages";
import { isObjectNotEmpty, isValueAnInteger } from "../../../../common/utils";

const ScanQuantityModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scanText, setScanText] = useState("");
  //const [rejectedQuantity, setRejectedQuantity] = useState(0);

  const binLocationByBarcode = useSelector(
    (state) => state.binLocationByBarcode
  );

  const [arryPackages, setArryPackages] = useState([]);
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([
    serverApis.getBinLocationByBarcode.actionName,
  ]);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setArryPackages([]);
    setScanText("");
  };

  const onDelete = (index) => {
    arryPackages.splice(index, 1);
    setArryPackages([...arryPackages]);
  };

  const columns = [
    {
      title: <span className="selection-text">Package Name</span>,
      className: "search-cell",
      width: 100,
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => "Package " + (index + 1),
    },
    {
      title: <span className="selection-text">Quantity</span>,
      className: "search-cell",
      width: 100,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: <span className="selection-text"></span>,
      className: "search-cell",
      width: 40,
      dataIndex: "fname",
      key: "fname",
      render: (text, record, index) => {
        return (
          <SvgIcon
            name="delete"
            viewbox="0 0 18 20"
            className="delete-icon"
            onClick={() => onDelete(index)}
          />
        );
      },
    },
  ];

  const totalScannedQty = () => {
    var totalQty = 0;

    arryPackages.forEach((pack) => {
      totalQty = Number(pack.quantity) + totalQty;
    });

    return Number(totalQty.toFixed(4));
  };

  const isValidBarCode = (scanText) => {
    let valid = true;

    if (scanText.length !== 10) {
      showAlert.error("Not a valid 10 digit bardcode!");
      valid = false;
    } else if (!isValueAnInteger(scanText)) {
      valid = false;
    } else if (Number(scanText.substring(0, 5)) !== Number(props.itemId)) {
      showAlert.error("Item code does not match with barcode!");
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    if (isObjectNotEmpty(binLocationByBarcode) && isModalOpen) {
      dispatch(resetStoreState(serverApis.getBinLocationByBarcode.actionName));
    }
  }, [binLocationByBarcode, dispatch, isModalOpen]);

  return (
    <>
      <Button
        disabled={props.disabled}
        id="button"
        className="receive-qty-btn"
        onClick={showModal}
      >
        Scan
        <SvgIcon name="qr-icons" viewbox="0 0 20 17" />
      </Button>
      <Modal
        title={
          "Add Quantity " + props.itemCode
          //+
          // " " +
          // "001012" +
          // " " +
          // `0${props.itemId}02000`
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="receive-qty-modal"
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            size="small"
            disabled={totalScannedQty() === 0}
            onClick={() => {
              if (props.onAddQuantity) {
                props.onAddQuantity(totalScannedQty());
              }
              handleCancel();
            }}
          >
            ADD{`(${totalScannedQty()})`}
          </Button>,
        ]}
      >
        <Spin spinning={isFetchingData} size="large">
          <div id="1">
            <Form.Item label="" className="mb-0 mt-3">
              <Input
                placeholder="Scan  Quantity Barcode"
                prefix={
                  <SvgIcon
                    name="qr-icons"
                    viewbox="0 0 20 17"
                    className="mr-2"
                  />
                }
                value={scanText}
                onChange={(event) => {
                  // if (!isNaN(event.target.value)) {
                  setScanText(event.target.value);
                  //}
                }}
                onFocus={() => {
                  setScanText("");
                }}
                onPressEnter={(event) => {
                  if (isValidBarCode(event.target.value)) {
                    let barQuantity = Number(
                      event.target.value.substring(5, 10)
                    ).toString();
                    setScanText(barQuantity);
                    setArryPackages([
                      ...arryPackages,
                      {
                        quantity: barQuantity,
                        index: arryPackages.length + 1,
                      },
                    ]);
                  } else {
                  }
                  setScanText("");
                }}
              />
            </Form.Item>
          </div>
          {/* <div id="2">
            Rejected Quantity
            <Input
              // placeholder="Scan Location Barcode..."
              prefix={
                <SvgIcon name="qr-icons" viewbox="0 0 20 17" className="mr-2" />
              }
              value={rejectedQuantity}
              onChange={(event) => {
                let number = Number(event.target.value);
                if (isNaN(number)) {
                  return;
                }
                setRejectedQuantity(number);
              }}
            />
          </div> */}
          <div className="table-wrapper mt-4">
            <CustomTable
              className="common-table with-custom-search with-selection"
              columns={columns}
              dataSource={arryPackages}
              pagination={false}
              scroll={{ y: "calc(100vh - 250px)" }}
              rowKey="index"
            />
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ScanQuantityModal;
