/* eslint-disable no-loop-func */
import React, { Component } from "react";
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Empty,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  submitLocationTransferAction,
  resetStoreState,
  getAllBinLocationsListAction,
  getAllItemsInBinAction,
  getAcountPeriodCodeAction,
} from "../../../redux/actions";
import { SvgIcon, Row, Col } from "../../../components/common";
import "./index.less";
import { showAlert } from "../../../common/alert-messages";
import {
  dataFormatterMMDDYYYY,
  getCurrentDateYYYYMMDD,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../common/utils";
import { loggedInUserDetail } from "../../../common/methods";
import ScanQuantityModal from "./ScanQuantityModal";

const { Panel } = Collapse;

class TransferLocationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanelKeyslocation: ["1"],
      activePanelKeys: [],
      showAddNewItem: true,
      selectedLocations: [],
      transferQtys: {},
      isDestinationSelectorOpen: false,
      allBinLocationsList: [],
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    this.props.getAllBinLocationsListAction();
    this.props.getAcountPeriodCodeAction();
  }

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.allItemsInBin)) {
      let selectedLocation = this.state.selectedLocations.find(
        (location) => location.code === this.props.allItemsInBin.binCode
      );
      if (isObjectNotEmpty(selectedLocation)) {
        selectedLocation.items = this.props.allItemsInBin.items;
      }

      let locationTransferQtys = {};

      selectedLocation.items.forEach((item) => {
        locationTransferQtys[
          this.props.allItemsInBin.binCode + item.item_code + item.expiry_date
        ] = {
          transferQty: 0,
          destinationLocationCode: "",
          destinationLocationId: "",
        };

        item.selectedDestinationLocations = [];
      });

      this.setState({
        transferQtys: {
          ...this.state.transferQtys,
          ...locationTransferQtys,
        },
      });
      this.props.resetStoreState(serverApis.getAllItemsInBin.actionName);
    }

    if (isObjectNotEmpty(this.props.submitLocationTransferSuccessMessage)) {
      this.setState({
        activePanelKeys: [],
        showAddNewItem: true,
        selectedLocations: [],
        transferQtys: {},
      });

      showAlert.success("Items transfered successfully!");
      this.props.resetStoreState(serverApis.submitLocationTransfer.actionName);
    }

    if (
      isObjectNotEmpty(this.props.allBinLocationsList) &&
      isObjectEmpty(this.state.allBinLocationsList)
    ) {
      let allBinLocationsList = this.props.allBinLocationsList.map(
        (binLocation) => (
          <Select.Option
            code={binLocation.code}
            id={binLocation.id}
            name={binLocation.name}
            key={binLocation.code}
          >
            {binLocation.name}
          </Select.Option>
        )
      );

      this.setState({ allBinLocationsList: allBinLocationsList });
    }
  }

  submitLocationTransfer = () => {
    const userDetails = loggedInUserDetail();

    let serialNumber = 100;

    let sourceLocationsAll = [];
    let destinationLocationsAll = [];

    this.state.selectedLocations.forEach((selectedSourceLocation) => {
      selectedSourceLocation.items.forEach((item) => {
        if (isObjectNotEmpty(item.selectedDestinationLocations)) {
          let totalTransferQty = 0;

          item.selectedDestinationLocations.forEach((destinationLocation) => {
            totalTransferQty =
              totalTransferQty + Number(destinationLocation.transferQty);
          });

          serialNumber = serialNumber + 1;
          sourceLocationsAll.push({
            catalog_item_id: item.catalog_item_id,
            catalog_item_batch_id: "",
            catalog_item_packet_id: "",
            location_id: item.location_id,
            expiry_date: item.expiry_date,
            item_description: item.item_name,
            iss_qty: totalTransferQty,
            iss_price: item.item_price,
            iss_amt: Number(item.item_price) * Number(totalTransferQty),
            packet_require_yn: "N",
            catalog_item_packet_code: "",
            batch_require_yn: "N",
            catalog_item_batch_code: "",
            location_code: selectedSourceLocation.code,
            trans_flag: "A",
            receipt_issue_flag: "I",
            taxable: "N",
            trans_bk: "",
            trans_no: "",
            trans_date: "",
            ref_trans_bk: "",
            ref_trans_no: "",
            ref_type: "",
            ref_trans_date: "",
            ref_serial_no: "",
            ref_qty: 0,
            clear_qty: 0,
            serial_no: serialNumber,
            image_thumnail: item.image_thumnail,
            catalog_item_code: item.item_code,
            item_type: item.item_type,
            company_id: userDetails.company_id,
            updated_by: userDetails.id,
            created_by: userDetails.id,
            id: "",
            lock_version: 0,
            updated_by_code: userDetails.user_cd,
            is_updated: "Y",
          });
        }
      });
    });

    this.state.selectedLocations.forEach((selectedSourceLocation) => {
      selectedSourceLocation.items.forEach((item) => {
        if (isObjectNotEmpty(item.selectedDestinationLocations)) {
          serialNumber = serialNumber + 1;

          item.selectedDestinationLocations.forEach((destinationLocation) => {
            serialNumber = serialNumber + 1;

            destinationLocationsAll.push({
              catalog_item_id: item.catalog_item_id,
              catalog_item_batch_id: "",
              catalog_item_packet_id: "",
              location_id: destinationLocation.destinationLocationId,
              expiry_date: item.expiry_date,
              item_description: item.item_name,
              rec_qty: destinationLocation.transferQty,
              rec_price: item.item_price,
              rec_amt:
                Number(item.item_price) *
                Number(destinationLocation.transferQty),
              packet_require_yn: "N",
              catalog_item_packet_code: "",
              batch_require_yn: "N",
              catalog_item_batch_code: "",
              location_code: destinationLocation.destinationLocationCode,
              trans_flag: "A",
              receipt_issue_flag: "R",
              taxable: "N",
              trans_bk: "",
              trans_no: "",
              trans_date: "",
              ref_trans_bk: "",
              ref_trans_no: "",
              ref_type: "",
              ref_trans_date: "",
              ref_serial_no: "",
              ref_qty: 0,
              clear_qty: 0,
              serial_no: serialNumber,
              image_thumnail: item.image_thumnail,
              catalog_item_code: item.item_code,
              item_type: item.item_type,
              company_id: userDetails.company_id,
              updated_by: userDetails.id,
              created_by: userDetails.id,
              id: "",
              lock_version: 0,
              updated_by_code: userDetails.user_cd,
              is_updated: "Y",
            });
          });
        }
      });
    });

    const requestObject = {
      inventory_transactions: [
        {
          receipt_issue_flag: "T",
          trans_type: "T",
          ext_ref_no: "",
          trans_no: "",
          trans_bk: "",
          trans_date: getCurrentDateYYYYMMDD(),
          account_period_code: this.props.accountPeriodCode,
          trans_flag: "A",
          remarks: "",
          company_id: userDetails.company_id,
          updated_by: userDetails.id,
          created_by: userDetails.id,
          id: "",
          lock_version: 0,
          updated_by_code: userDetails.user_cd,
          inventory_transaction_issue_lines: sourceLocationsAll,
          inventory_transaction_receive_lines: destinationLocationsAll,
        },
      ],
    };

    this.props.submitLocationTransferAction(requestObject);
  };

  disableSubmitButton = () => {
    let disableButton = false;

    disableButton = this.state.selectedLocations.every((location) =>
      location.items.every((item) =>
        isObjectEmpty(item.selectedDestinationLocations)
      )
    );

    return disableButton;
  };

  onChangeReciveQuantity = (number, item, selectedLocation, itemIndex) => {
    if (number > Number(item.on_hand_qty) || isNaN(number)) {
      showAlert.error("Please enter quantity less than available quantity!");
    } else {
      let transferQtys = this.state.transferQtys;
      transferQtys[
        selectedLocation.code + item.item_code + item.expiry_date
      ] = {
        ...this.state.transferQtys[
          selectedLocation.code + item.item_code + item.expiry_date
        ],
        transferQty: number,
      };
      this.setState({ transferQtys: { ...transferQtys } });
    }

    this.formRef.current.setFieldsValue({
      [selectedLocation.code + itemIndex + "DR"]: this.state.transferQtys[
        selectedLocation.code + item.item_code + item.expiry_date
      ].transferQty,
    });
  };

  render() {
    //console.log(this.props.allItemsInBin);
    // console.log(this.state.selectedLocations);
    // console.log(this.state.transferQtys);

    const deleteTransferRow = (locationIndex, itemIndex, rowIndex) => (
      <Popconfirm
        overlayClassName="deletemodal-alt"
        title={`Are you sure to delete destination location "${this.state.selectedLocations[locationIndex].items[itemIndex].selectedDestinationLocations[rowIndex].destinationLocationCode}" ? `}
        //description={`Are you sure to delete destination location - ${this.state.selectedLocations[locationIndex].items[itemIndex].selectedDestinationLocations[rowIndex].name} ? `}
        onConfirm={() => {
          let selectedLocations = this.state.selectedLocations;

          let item = selectedLocations[locationIndex].items[itemIndex];

          item.selectedDestinationLocations.splice(rowIndex, 1);

          this.setState({ selectedLocations });
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="trash-btn">
          <SvgIcon name="trash" viewbox="0 0 31.5 36" />
        </Button>
      </Popconfirm>
    );

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <Form ref={this.formRef} layout="vertical">
          <div className="transfer-wrapper">
            <div className="upper-section">
              {this.state.showAddNewItem && (
                <Collapse
                  className="transfer-accordian transferaccordianlocation mb-3"
                  defaultActiveKey={["1"]}
                  activeKey={this.state.activePanelKeyslocation}
                  onChange={(activePanelKeyslocation) => {
                    this.setState({
                      activePanelKeyslocation: activePanelKeyslocation,
                    });
                  }}
                >
                  <Panel
                    header=" Select Location"
                    key="1"
                    extra={
                      <SvgIcon
                        name="trash"
                        viewbox="0 0 31.5 36"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ showAddNewItem: false });
                        }}
                      />
                    }
                  >
                    <Row className="selectlocation-row">
                      <Col xs="8" className="col1">
                        <Form.Item
                          label={null}
                          rules={[
                            { required: true, message: "Please enter Item #!" },
                          ]}
                        >
                          <Select
                            suffixIcon={
                              <SvgIcon
                                name="select-arrow"
                                viewbox="0 0 19.124 12.357"
                              />
                            }
                            placeholder="Select Item"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch
                            value=""
                            onSelect={(string, option) => {
                              let alreadyExist = this.state.selectedLocations.some(
                                (location) => location.code === string
                              );

                              if (alreadyExist) {
                                showAlert.error("Location already selected!");
                              } else {
                                this.setState({
                                  selectedLocations: [
                                    { ...option, items: [] },
                                    ...this.state.selectedLocations,
                                  ],
                                  showAddNewItem: true,
                                  activePanelKeys: [
                                    option.code,
                                    ...this.state.activePanelKeys,
                                  ],
                                });
                                this.props.getAllItemsInBinAction(option.code);
                              }
                            }}
                          >
                            {this.state.allBinLocationsList}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="4" className="col2">
                        <div className="scan-btn">
                          <Input
                            placeholder="Scan"
                            prefix={
                              <SvgIcon name="scan" viewbox="0 0 29.25 27" />
                            }
                            value={this.state.barCodeText}
                            onChange={(event) =>
                              this.setState({ barCodeText: event.target.value })
                            }
                            onFocus={() => this.setState({ barCodeText: "" })}
                            onPressEnter={(event) => {
                              let scannedLoaction = this.props.allBinLocationsList.find(
                                (location) =>
                                  location.barcode === event.target.value
                              );

                              if (isObjectEmpty(scannedLoaction)) {
                                showAlert.error(
                                  "Location not available for this barcode!"
                                );
                                this.setState({ barCodeText: "" });
                              } else {
                                this.setState({
                                  selectedLocations: [
                                    { ...scannedLoaction, items: [] },
                                    ...this.state.selectedLocations,
                                  ],
                                  showAddNewItem: true,
                                  activePanelKeys: [
                                    scannedLoaction.code,
                                    ...this.state.activePanelKeys,
                                  ],
                                  barCodeText: "",
                                });
                                this.props.getAllItemsInBinAction(
                                  scannedLoaction.code
                                );

                                event.preventDefault();
                                event.target.blur();
                              }
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              )}
              <Collapse
                className="transfer-accordian transfer-accordian-location"
                key={"selected"}
                // defaultActiveKey={[0]}
                activeKey={this.state.activePanelKeys}
                onChange={(activePanelKeys) => {
                  this.setState({ activePanelKeys: activePanelKeys });
                }}
              >
                {this.state.selectedLocations.map(
                  (selectedLocation, selectedLocationIndex) => (
                    <Panel
                      header={selectedLocation.name}
                      key={selectedLocation.code}
                      extra={
                        <div onClick={(event) => event.stopPropagation()}>
                          <Popconfirm
                            overlayClassName="deletemodal-alt"
                            title={`Are you sure to delete source location "${selectedLocation.name}" ? `}
                            // description={`Are you sure to delete source location - ${selectedLocation.name} ? `}
                            onConfirm={(event) => {
                              event.stopPropagation();
                              let newArry = this.state.selectedLocations.splice(
                                selectedLocationIndex,
                                1
                              );

                              this.setState({
                                selectedLocation: [...newArry],
                              });
                            }}
                            onCancel={(event) => {
                              event.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <SvgIcon
                              name="trash"
                              viewbox="0 0 31.5 36"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          </Popconfirm>
                        </div>
                      }
                    >
                      {selectedLocation.items.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="card transferlocations-card"
                        >
                          <div className="card-body">
                            <div className="transferlocations-row">
                              <div className="colum1">
                                <p>item #</p>
                                <h4>{item.item_code}</h4>
                                {isObjectNotEmpty(
                                  dataFormatterMMDDYYYY(item.expiry_date)
                                ) && (
                                  <p>
                                    Expiring on{" "}
                                    {dataFormatterMMDDYYYY(item.expiry_date)}
                                  </p>
                                )}
                              </div>
                              <div className="colum2">
                                <p>Quantity</p>
                                <h4>{item.on_hand_qty}</h4>
                              </div>

                              <div className="colum4">
                                <>
                                  <label className="upper-label">
                                    Choose Destination{" "}
                                  </label>
                                  <div className="innerrow">
                                    <Form.Item
                                      className="mb-0"
                                      name={
                                        selectedLocation.code + itemIndex + "D"
                                      }
                                    >
                                      <Select
                                        suffixIcon={
                                          <SvgIcon
                                            name="select-arrow"
                                            viewbox="0 0 19.124 12.357"
                                          />
                                        }
                                        placeholder="Select Item"
                                        optionFilterProp="children"
                                        // dropdownMatchSelectWidth={false}
                                        dropdownClassName="choose-destination-drop"
                                        showSearch
                                        // value={
                                        //   this.state.transferQtys[
                                        //     selectedLocation.code +
                                        //       item.item_code +
                                        //       item.expiry_date
                                        //   ].destinationLocationCode
                                        // }
                                        onSelect={(key, location) => {
                                          let transferQtys = this.state
                                            .transferQtys;

                                          transferQtys[
                                            selectedLocation.code +
                                              item.item_code +
                                              item.expiry_date
                                          ] = {
                                            transferQty: isObjectEmpty(
                                              transferQtys[
                                                selectedLocation.code +
                                                  item.item_code +
                                                  item.expiry_date
                                              ]
                                            )
                                              ? 0
                                              : transferQtys[
                                                  selectedLocation.code +
                                                    item.item_code +
                                                    item.expiry_date
                                                ].transferQty,
                                            destinationLocationCode: key,
                                            destinationLocationId: location.id,
                                          };

                                          this.formRef.current.setFieldsValue({
                                            [selectedLocation.code +
                                            itemIndex +
                                            "D"]: transferQtys[
                                              selectedLocation.code +
                                                item.item_code +
                                                item.expiry_date
                                            ].destinationLocationCode,
                                          });
                                        }}
                                      >
                                        {this.state.allBinLocationsList}
                                      </Select>
                                    </Form.Item>
                                    <div className="scanbtn-col4">
                                      <ScanQuantityModal
                                        itemCode={item.item_code}
                                        itemId={item.catalog_item_id}
                                        onAddQuantity={(quantity) => {
                                          this.onChangeReciveQuantity(
                                            quantity,
                                            item,
                                            selectedLocation,
                                            itemIndex
                                          );
                                        }}
                                      />
                                    </div>
                                    <Form.Item
                                      className="mb-0"
                                      name={
                                        selectedLocation.code + itemIndex + "DR"
                                      }
                                    >
                                      <Input
                                        defaultValue={0}
                                        onChange={(event) => {
                                          let number = Number(
                                            event.target.value
                                          );
                                          this.onChangeReciveQuantity(
                                            number,
                                            item,
                                            selectedLocation,
                                            itemIndex
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                    <Button
                                      className="plus-button"
                                      onClick={() => {
                                        if (
                                          isObjectEmpty(
                                            this.state.transferQtys[
                                              selectedLocation.code +
                                                item.item_code +
                                                item.expiry_date
                                            ].destinationLocationCode
                                          ) ||
                                          Number(item.totalItemsToTransfer) ===
                                            0 ||
                                          Number(
                                            this.state.transferQtys[
                                              selectedLocation.code +
                                                item.item_code +
                                                item.expiry_date
                                            ].transferQty
                                          ) === 0
                                        ) {
                                          showAlert.error(
                                            "Please enter values!"
                                          );
                                          return;
                                        }

                                        item.selectedDestinationLocations.unshift(
                                          {
                                            ...this.state.transferQtys[
                                              selectedLocation.code +
                                                item.item_code +
                                                item.expiry_date
                                            ],
                                          }
                                        );
                                        this.formRef.current.setFieldsValue({
                                          [selectedLocation.code +
                                          itemIndex +
                                          "D"]: "",
                                          [selectedLocation.code +
                                          itemIndex +
                                          "DR"]: "0",
                                        });

                                        this.setState({
                                          transferQtys: {
                                            ...this.state.transferQtys,
                                            [selectedLocation.code +
                                            item.item_code +
                                            item.expiry_date]: {
                                              destinationLocationCode: "",
                                              transferQty: 0,
                                              destinationLocationId: "",
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      {/* Add Destination{" "} */}
                                      <SvgIcon
                                        name="plus"
                                        viewbox="0 0 13 12"
                                      />
                                    </Button>
                                  </div>
                                </>

                                <>
                                  {item.selectedDestinationLocations.map(
                                    (selectedRow, selectedRowIndex) => (
                                      <div className="innerrow">
                                        <Select
                                          suffixIcon={
                                            <SvgIcon
                                              name="select-arrow"
                                              viewbox="0 0 19.124 12.357"
                                            />
                                          }
                                          placeholder="Select Item"
                                          optionFilterProp="children"
                                          showSearch
                                          value={
                                            selectedRow.destinationLocationCode
                                          }
                                          disabled
                                        >
                                          <Select.Option
                                            name={
                                              selectedRow.destinationLocationCode
                                            }
                                            key={
                                              selectedRow.destinationLocationCode
                                            }
                                          >
                                            {
                                              selectedRow.destinationLocationCode
                                            }
                                          </Select.Option>
                                        </Select>
                                        <Input
                                          disabled
                                          value={selectedRow.transferQty}
                                        />
                                        {deleteTransferRow(
                                          selectedLocationIndex,
                                          itemIndex,
                                          selectedRowIndex
                                        )}
                                      </div>
                                    )
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {isObjectEmpty(selectedLocation.items) && (
                        <Empty description="No Data" />
                      )}
                    </Panel>
                  )
                )}
              </Collapse>
            </div>
            <div className="bottom-section">
              <div className="footer-top d-flex">
                <div>
                  <Button
                    className="add-new-button"
                    onClick={() => {
                      this.setState({ showAddNewItem: true });
                    }}
                  >
                    Add new location
                  </Button>
                </div>
                {/* <div className="d-flex align-items-center">
                  <label>Total Items to Transfer</label>
                  <Input value="0.00" disabled />
                </div> */}
              </div>
              <Button
                className="primary-btn bottom-btn"
                htmlType="submit"
                block
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure you want to transfer ?",
                    icon: <SvgIcon name="info" viewbox="0 0 33 33" />,
                    okText: "Yes",
                    cancelText: "Cancel",
                    className: "delete-items-modal",
                    okType: "danger",
                    onOk: () => {
                      this.submitLocationTransfer();
                    },
                  });
                }}
                disabled={this.disableSubmitButton()}
              >
                Transfer
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getAllBinLocationsList.actionName,
  serverApis.getAllItemsInBin.actionName,
  serverApis.submitLocationTransfer.actionName,
]);

const mapStateToProps = (state) => ({
  allBinLocationsList: state.allBinLocationsList,
  submitLocationTransferSuccessMessage:
    state.submitLocationTransferSuccessMessage,
  isFetchingData: loadingSelector(state),
  allItemsInBin: state.allItemsInBin,
  accountPeriodCode: state.accountPeriodCode,
});

export default connect(mapStateToProps, {
  getAllBinLocationsListAction,
  submitLocationTransferAction,
  resetStoreState,
  getAllItemsInBinAction,
  getAcountPeriodCodeAction,
})(TransferLocationTab);
