import React, { Component } from "react";
import { Checkbox, Form, Spin, Radio, Skeleton, Button, Input } from "antd";
import "./index.less";
import { Row, Col, SvgIcon } from "../../../components/common";
import StartPickingModal from "./StartPickingModal";
import PickedSuccessModal from "./PickedSuccessModal";

import { connect } from "react-redux";
import {
  createLoadingSelector,
  getProductionPickInboxListAction,
  getProductionUnPickInboxScanItemAction,
  getProductionUnPickInboxListAction,
  getProductionPickInboxScanItemAction,
  resetStoreState,
} from "../../../redux/actions";
import { serverApis } from "../../../constants/server-apis";
import {
  dataFormatterMMDDYYYY,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../common/utils";
import SearchModal from "./SearchModal";
import UnPickedConfirmationModal from "./UnPickedConfirmationModal";
import { showAlert } from "../../../common/alert-messages";

const radioOptionTitles = { PICK: "PICK", UNPICK: "UNPICK" };
const PAGE_SIZE = 100;

class PickingTab extends Component {
  scrollView = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      selectedOrders: [],
      selectedInboxListType: radioOptionTitles.PICK,
      filters: {},
      currentPageNumber: 1,
      totalPageCount: 0,
      clearFilter: false,
      scanText: "",
    };
  }
  scrollRef = React.createRef();

  isInViewport() {
    if (!this.scrollRef.current) return false;

    const scrollTop = this.scrollRef.current.scrollTop;
    const scrollHeight = this.scrollRef.current.scrollHeight;
    const clientHeight = this.scrollRef.current.clientHeight;

    return scrollTop + clientHeight + 50 >= scrollHeight;
  }

  handleScroll = (e) => {
    if (
      !this.props.isFetchingData &&
      this.isInViewport() &&
      this.state.currentPageNumber < this.state.totalPageCount &&
      isObjectNotEmpty(this.currentSelectedInboxList())
    ) {
      const currentPageNumber = this.state.currentPageNumber + 1;
      this.getInboxList(
        this.state.selectedInboxListType,
        this.state.filters,
        currentPageNumber
      );
      this.setState({ currentPageNumber: currentPageNumber });
    }

    // Detect when scrolled to bottom
  };

  componentWillUnmount() {
    this.scrollRef.current.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.scrollRef.current.addEventListener("scroll", this.handleScroll);
    this.getInboxList();
  }

  componentDidUpdate(prevProps) {
    if (isObjectNotEmpty(this.props.productionPickInboxScanItem)) {
      this.onScanSuccessFully(this.props.productionPickInboxScanItem);
      this.props.resetStoreState(
        serverApis.productionPickInboxScanItem.actionName
      );
    }

    if (isObjectNotEmpty(this.props.saveProductionUnPickInboxSuccessMessage)) {
      showAlert.confirm(
        "Unpick Successful. Please do transfer of merchandise from ‘Production’ location to the respective Rack/Bin location using Transfer screen."
      );
      this.props.resetStoreState(
        serverApis.saveProductionUnPickInbox.actionName
      );
      this.resetInboxLists();

      this.getInboxList(radioOptionTitles.UNPICK, this.state.filters, 1);

      const selectedOrders = this.state.selectedOrders.filter(
        (order) =>
          order.sales_order_id !==
          this.props.saveProductionUnPickInboxSuccessMessage
      );

      this.setState({
        selectedOrders: selectedOrders,
        currentPageNumber: 1,
        totalPageCount: 0,
      });
    }

    if (
      isObjectEmpty(prevProps.productionPickInboxList) &&
      isObjectNotEmpty(this.props.productionPickInboxList)
    ) {
      this.setState({
        totalPageCount: Math.ceil(
          this.props.productionPickInboxList[0].total_rows / PAGE_SIZE
        ),
      });
    }

    if (
      isObjectEmpty(prevProps.productionUnPickInboxList) &&
      isObjectNotEmpty(this.props.productionUnPickInboxList)
    ) {
      this.setState({
        totalPageCount: Math.ceil(
          this.props.productionUnPickInboxList[0].total_rows / PAGE_SIZE
        ),
      });
    }
  }

  deleteSelectedOrder = (order) => {
    let selectedOrders = this.state.selectedOrders;

    selectedOrders = selectedOrders.filter(
      (selectedOrder) => selectedOrder.trans_no !== order.trans_no
    );

    this.setState({ selectedOrders: selectedOrders });
  };

  onSelectAvailableLocation = (
    isChecked,
    location,
    order,
    catalogItemCode,
    stockLocationIndex
  ) => {
    let selectedOrders = this.state.selectedOrders;

    let orderToUpdate = selectedOrders.find(
      (selectedOrder) => selectedOrder.trans_no === order.trans_no
    );

    if (isChecked) {
      orderToUpdate.selectedLocations = [
        ...orderToUpdate.selectedLocations,
        {
          ...location,
          pickedQty: 0,
          catalogItemCode: catalogItemCode,
          selectedIndex: stockLocationIndex,
        },
      ];
    } else {
      orderToUpdate.selectedLocations = orderToUpdate.selectedLocations.filter(
        (unCheckedlocation) =>
          !(
            unCheckedlocation.catalogItemCode === catalogItemCode &&
            unCheckedlocation.location_id === location.location_id &&
            unCheckedlocation.selectedIndex === stockLocationIndex
          )
      );
    }

    this.setState({ selectedOrders: [...selectedOrders] });
  };

  onChangePickedQty = (qty, selectedLocation, order, stockLocationIndex) => {
    let selectedOrders = this.state.selectedOrders;

    let orderToUpdate = selectedOrders.find(
      (selectedOrder) => selectedOrder.trans_no === order.trans_no
    );

    let locationToUpdate = orderToUpdate.selectedLocations.find(
      (location) =>
        location.location_id === selectedLocation.location_id &&
        location.catalogItemCode === selectedLocation.catalogItemCode &&
        location.selectedIndex === stockLocationIndex
    );
    locationToUpdate.pickedQty = qty;

    this.setState({ selectedOrders: [...selectedOrders] });
  };

  onCloseSuccessPopup = () => {
    this.props.resetStoreState(serverApis.saveProductionPickInbox.actionName);
    this.resetInboxLists();
    this.setState(
      {
        selectedOrders: [],
        totalPageCount: 0,
        currentPageNumber: 1,
      },
      () => this.getInboxList()
    );
  };

  getInboxList = (
    value = radioOptionTitles.PICK,
    filters = this.state.filters,
    currentPageNumber = this.state.currentPageNumber
  ) => {
    if (value === radioOptionTitles.PICK) {
      this.props.getProductionPickInboxListAction(
        filters,
        currentPageNumber,
        PAGE_SIZE
      );
    } else {
      this.props.getProductionUnPickInboxListAction(
        filters,
        currentPageNumber,
        PAGE_SIZE
      );
    }

    this.setState({ selectedInboxListType: value });
  };

  currentSelectedInboxList = () => {
    if (this.state.selectedInboxListType === radioOptionTitles.PICK) {
      return this.props.productionPickInboxList;
    } else {
      return this.props.productionUnPickInboxList;
    }
  };

  isPickListSelected = () => {
    return this.state.selectedInboxListType === radioOptionTitles.PICK;
  };

  resetInboxLists = () => {
    this.props.resetStoreState(serverApis.productionPickInboxList.actionName);
    this.props.resetStoreState(serverApis.productionUnPickInboxList.actionName);
  };

  onScanSuccessFully = (order) => {
    const isAddOrderNow = !this.state.selectedOrders.some(
      (selectedOrder) => selectedOrder.trans_no === order.trans_no
    );
    let selectedOrders = this.state.selectedOrders;
    if (isAddOrderNow) {
      if (this.state.selectedInboxListType === radioOptionTitles.PICK) {
        selectedOrders.push({
          ...order,
          selectedLocations: [],
        });
      } else {
        selectedOrders = [{ ...order }];
      }
      showAlert.success("Item added to list.");
    } else {
      showAlert.error("Order already present in pick list.");
    }

    this.setState({
      selectedOrders: [...selectedOrders],
    });
  };

  refreshInboxList = (selectedInboxListType) => {
    this.resetInboxLists();
    this.setState(
      {
        totalPageCount: 0,
        currentPageNumber: 1,
        selectedOrders: [],
      },
      () => this.getInboxList(selectedInboxListType)
    );
  };

  render() {
    return (
      <Spin
        spinning={
          this.props.isLoadingSaveUnpickInbox
          //&&
          // isObjectEmpty(this.currentSelectedInboxList() && false)
        }
        size="large"
      >
        <PickedSuccessModal
          isModalOpen={isObjectNotEmpty(
            this.props.saveProductionPickInboxSuccessMessage
          )}
          selectedOrders={this.state.selectedOrders}
          onCloseSuccessPopup={this.onCloseSuccessPopup}
        />
        <Form layout="vertical">
          <div className="pickingtab-wrapper">
            <div className="pickingtab-header">
              <div className="d-flex">
              <Radio.Group
                value={this.state.selectedInboxListType}
                onChange={(event) => {
                  this.refreshInboxList(event.target.value);
                }}
              >
                
                <Radio.Button value={radioOptionTitles.PICK}>
                  {radioOptionTitles.PICK}
                </Radio.Button>
                <Radio.Button value={radioOptionTitles.UNPICK}>
                  {radioOptionTitles.UNPICK}
                </Radio.Button>
              </Radio.Group>
              <Button className="refresh-btn ml-2">
                <SvgIcon
                className="refresh-icon "
                name="refresh"
                viewbox="0 0 18.84 21.499"
                onClick={() =>
                  this.refreshInboxList(this.state.selectedInboxListType)
                }
              />
                </Button>
              </div>
         
              {/* <div>
                <Button
                  onClick={() =>
                    this.refreshInboxList(this.state.selectedInboxListType)
                  }
                >
                  Refresh
                </Button>
              </div> */}
              <div className="right-col">
                <div className="scan-btn ">
                  <Input
                    placeholder="Scan"
                    prefix={<SvgIcon name="scan" viewbox="0 0 29.25 27" />}
                    value={this.state.scanText}
                    onChange={(event) =>
                      this.setState({ scanText: event.target.value })
                    }
                    onPressEnter={(event) => {
                      if (
                        this.state.selectedInboxListType ===
                        radioOptionTitles.PICK
                      ) {
                        this.props.getProductionPickInboxScanItemAction(
                          event.target.value
                        );
                      } else {
                        this.props.getProductionUnPickInboxScanItemAction(
                          event.target.value
                        );
                      }

                      this.setState({ scanText: "" });
                    }}
                  />
                </div>
       
            
                <SearchModal
                  getInboxList={(filters) => {
                    this.resetInboxLists();

                    this.setState(
                      {
                        filters: filters,
                        totalPageCount: 0,
                        currentPageNumber: 1,
                        // selectedOrders: [],
                      },
                      () =>
                        this.getInboxList(
                          this.state.selectedInboxListType,
                          filters
                        )
                    );
                  }}
                  clearFilter={this.state.clearFilter}
                />
                {isObjectNotEmpty(this.state.filters) && (
                  <Button
                    type="text"
                    className="clear-btn"
                    onClick={() =>
                      this.setState({ clearFilter: !this.state.clearFilter })
                    }
                  >
                    <SvgIcon name="filter-clear" viewbox="0 0 39.339 32.384" />
                  </Button>
                )}
              </div>
            </div>
            <div ref={this.scrollRef} className="upper-section">
              {this.currentSelectedInboxList().map((order) => (
                <div key={order.trans_no} className="card">
                  <div className="card-body">
                    <div className="add-remove-btns">
                      <Button
                        type={
                          this.state.selectedOrders.some(
                            (selectedOrder) =>
                              selectedOrder.trans_no === order.trans_no
                          )
                            ? "danger"
                            : "primary"
                        }
                        onClick={() => {
                          const isAddOrderNow = !this.state.selectedOrders.some(
                            (selectedOrder) =>
                              selectedOrder.trans_no === order.trans_no
                          );
                          let selectedOrders = this.state.selectedOrders;
                          if (
                            this.state.selectedInboxListType ===
                            radioOptionTitles.PICK
                          ) {
                            if (isAddOrderNow) {
                              selectedOrders.push({
                                ...order,
                                selectedLocations: [],
                              });
                            } else {
                              selectedOrders = selectedOrders.filter(
                                (selectedOrder) =>
                                  selectedOrder.trans_no !== order.trans_no
                              );
                            }
                          } else {
                            if (isAddOrderNow) {
                              selectedOrders = [{ ...order }];
                            } else {
                              selectedOrders = [];
                            }
                          }

                          this.setState({
                            selectedOrders: [...selectedOrders],
                          });
                        }}
                      >
                        {this.state.selectedOrders.some(
                          (selectedOrder) =>
                            selectedOrder.trans_no === order.trans_no
                        )
                          ? "-"
                          : "+"}
                      </Button>
                    </div>
                    <Row className="mb-2">
                      <Col xs="4">
                        <label>JOB #</label>
                        <p>{order.trans_no}</p>
                      </Col>
                      <Col xs="4">
                        <label>Order Quantity</label>
                        <p>
                          {this.isPickListSelected()
                            ? order.line_item_qty
                            : order.item_qty}
                        </p>
                      </Col>
                      <Col xs="4">
                        <label>Imprint Type</label>
                        <p>{order.catalog_attribute_value_code}</p>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4">
                        <label>Ship Date</label>
                        <p>{dataFormatterMMDDYYYY(order.ship_date)}</p>
                      </Col>
                      <Col xs="4">
                        <label>Default Location</label>
                        <p>{order.location_code}</p>
                      </Col>
                      <Col xs="4">
                        <label>Item #</label>
                        <p>{order.catalog_item_code}</p>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4">
                        <label>Rush Order Type</label>
                        <p>{order.rush_order_type}</p>
                      </Col>
                      {isObjectEmpty(order.all_multi_description) && (
                        <Col xs="4">
                          {/* className="mb-0"> */}
                          <label>Item Variant</label>
                          <p>
                            {isObjectEmpty(order.all_multi_description)
                              ? order.inventory_items
                              : ""}
                          </p>
                        </Col>
                      )}
                      {isObjectNotEmpty(order.all_multi_description) && (
                        <Col xs="8">
                          <label>Multi Order Options</label>
                          <p>{order.all_multi_description}</p>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              ))}
              {this.props.isFetchingData && (
                <Skeleton active>
                  <div className="card">
                    <div className="card-body">
                      <Checkbox />
                      <Row className="mb-2">
                        <Col xs="4">
                          <label>JOB #</label>
                          <p>10250</p>
                        </Col>
                        <Col xs="4">
                          <label>Order Quantity</label>
                          <p>85</p>
                        </Col>
                        <Col xs="4">
                          <label>Imprint Type</label>
                          <p>Black</p>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4">
                          <label>Ship Date</label>
                          <p>25/2/2023</p>
                        </Col>
                        <Col xs="4">
                          <label>Default Location</label>
                          <p>Uk</p>
                        </Col>
                        <Col xs="4">
                          <label>Item #</label>
                          <p>#645320</p>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4">
                          <label>Item Variant</label>
                          <p>Var</p>
                        </Col>
                        <Col xs="8">
                          <label>Multi Order Options</label>
                          <p>3588210</p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Skeleton>
              )}
              {/* {this.props.isFetchingData && <div>Loading....</div>} */}
            </div>
            <div className="bottom-section">
              {this.state.selectedInboxListType === radioOptionTitles.PICK ? (
                <StartPickingModal
                  selectedOrders={this.state.selectedOrders}
                  deleteSelectedOrder={this.deleteSelectedOrder}
                  onSelectAvailableLocation={this.onSelectAvailableLocation}
                  onChangePickedQty={this.onChangePickedQty}
                />
              ) : (
                <UnPickedConfirmationModal
                  selectedOrder={
                    isObjectEmpty(this.state.selectedOrders)
                      ? {}
                      : this.state.selectedOrders[0]
                  }
                />
              )}
            </div>
          </div>
        </Form>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.productionPickInboxList.actionName,
  serverApis.productionUnPickInboxList.actionName,
]);

const loadingSelector2 = createLoadingSelector([
  serverApis.saveProductionUnPickInbox.actionName,
  serverApis.productionPickInboxScanItem.actionName,
  serverApis.productionUnPickInboxScanItem.actionName,
]);

const mapStateToProps = (state) => ({
  saveProductionPickInboxSuccessMessage:
    state.saveProductionPickInboxSuccessMessage,
  saveProductionUnPickInboxSuccessMessage:
    state.saveProductionUnPickInboxSuccessMessage,
  productionPickInboxList: state.productionPickInboxList,
  productionUnPickInboxList: state.productionUnPickInboxList,
  productionPickInboxScanItem: state.productionPickInboxScanItem,

  isFetchingData: loadingSelector(state),
  isLoadingSaveUnpickInbox: loadingSelector2(state),
});

export default connect(mapStateToProps, {
  getProductionPickInboxScanItemAction,
  getProductionUnPickInboxScanItemAction,
  getProductionPickInboxListAction,
  getProductionUnPickInboxListAction,
  resetStoreState,
})(PickingTab);
