import { isObjectNotEmpty } from "../../common/utils";
import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const binLocationByBarcodeReducer = (state = {}, action) => {
  const actionName = serverApis.getBinLocationByBarcode.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      if (isObjectNotEmpty(action.json.locations)) {
        return action.json.locations[0];
      } else {
        return state;
      }

    case getResetAction(actionName):
      return {};
    default:
      return state;
  }
};
