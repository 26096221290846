/* eslint-disable no-loop-func */
import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Select,
  Spin,
  Modal,
} from "antd";
import FloorModal from "./floor-modal";
import { connect } from "react-redux";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  getStockItemListAction,
  getStockItemInfoAction,
  getStockItemLocationsAction,
  getAcountPeriodCodeAction,
  submitLocationTransferAction,
  resetStoreState,
} from "../../../redux/actions";
import { SvgIcon } from "../../../components/common";
import {
  dataFormatterMMDDYYYY,
  dataFormatterMMDDYYYY2,
  getCurrentDateYYYYMMDD,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../common/utils";
import TransferredSuccessfully from "./TransferredSuccessfully";
import { showAlert } from "../../../common/alert-messages";
import { loggedInUserDetail } from "../../../common/methods";
import "./index.less";

const { Panel } = Collapse;

class TransferTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStockItem: {},
      showTransferConfirmPopup: false,
      itemsTransferDetails: {},
      showAddNewItem: true,
      activePanelKeys: ["addNewItem"],
    };
  }

  scrollView = React.createRef();

  removeDestinationBinRow = (index, itemCode, binDetails) => {
    let itemsTransferDetails = this.state.itemsTransferDetails;

    let transferDetails = itemsTransferDetails[itemCode];

    let selectedDestinationLocations =
      transferDetails.selectedDestinationLocations;

    selectedDestinationLocations[binDetails.code].splice(index, 1);

    transferDetails = {
      ...transferDetails,
      selectedDestinationLocations: { ...selectedDestinationLocations },
    };

    itemsTransferDetails[itemCode] = { ...transferDetails };

    this.setState({
      itemsTransferDetails: { ...itemsTransferDetails },
    });
  };

  updateDestinationLocations = (
    itemCode,
    binDetails,
    quantity = 0,
    removeLocation = false,
    expiryDate = ""
  ) => {
    let itemsTransferDetails = this.state.itemsTransferDetails;

    let transferDetails = itemsTransferDetails[itemCode];

    let selectedDestinationLocations =
      transferDetails.selectedDestinationLocations;

    if (removeLocation) {
      delete selectedDestinationLocations[binDetails.code];
    } else if (quantity === 0) {
      selectedDestinationLocations[binDetails.code] = [];
    } else {
      selectedDestinationLocations[binDetails.code] = [
        {
          bin: { ...binDetails },
          selectedQuantity: quantity,
          expiryDate: expiryDate,
        },
        ...selectedDestinationLocations[binDetails.code],
      ];
    }

    transferDetails = {
      ...transferDetails,
      selectedDestinationLocations: { ...selectedDestinationLocations },
    };

    itemsTransferDetails[itemCode] = { ...transferDetails };

    this.setState({
      itemsTransferDetails: { ...itemsTransferDetails },
    });
  };

  componentDidMount() {
    this.props.getStockItemListAction();
    this.props.getAcountPeriodCodeAction();
  }
  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.submitLocationTransferSuccessMessage)) {
      this.setState({
        selectedStockItem: {},
        showTransferConfirmPopup: true,
        showAddNewItem: true,
        activePanelKeys: ["addNewItem"],
      });

      // showAlert.success("Items transfered successfully!");
      this.props.resetStoreState(serverApis.submitLocationTransfer.actionName);
    }

    if (isObjectNotEmpty(this.props.stockItemInfo)) {
      let itemsTransferDetails = this.state.itemsTransferDetails;

      let itemDetails =
        itemsTransferDetails[this.props.stockItemInfo.store_code];

      itemDetails.stockItemInfo = this.props.stockItemInfo;

      this.setState({
        itemsTransferDetails: {
          ...itemsTransferDetails,
        },
        showAddNewItem: false,
      });
      this.props.resetStoreState(serverApis.getStockItemInfo.actionName);
    }

    if (isObjectNotEmpty(this.props.stockItemLocations)) {
      let itemsTransferDetails = this.state.itemsTransferDetails;
      let itemDetails =
        itemsTransferDetails[this.props.stockItemLocations[0].item_code];
      itemDetails.stockItemLocations = this.props.stockItemLocations;
      this.setState({ itemsTransferDetails: { ...itemsTransferDetails } });

      this.props.resetStoreState(serverApis.getStockItemLocations.actionName);
    }
  }

  onCheckSourceLocation = (checked, location, index) => {
    let itemsTransferDetails = this.state.itemsTransferDetails;

    let transferDetails = itemsTransferDetails[location.item_code];

    let selectedSourceLocations = transferDetails.selectedSourceLocations;

    if (checked) {
      location.selectedQuantity = Number(location.on_hand_qty);
      location.selectedIndex = index;

      selectedSourceLocations = [...selectedSourceLocations, location];
    } else {
      let uncheckedLocation = selectedSourceLocations.find(
        (existingLocation) => existingLocation.selectedIndex === index
      );

      const filteredLocations = selectedSourceLocations.filter(
        (existingLocation) => existingLocation.selectedIndex !== index
      );

      delete uncheckedLocation.selectedQuantity;
      delete uncheckedLocation.selectedIndex;

      selectedSourceLocations = filteredLocations;
    }

    transferDetails.selectedSourceLocations = selectedSourceLocations;

    if (isObjectEmpty(transferDetails.selectedSourceLocations)) {
      transferDetails.selectedDestinationLocations = {};
    }

    this.setState({ itemsTransferDetails: { ...itemsTransferDetails } });
  };

  getTotalItemToTransfer = (itemCode) => {
    let totalItemsToTransfer = 0;

    if (
      isObjectNotEmpty(this.state.itemsTransferDetails[itemCode]) &&
      isObjectNotEmpty(
        this.state.itemsTransferDetails[itemCode].selectedSourceLocations
      )
    ) {
      this.state.itemsTransferDetails[itemCode].selectedSourceLocations.forEach(
        (location) => {
          if (itemCode === location.item_code) {
            totalItemsToTransfer =
              totalItemsToTransfer + Number(location.selectedQuantity);
          }
        }
      );
    }

    return totalItemsToTransfer;
  };

  getTotalItems = () => {
    let totalItemsToTransfer = 0;

    for (const itemCode in this.state.itemsTransferDetails) {
      if (
        isObjectNotEmpty(this.state.itemsTransferDetails[itemCode]) &&
        isObjectNotEmpty(
          this.state.itemsTransferDetails[itemCode].selectedSourceLocations
        )
      ) {
        const selectedSourceLocations = this.state.itemsTransferDetails[
          itemCode
        ].selectedSourceLocations;

        // eslint-disable-next-line no-loop-func
        selectedSourceLocations.forEach((location) => {
          totalItemsToTransfer =
            totalItemsToTransfer + Number(location.selectedQuantity);
        });
      }
    }

    return totalItemsToTransfer;
  };
  getTotalAllocatedItems = () => {
    let totalItemsToTransfer = 0;

    for (const itemCode in this.state.itemsTransferDetails) {
      if (
        isObjectNotEmpty(this.state.itemsTransferDetails[itemCode]) &&
        isObjectNotEmpty(
          this.state.itemsTransferDetails[itemCode].selectedDestinationLocations
        )
      ) {
        totalItemsToTransfer =
          totalItemsToTransfer + this.getItemQuantityAssigned(itemCode);
      }
    }

    return totalItemsToTransfer;
  };

  getItemQuantityToTransfer = (itemCode) => {
    let totalItemsToTransfer = 0;

    const selectedSourceLocations = this.state.itemsTransferDetails[itemCode]
      .selectedSourceLocations;

    selectedSourceLocations.forEach((location) => {
      totalItemsToTransfer =
        totalItemsToTransfer + Number(location.selectedQuantity);
    });

    return totalItemsToTransfer;
  };

  getItemQuantityAssigned = (itemCode) => {
    let totalItemsToTransfer = 0;

    const selectedDestinationLocations = this.state.itemsTransferDetails[
      itemCode
    ].selectedDestinationLocations;

    for (const locationCode in selectedDestinationLocations) {
      let selectedBinLocationLines = selectedDestinationLocations[locationCode];

      selectedBinLocationLines.map((selectedLocation) => {
        totalItemsToTransfer =
          totalItemsToTransfer + Number(selectedLocation.selectedQuantity);
        return 0;
      });
    }

    return totalItemsToTransfer;
  };

  isCheckboxDisabled = (location) => {
    if (
      isObjectEmpty(
        this.state.itemsTransferDetails[location.item_code]
          .selectedSourceLocations
      )
    ) {
      return false;
    } else if (
      isObjectEmpty(
        this.state.itemsTransferDetails[location.item_code]
          .selectedSourceLocations[0].expiry_date
      ) &&
      isObjectEmpty(location.expiry_date)
    ) {
      return false;
    } else if (
      this.state.itemsTransferDetails[location.item_code]
        .selectedSourceLocations[0].expiry_date === location.expiry_date
    ) {
      return false;
    }
    return true;
  };

  closeConfirmPopup = () => {
    this.setState({
      itemsTransferDetails: {},
      showTransferConfirmPopup: false,
    });
  };

  confirmDelete = (itemCode) => {
    Modal.confirm({
      title: "Do you want to delete these items?",
      icon: <SvgIcon name="info" viewbox="0 0 33 33" />,
      okText: "Yes",
      cancelText: "Cancel",
      className: "delete-items-modal",
      okType: "danger",
      onOk: () => {
        let itemsTransferDetails = this.state.itemsTransferDetails;
        delete itemsTransferDetails[itemCode];

        this.setState({
          itemsTransferDetails: { ...itemsTransferDetails },
        });
      },
    });
  };

  getSelectedItemSourceLocations = (itemCode) => {
    const locations = this.state.itemsTransferDetails[itemCode]
      .selectedSourceLocations;
    return locations;
  };

  getSelectedItemDestinationLocations = (itemCode) => {
    return this.state.itemsTransferDetails[itemCode]
      .selectedDestinationLocations;
  };
  getSelectedOtherItemDestinationLocations = (itemCode) => {
    let otherItemLocations = {};

    for (const itemCodeKey in this.state.itemsTransferDetails) {
      if (itemCodeKey !== itemCode) {
        otherItemLocations = {
          ...otherItemLocations,
          ...this.state.itemsTransferDetails[itemCodeKey]
            .selectedDestinationLocations,
        };
      }
    }

    return otherItemLocations;
  };

  areAllTransferAndAssignedQtySame = () => {
    let sameQty = true;

    for (const itemCode in this.state.itemsTransferDetails) {
      let transferQty = this.getItemQuantityToTransfer(itemCode);
      let assignedQty = this.getItemQuantityAssigned(itemCode);

      if (transferQty !== assignedQty) {
        sameQty = false;
      }
    }
    return sameQty;
  };

  submitLocationTransfer = () => {
    const userDetails = loggedInUserDetail();

    let serialNumber = 100;

    let sourceLocationsAll = [];
    let destinationLocationsAll = [];

    for (const itemCode in this.state.itemsTransferDetails) {
      let itemsTransferDetails = this.state.itemsTransferDetails[itemCode];

      const sourceLocations = itemsTransferDetails.selectedSourceLocations.map(
        // eslint-disable-next-line no-loop-func
        (sourceLocation) => {
          serialNumber = serialNumber + 1;
          return {
            catalog_item_id: itemsTransferDetails.stockItemInfo.catalog_item_id,
            catalog_item_batch_id: "",
            catalog_item_packet_id: "",
            location_id: sourceLocation.location_id,
            expiry_date: sourceLocation.expiry_date,
            item_description:
              itemsTransferDetails.stockItemInfo.sale_description,
            iss_qty: sourceLocation.selectedQuantity,
            iss_price: itemsTransferDetails.stockItemInfo.price,
            iss_amt:
              Number(itemsTransferDetails.stockItemInfo.price) *
              Number(sourceLocation.selectedQuantity),
            packet_require_yn:
              itemsTransferDetails.stockItemInfo.packet_require_yn,
            catalog_item_packet_code: "",
            batch_require_yn:
              itemsTransferDetails.stockItemInfo.batch_require_yn,
            catalog_item_batch_code: sourceLocation.item_batch_code,
            location_code: sourceLocation.location_code,
            trans_flag: "A",
            receipt_issue_flag: "I",
            taxable: itemsTransferDetails.stockItemInfo.taxable,
            trans_bk: "",
            trans_no: "",
            trans_date: "",
            ref_trans_bk: "",
            ref_trans_no: "",
            ref_type: "",
            ref_trans_date: "",
            ref_serial_no: "",
            ref_qty: 0,
            clear_qty: 0,
            serial_no: serialNumber,
            image_thumnail: itemsTransferDetails.stockItemInfo.image_thumnail,
            catalog_item_code: itemsTransferDetails.stockItemInfo.store_code,
            item_type: itemsTransferDetails.stockItemInfo.item_type,
            company_id: userDetails.company_id,
            updated_by: userDetails.id,
            created_by: userDetails.id,
            id: "",
            lock_version: 0,
            updated_by_code: userDetails.user_cd,
            is_updated: "Y",
          };
        }
      );
      sourceLocationsAll = [...sourceLocationsAll, ...sourceLocations];

      Object.values(itemsTransferDetails.selectedDestinationLocations).map(
        (destinationBin) => {
          if (isObjectNotEmpty(destinationBin)) {
            let destinationLocations = destinationBin.map(
              (destinationLocation) => {
                serialNumber = serialNumber + 1;

                return {
                  catalog_item_id:
                    itemsTransferDetails.stockItemInfo.catalog_item_id,
                  catalog_item_batch_id: "",
                  catalog_item_packet_id: "",
                  location_id: destinationLocation.bin.id,
                  expiry_date: destinationLocation.expiryDate,
                  item_description:
                    itemsTransferDetails.stockItemInfo.sale_description,
                  rec_qty: destinationLocation.selectedQuantity,
                  rec_price: itemsTransferDetails.stockItemInfo.price,
                  rec_amt:
                    Number(itemsTransferDetails.stockItemInfo.price) *
                    Number(destinationLocation.selectedQuantity),
                  packet_require_yn:
                    itemsTransferDetails.stockItemInfo.packet_require_yn,
                  catalog_item_packet_code: "",
                  batch_require_yn:
                    itemsTransferDetails.stockItemInfo.batch_require_yn,
                  catalog_item_batch_code: "",
                  location_code: destinationLocation.bin.code,
                  trans_flag: "A",
                  receipt_issue_flag: "R",
                  taxable: itemsTransferDetails.stockItemInfo.taxable,
                  trans_bk: "",
                  trans_no: "",
                  trans_date: "",
                  ref_trans_bk: "",
                  ref_trans_no: "",
                  ref_type: "",
                  ref_trans_date: "",
                  ref_serial_no: "",
                  ref_qty: 0,
                  clear_qty: 0,
                  serial_no: serialNumber,
                  image_thumnail:
                    itemsTransferDetails.stockItemInfo.image_thumnail,
                  catalog_item_code:
                    itemsTransferDetails.stockItemInfo.store_code,
                  item_type: itemsTransferDetails.stockItemInfo.item_type,
                  company_id: userDetails.company_id,
                  updated_by: userDetails.id,
                  created_by: userDetails.id,
                  id: "",
                  lock_version: 0,
                  updated_by_code: userDetails.user_cd,
                  is_updated: "Y",
                };
              }
            );

            destinationLocationsAll = [
              ...destinationLocationsAll,
              ...destinationLocations,
            ];
          }

          return 0;
        }
      );
    }

    const requestObject = {
      inventory_transactions: [
        {
          receipt_issue_flag: "T",
          trans_type: "T",
          ext_ref_no: "",
          trans_no: "",
          trans_bk: "",
          trans_date: getCurrentDateYYYYMMDD(),
          account_period_code: this.props.accountPeriodCode,
          trans_flag: "A",
          remarks: "",
          company_id: userDetails.company_id,
          updated_by: userDetails.id,
          created_by: userDetails.id,
          id: "",
          lock_version: 0,
          updated_by_code: userDetails.user_cd,
          inventory_transaction_issue_lines: sourceLocationsAll,
          inventory_transaction_receive_lines: destinationLocationsAll,
        },
      ],
    };

    this.props.submitLocationTransferAction(requestObject);
  };

  render() {
    const deleteItem = (itemCode) => (
      <SvgIcon
        name="trash"
        viewbox="0 0 31.5 36"
        onClick={(event, e) => {
          event.stopPropagation();
          this.confirmDelete(itemCode);
        }}
      />
    );

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        {this.state.showTransferConfirmPopup && (
          <TransferredSuccessfully
            itemsTransferDetails={this.state.itemsTransferDetails}
            closeConfirmPopup={this.closeConfirmPopup}
          />
        )}

        <Form layout="vertical">
          <div className="transfer-wrapper">
            <div ref={this.scrollView} className="upper-section">
              <Collapse
                // accordion
                className="transfer-accordian"
                defaultActiveKey={["addNewItem"]}
                activeKey={this.state.activePanelKeys}
                onChange={(activePanelKeys) => {
                  this.setState({ activePanelKeys: activePanelKeys });
                }}
              >
                {this.state.showAddNewItem && (
                  <Panel
                    header="Item #"
                    key="addNewItem"
                    extra={
                      <SvgIcon
                        name="trash"
                        viewbox="0 0 31.5 36"
                        onClick={(event, e) => {
                          event.stopPropagation();
                          this.setState({ showAddNewItem: false });
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label={null}
                      rules={[
                        { required: true, message: "Please enter Item #!" },
                      ]}
                    >
                      <Select
                        suffixIcon={
                          <SvgIcon
                            name="select-arrow"
                            viewbox="0 0 19.124 12.357"
                          />
                        }
                        placeholder="Select Item"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch
                        onSelect={(item, selectedOption) => {
                          if (
                            isObjectNotEmpty(
                              this.state.itemsTransferDetails[
                                selectedOption.stockItem.store_code
                              ]
                            )
                          ) {
                            showAlert.error(
                              "Item already exists in list below."
                            );
                            return;
                          }

                          this.setState({
                            activePanelKeys: [
                              ...this.state.activePanelKeys,
                              selectedOption.stockItem.store_code,
                            ],
                            showAddNewItem: false,
                            selectedStockItem: selectedOption.stockItem,
                            itemsTransferDetails: {
                              [selectedOption.stockItem.store_code]: {
                                selectedStockItem: selectedOption.stockItem,
                                selectedDestinationLocations: {},
                                selectedSourceLocations: [],
                              },
                              ...this.state.itemsTransferDetails,
                            },
                          });

                          this.props.getStockItemInfoAction(
                            selectedOption.stockItem.id
                          );
                          this.props.getStockItemLocationsAction(
                            selectedOption.stockItem.store_code
                          );
                        }}
                        value=""
                      >
                        {this.props.stockItemList.map((item) => (
                          <Select.Option
                            title={item.store_code}
                            key={item.store_code}
                            value={item.store_code}
                            stockItem={item}
                          >
                            {item.store_code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className="card">
                      <div className="card-header">
                        <div className="header-col">
                          <label>Item #</label>
                          <p>
                            {isObjectNotEmpty(this.props.stockItemInfo)
                              ? this.props.stockItemInfo.store_code
                              : ""}
                          </p>
                        </div>
                        <div className="header-col">
                          <label>Item Name</label>
                          <p>
                            {isObjectNotEmpty(this.props.stockItemInfo)
                              ? this.props.stockItemInfo.name
                              : ""}
                            {/* <span className="read-more-btn"> Read More.. </span> */}
                          </p>
                        </div>
                      </div>
                      <div className="card-body">
                        <h4 className="items-title">Aisle Locations</h4>
                        <div className="locations-list"></div>
                      </div>
                    </div>
                  </Panel>
                )}
                {Object.keys(this.state.itemsTransferDetails).map(
                  (itemCode) => (
                    <Panel
                      header={itemCode}
                      key={itemCode}
                      extra={deleteItem(itemCode)}
                    >
                      <Form.Item
                        label={null}
                        rules={[
                          { required: true, message: "Please enter Item #!" },
                        ]}
                      >
                        <Select
                          suffixIcon={
                            <SvgIcon
                              name="select-arrow"
                              viewbox="0 0 19.124 12.357"
                            />
                          }
                          placeholder="Select Item"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          showSearch
                          value={itemCode}
                          disabled={true}
                        >
                          {this.props.stockItemList.map((item) => (
                            <Select.Option
                              title={item.store_code}
                              key={item.store_code}
                              value={item.store_code}
                              stockItem={item}
                            >
                              {item.store_code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div className="card">
                        <div className="card-header">
                          <div className="header-col">
                            <label>Item #</label>
                            <p>
                              {isObjectNotEmpty(
                                this.state.itemsTransferDetails[itemCode]
                                  .stockItemInfo
                              )
                                ? this.state.itemsTransferDetails[itemCode]
                                    .stockItemInfo.store_code
                                : ""}
                            </p>
                          </div>
                          <div className="header-col">
                            <label>Item Name</label>
                            <p>
                              {isObjectNotEmpty(
                                this.state.itemsTransferDetails[itemCode]
                                  .stockItemInfo
                              )
                                ? this.state.itemsTransferDetails[itemCode]
                                    .stockItemInfo.name
                                : ""}
                              {/* <span className="read-more-btn"> Read More.. </span> */}
                            </p>
                          </div>
                        </div>
                        <div className="card-header">
                          <div className="header-col">
                            <label>Transfer Qty</label>
                            <p>{this.getItemQuantityToTransfer(itemCode)}</p>
                          </div>
                          <div className="header-col">
                            <label>Items Assigned</label>
                            <p>{this.getItemQuantityAssigned(itemCode)}</p>
                          </div>
                        </div>
                        <div className="location-selected-list w-100">
                          <label>Destination Locations</label>
                          {Object.keys(
                            this.state.itemsTransferDetails[itemCode]
                              .selectedDestinationLocations
                          ).map((locationCode) =>
                            this.state.itemsTransferDetails[
                              itemCode
                            ].selectedDestinationLocations[locationCode].map(
                              (selectedLocation, index) => (
                                <h6
                                  key={locationCode + index}
                                  className="shipment-location-selected"
                                >
                                  {locationCode}
                                  &nbsp;&nbsp;&nbsp;
                                  {selectedLocation.selectedQuantity}
                                  &nbsp;&nbsp;&nbsp;
                                  {dataFormatterMMDDYYYY2(
                                    selectedLocation.expiryDate
                                  )}
                                  <SvgIcon
                                    name="close"
                                    viewbox="0 0 13.426 13.423"
                                    onClick={() =>
                                      this.removeDestinationBinRow(
                                        index,
                                        itemCode,
                                        selectedLocation.bin
                                      )
                                    }
                                  />
                                </h6>
                              )
                            )
                          )}
                        </div>
                        <div className="card-body">
                          <h4 className="items-title">Aisle Locations</h4>

                          <div className="locations-list">
                            {this.state.itemsTransferDetails[itemCode]
                              .stockItemLocations &&
                              this.state.itemsTransferDetails[
                                itemCode
                              ].stockItemLocations.map(
                                (location, stockLocationIndex) => (
                                  <div
                                    key={stockLocationIndex}
                                    className="locations-row"
                                  >
                                    <div className="colum1">
                                      <div className="d-flex">
                                        <Checkbox
                                          checked={this.state.itemsTransferDetails[
                                            itemCode
                                          ].selectedSourceLocations.some(
                                            (selectedLocation) =>
                                              selectedLocation.location_code ===
                                                location.location_code &&
                                              selectedLocation.selectedIndex ===
                                                stockLocationIndex
                                          )}
                                          onChange={(event) =>
                                            this.onCheckSourceLocation(
                                              event.target.checked,
                                              location,
                                              stockLocationIndex
                                            )
                                          }
                                          // disabled={this.isCheckboxDisabled(
                                          //   location
                                          // )}
                                        />
                                        <div className="ml-2">
                                          <h4>{location.location_code}</h4>
                                          {isObjectNotEmpty(
                                            location.expiry_date
                                          ) && (
                                            <p>
                                              Expiring on{" "}
                                              {dataFormatterMMDDYYYY(
                                                location.expiry_date
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="colum2">
                                      <p>Quantity</p>
                                      <h5>{Number(location.on_hand_qty)}</h5>
                                    </div>
                                    <div xs="3" className="colum3">
                                      <Input
                                        value={location.selectedQuantity}
                                        onChange={(event) => {
                                          if (
                                            Number(event.target.value) >
                                              Number(location.on_hand_qty) ||
                                            isNaN(Number(event.target.value))
                                          ) {
                                            return;
                                          }

                                          let selectedLocation = this.state.itemsTransferDetails[
                                            itemCode
                                          ].selectedSourceLocations.find(
                                            (item) =>
                                              item.location_code ===
                                                location.location_code &&
                                              item.selectedIndex ===
                                                stockLocationIndex
                                          );

                                          selectedLocation.selectedQuantity =
                                            event.target.value;

                                          this.setState({
                                            itemsTransferDetails: {
                                              ...this.state
                                                .itemsTransferDetails,
                                            },
                                          });
                                        }}
                                        disabled={
                                          !this.state.itemsTransferDetails[
                                            itemCode
                                          ].selectedSourceLocations.some(
                                            (selectedLocation) =>
                                              selectedLocation.location_code ===
                                                location.location_code &&
                                              selectedLocation.selectedIndex ===
                                                stockLocationIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <FloorModal
                            selectedSourceLocations={this.getSelectedItemSourceLocations(
                              itemCode
                            )}
                            totalItemsToTransfer={this.getTotalItemToTransfer(
                              itemCode
                            )}
                            selectedStockItem={
                              this.state.itemsTransferDetails[itemCode]
                                .selectedStockItem
                            }
                            selectedDestinationLocations={this.getSelectedItemDestinationLocations(
                              itemCode
                            )}
                            updateDestinationLocations={
                              this.updateDestinationLocations
                            }
                            selectedOtherItemDestinationLocations={this.getSelectedOtherItemDestinationLocations(
                              itemCode
                            )}
                            removeDestinationBinRow={
                              this.removeDestinationBinRow
                            }
                            getItemQuantityAssigned={
                              this.getItemQuantityAssigned
                            }
                          />
                        </div>
                      </div>
                    </Panel>
                  )
                )}
              </Collapse>
            </div>
            <div className="bottom-section">
              <div className="footer-top d-flex">
                <div>
                  <Button
                    className="add-new-button"
                    onClick={() => {
                      this.setState({ showAddNewItem: true }, () =>
                        this.scrollView.current.scrollTo(0, 0)
                      );
                    }}
                  >
                    Add new item
                  </Button>
                </div>
                <div className="d-flex align-items-center">
                  <label>Total Items to Transfer</label>
                  <Input value={this.getTotalItems()} disabled />
                </div>
              </div>
              <div className="footer-top d-flex">
                <div></div>
                <div className="d-flex align-items-center">
                  <label>Total Items Assigned</label>
                  <Input value={this.getTotalAllocatedItems()} disabled />
                </div>
              </div>
              <Button
                onClick={() => this.submitLocationTransfer()}
                className="primary-btn bottom-btn"
                htmlType="submit"
                block
                disabled={
                  !(
                    Number(this.getTotalItems()) > 0 &&
                    this.areAllTransferAndAssignedQtySame()
                  )
                }
              >
                Transfer
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getStockItemInfo.actionName,
  serverApis.getStockItemList.actionName,
  serverApis.getStockItemLocations.actionName,
  serverApis.submitLocationTransfer.actionName,
]);

const mapStateToProps = (state) => ({
  stockItemInfo: state.stockItemInfo,
  stockItemList: state.stockItemList,
  stockItemLocations: state.stockItemLocations,
  submitLocationTransferSuccessMessage:
    state.submitLocationTransferSuccessMessage,
  accountPeriodCode: state.accountPeriodCode,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getStockItemListAction,
  getStockItemInfoAction,
  getStockItemLocationsAction,
  getAcountPeriodCodeAction,
  submitLocationTransferAction,
  resetStoreState,
})(TransferTab);
