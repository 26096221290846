import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const loginReducer = (state = {}, action) => {
  const apiName = serverApis.login.actionName;
  switch (action.type) {
    case getActionNameWithStatus(apiName, requestStatus.SUCCESS):
      return action.json;

    case getResetAction(apiName):
      return {};
    default:
      return state;
  }
};

export const logoutReducer = (state = "", action) => {
  const apiName = serverApis.logout.actionName;
  switch (action.type) {
    case getActionNameWithStatus(apiName, requestStatus.SUCCESS):
      return action.json.message;

    case getResetAction(apiName):
      return "";
    default:
      return state;
  }
};
