import { USER_DETAIL_KEY, requestContentType } from "../constants";

import { requestCreatorKey } from "../constants/request";
import { baseServerAdress } from "../constants/server-address";
import { isObjectNotEmpty } from "./utils";

export const getFormatedFloorPlan = (floorPlan, store_code) => {
  // console.log("\n");
  // console.log("start = " + new Date().toLocaleString());
  let count = 0;

  let floor_plan = [];
  let floor_plan_rows = [];
  let floor_plan_shelves = [];

  let pallet_capacity = 0;
  let pallet_used = 0;
  let pallet_available = 0;
  let stock_qty = 0;
  let available_stock_qty = 0;
  let item_already_placed = "N";
  let valid_section_aisle = "N";
  let locations = floorPlan;
  let catalog_item_code = store_code;

  floorPlan.forEach((location, index) => {
    count = count + 1;

    if (index === 0) {
      floor_plan_shelves.push(location);
      pallet_capacity = location["pallet_capacity"];
      pallet_used = Number(location["pallet_used"]);
      pallet_available = Number(location["pallet_available"]);
      stock_qty = Number(location["stock_qty"]);
      available_stock_qty = Number(location["available_stock_qty"]);
      if (catalog_item_code === location["catalog_item_code"]) {
        item_already_placed = "Y";
      }
      valid_section_aisle = location["valid_section_aisle"];
    } else {
      let prev_location = locations[index - 1];

      if (prev_location["section"] === location["section"]) {
        if (prev_location["aisle"] === location["aisle"]) {
          if (catalog_item_code === location["catalog_item_code"]) {
            item_already_placed = "Y";
          }

          pallet_capacity = pallet_capacity + location["pallet_capacity"];
          pallet_used = Number(pallet_used) + Number(location["pallet_used"]);
          pallet_available =
            Number(pallet_available) + Number(location["pallet_available"]);
          stock_qty = Number(stock_qty) + Number(location["stock_qty"]);
          available_stock_qty =
            Number(available_stock_qty) +
            Number(location["available_stock_qty"]);
          if (valid_section_aisle === "N") {
            valid_section_aisle = location["valid_section_aisle"];
          }
        } else {
          if (catalog_item_code === prev_location["catalog_item_code"]) {
            item_already_placed = "Y";
          }
          floor_plan_rows.push({
            section: prev_location["section"],
            aisle: prev_location["aisle"],
            pallet_capacity: pallet_capacity,
            pallet_used: pallet_used,
            pallet_available: pallet_available,
            stock_qty: stock_qty,
            available_stock_qty: available_stock_qty,
            item_already_placed: item_already_placed,
            valid_section_aisle: valid_section_aisle,
            bins: floor_plan_shelves,
          });
          floor_plan_shelves = [];
          pallet_capacity = location["pallet_capacity"];
          pallet_used = Number(location["pallet_used"]);
          pallet_available = Number(location["pallet_available"]);
          stock_qty = Number(location["stock_qty"]);
          available_stock_qty = Number(location["available_stock_qty"]);
          item_already_placed = "N";
          valid_section_aisle = location["valid_section_aisle"];
        }
        floor_plan_shelves.push(location);

        if (index === locations.count - 1) {
          if (catalog_item_code === location["catalog_item_code"]) {
            item_already_placed = "Y";
          }
          floor_plan_rows.push({
            section: location["section"],
            aisle: location["aisle"],
            pallet_capacity: pallet_capacity,
            pallet_used: pallet_used,
            pallet_available: pallet_available,
            stock_qty: stock_qty,
            available_stock_qty: available_stock_qty,
            item_already_placed: item_already_placed,
            valid_section_aisle: valid_section_aisle,
            bins: floor_plan_shelves,
          });
          floor_plan.push({
            section: location["section"],
            floor_plan_rows: floor_plan_rows,
          });
        }
      } else {
        if (catalog_item_code === prev_location["catalog_item_code"]) {
          item_already_placed = "Y";
        }
        floor_plan_rows.push({
          section: prev_location["section"],
          aisle: prev_location["aisle"],
          pallet_capacity: pallet_capacity,
          pallet_used: pallet_used,
          pallet_available: Number(pallet_available),
          stock_qty: stock_qty,
          available_stock_qty: available_stock_qty,
          item_already_placed: item_already_placed,
          valid_section_aisle: valid_section_aisle,
          bins: floor_plan_shelves,
        });
        floor_plan.push({
          section: prev_location["section"],
          floor_plan_rows: floor_plan_rows,
        });
        floor_plan_shelves = [];
        floor_plan_rows = [];
        floor_plan_shelves.push(location);
        pallet_capacity = location["pallet_capacity"];
        pallet_used = Number(location["pallet_used"]);
        pallet_available = Number(location["pallet_available"]);
        stock_qty = Number(location["stock_qty"]);
        available_stock_qty = Number(location["available_stock_qty"]);
        if (index === locations.count - 1) {
          if (catalog_item_code === location["catalog_item_code"]) {
            item_already_placed = "Y";
          }
          floor_plan_rows.push({
            section: location["section"],
            aisle: location["aisle"],
            pallet_capacity: pallet_capacity,
            pallet_used: pallet_used,
            pallet_available: pallet_available,
            stock_qty: stock_qty,
            available_stock_qty: available_stock_qty,
            item_already_placed: item_already_placed,
            valid_section_aisle: valid_section_aisle,
            bins: floor_plan_shelves,
          });
          floor_plan.push({
            section: location["section"],
            floor_plan_rows: floor_plan_rows,
          });
        }
        item_already_placed = "N";
        valid_section_aisle = location["valid_section_aisle"];
      }
    }
  });
  // console.log(count);
  // console.log("End = " + new Date().toLocaleString());
  // console.log(floor_plan);
  // console.log("\n");

  return floor_plan;
};

export const getActionCreatorObject = (
  apiInfo,
  requestBody,
  isForFormData = false
) => {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: apiInfo.actionName,

    [requestCreatorKey.REQUEST_METHOD]: apiInfo.apiMethodType,

    [requestCreatorKey.REQUEST_URL]: baseServerAdress.API + apiInfo.serverUrl,
  };
  if (!isForFormData) {
    actionCreatorObject[requestCreatorKey.REQUEST_HEADER] = {
      ...getJsonRequestHeaderWithSessionId(),
    };
  }
  if (requestBody) {
    if (isForFormData) {
      actionCreatorObject[requestCreatorKey.REQUEST_PAYLOAD] = requestBody;
    } else {
      actionCreatorObject[requestCreatorKey.REQUEST_PAYLOAD] = JSON.stringify(
        requestBody
      );
    }
  }

  return actionCreatorObject;
};

export const clearLoginData = () => {
  localStorage.removeItem(USER_DETAIL_KEY);
};

export const isOpenPath = (routerPath) => {
  switch (routerPath) {
    // case componentPaths.registration:
    case "componentPaths.USER_ACTIVATION":
      return true;

    default:
      return true;
  }
};

export const isFlagTrueY = (flag) => {
  return flag === "Y";
};

export const isUserLoggedIn = () => {
  if (localStorage.getItem(USER_DETAIL_KEY)) {
    return true;
  } else return false;
};

export const saveLocalData = (key, value) => {
  localStorage.setItem(key, value);
};

export const saveLoggedInUserDetails = (userDetails) => {
  localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(userDetails));
};

export const getLocalData = (key) => {
  return localStorage.getItem(key);
};

export const loggedInUserDetail = () => {
  return isObjectNotEmpty(localStorage.getItem(USER_DETAIL_KEY))
    ? JSON.parse(localStorage.getItem(USER_DETAIL_KEY))
    : null;
};

export const isLiveMode = () => {
  const appLiveUrl = "tekreceive.tekweld.com";
  if (appLiveUrl === window.location.origin.replace("https://", "")) {
    return true;
  } else if (appLiveUrl === window.location.origin.replace("http://", "")) {
    return true;
  } else {
    return false;
  }
};

export const setScrollToTop = () => {
  // window.onbeforeunload = function () {
  window.scrollTo(0, 0);
  // };
};

export const getJsonRequestHeader = () => {
  return {
    Accept: requestContentType.APPLICATION_JSON,
    "Content-Type": requestContentType.APPLICATION_JSON,
  };
};

export const getJsonRequestHeaderWithSessionId = () => {
  return {
    ...getJsonRequestHeader(),
    // Session: `${getLocalData(LOGIN_TOKEN_KEY)}`,
  };
};

export const printFileViaURL = async (url) => {
  await fetch(url)
    .then((fileData) => fileData.blob())
    .then((blobData) => {
      const blobURL = URL.createObjectURL(blobData);
      const nw = window.open(blobURL, "_blank", "popup");
      setTimeout(() => {
        nw.print();
        // setTimeout(() => {
        //   // nw.close();
        // }, 1500);
      }, 1000);
    });
};

export const downloadFile = (fileURL, fileName = "file") => {
  setTimeout(() => {
    fetch(fileURL).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }, 100);
};

export const getCatalogItemCodeFromOrderDetail = (orderDetail) => {
  let catalog_item_code = "";
  if (
    isObjectNotEmpty(orderDetail) &&
    isObjectNotEmpty(orderDetail.sales_order_lines)
  ) {
    const itemLine = orderDetail.sales_order_lines.find(
      (line) => line.item_type === "I"
    );

    if (isObjectNotEmpty(itemLine)) {
      catalog_item_code = itemLine.catalog_item_code;
    }
  }
  return catalog_item_code;
};
