import React, { Component } from "react";
import dayjs from "dayjs";
import { Button, Modal, Input, Checkbox, Form, DatePicker } from "antd";
import moment from "moment";
import { SvgIcon, Row, Col } from "../../../../components/common";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import { isFlagTrueY } from "../../../../common/methods";
import "./index.less";
import { dateFormats } from "../../../../constants";
class FloorPlansBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      binTextDetails: {},
    };
  }

  initialTextDetails = () => {
    return {
      quantity: 0,
      expiryDate: "",
    };
  };

  resetBinEditableDetails = (binCode) => {
    let binTextDetails = this.state.binTextDetails;

    binTextDetails[binCode] = { ...this.initialTextDetails() };
    this.setState({ binTextDetails });
  };

  onchangeItemQty = (qty, binCode) => {
    let binTextDetails = this.state.binTextDetails;

    if (isObjectEmpty(binTextDetails[binCode])) {
      binTextDetails[binCode] = { quantity: qty };
    } else {
      binTextDetails[binCode].quantity = qty;
    }
    this.setState({ binTextDetails });
  };

  onchangeItemExpiry = (date, binCode) => {
    let binTextDetails = this.state.binTextDetails;

    if (isObjectEmpty(binTextDetails[binCode])) {
      binTextDetails[binCode] = { expiryDate: date };
    } else {
      binTextDetails[binCode].expiryDate = date;
    }
    this.setState({ binTextDetails });
  };

  showModal = () => {
    if (isFlagTrueY(this.props.containerDetails.valid_section_aisle)) {
      this.setState({ isModalOpen: true });
    }
  };

  handleOk = () => {
    this.oncloseFloorPlanBox();
    this.setState({ isModalOpen: false });
  };

  oncloseFloorPlanBox = () => {
    if (this.props.onCloseFloorPlansBox) {
      this.props.onCloseFloorPlansBox();
    }
  };

  handleCancel = () => {
    this.oncloseFloorPlanBox();
    this.setState({ isModalOpen: false });
  };

  isBinBlocked = (binDetails) => {
    // allowed mixed items change
    // const isOtherItemAllocated = isObjectNotEmpty(
    //   this.props.selectedOtherItemDestinationLocations[binDetails.code]
    // );

    const isSourceLocation = this.props.selectedSourceLocations.some(
      (selectedLocation) => {
        return (
          selectedLocation.location_code === binDetails.code
          // &&
          // isObjectNotEmpty(binDetails.catalog_item_code)
        );
      }
    );

    // allowed mixed items change
    // const otherItemFilled =
    //   isObjectNotEmpty(binDetails.catalog_item_code) &&
    //   binDetails.catalog_item_code !== this.props.selectedStockItem.store_code;

    const availableQuantityZero = Number(binDetails.available_stock_qty) === 0;

    return (
      // allowed mixed items change
      // isOtherItemAllocated ||
      // otherItemFilled ||
      availableQuantityZero || isSourceLocation
    );
  };

  getBinClassName = (binDetails, selected) => {
    if (this.isBinBlocked(binDetails)) {
      return "box-col red-box";
    } else if (selected) {
      return "box-col blue-box-checked";
    } else if (
      binDetails.catalog_item_code === this.props.selectedStockItem.store_code
    ) {
      return "box-col green-box";
    } else {
      return "box-col";
    }
  };

  componentDidUpdate() {
    if (
      isObjectNotEmpty(this.props.selectedRack) &&
      this.props.selectedRack.rackLocationTitle ===
        this.getRackLocationTitle() &&
      !this.state.isModalOpen
    ) {
      this.showModal();
    }
  }

  getRackLocationTitle = () => {
    return `${this.props.containerDetails.aisle}-${this.props.containerDetails.section}`;
  };

  render() {
    const rackLocationTitle = this.getRackLocationTitle();
    return (
      <>
        <div className={this.props.className} onClick={this.showModal}>
          {isFlagTrueY(this.props.containerDetails.valid_section_aisle)
            ? Number(this.props.containerDetails.available_stock_qty)
            : "."}

          <span className="floor-title">{rackLocationTitle}</span>
        </div>
        <Modal
          title={`Available bins in ${rackLocationTitle}`}
          className="floor-plan-transfer-model-bins"
          centered
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
        >
          <div className="floor-wrapper">
            <div className="floor-head-wrapper floorheadwrapper-bins d-flex">
              <Checkbox className="checkbox-cus" checked={true}>
                Contains
              </Checkbox>
              <Checkbox className="checkbox-cus" checked={false}>
                N Quantity' Space Available
              </Checkbox>
              <Checkbox
                className="notavailabe-check checkbox-cus"
                checked={false}
              >
                Not Available
              </Checkbox>
            </div>
            <div className="bins-heading mt-2">
              <Row className="bins-heading-row mx-3 py-2">
                <Col className="colums pl-2" xs="4">
                  <span>Location</span>
                </Col>
                <Col className="colums text-center" xs="3">
                  <span>Qty</span>
                </Col>
                <Col className="colums text-center" xs="3">
                  <span>Exp. Date</span>
                </Col>
              </Row>
            </div>

            <div className="floorplan-list-wrapper">
              {this.props.containerDetails.bins.map((bin) => {
                const selectedDestinationLocations = this.props
                  .selectedDestinationLocations[bin.code];
                return (
                  <Row key={bin.code}>
                    <Col className="colums" xs="4" key={"existingLine"}>
                      <div
                        className="box-row"
                        onClick={
                          !this.isBinBlocked(bin)
                            ? selectedDestinationLocations
                              ? () => {
                                  this.props.updateDestinationLocations(
                                    this.props.selectedStockItem.store_code,
                                    bin,
                                    0,
                                    true
                                  );
                                }
                              : () => {
                                  this.props.updateDestinationLocations(
                                    this.props.selectedStockItem.store_code,
                                    bin
                                  );

                                  this.resetBinEditableDetails(bin.code);
                                }
                            : () => {}
                        }
                      >
                        <div
                          className={this.getBinClassName(
                            bin,
                            selectedDestinationLocations
                          )}
                        >
                          <div className="counts">
                            {Number(bin.available_stock_qty)}
                          </div>
                          {/* <span>{bin.bin}</span> */}
                        </div>
                      </div>
                      <div className="inner-box">{bin.bin}</div>
                    </Col>
                    {selectedDestinationLocations && (
                      <>
                        <Col className="colums" xs="3" key={"receiveLine"}>
                          <div className="value-row">
                            <div className="value-col">
                              {/* <label>{bin.bin}</label> */}
                              <Input
                                value={
                                  isObjectEmpty(
                                    this.state.binTextDetails[bin.code]
                                  )
                                    ? 0
                                    : this.state.binTextDetails[bin.code]
                                        .quantity
                                }
                                onChange={(event) => {
                                  let number = Number(event.target.value);
                                  if (
                                    number > Number(bin.available_stock_qty) ||
                                    isNaN(number)
                                  ) {
                                    return;
                                  }
                                  this.onchangeItemQty(number, bin.code);
                                }}
                              />
                            </div>
                          </div>

                          {selectedDestinationLocations.map((rowDetails) => (
                            <div className="value-row">
                              <div className="value-col">
                                <Input
                                  disabled={true}
                                  value={rowDetails.selectedQuantity}
                                />
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col className="colums" xs="3">
                          <div className="date-col">
                            {/* <label>Expiry Date </label> */}
                            <DatePicker
                              disabledDate={(current) => {
                                // Can not select days before today and today
                                return (
                                  // current &&
                                  current && current < dayjs().add(-1, "day")
                                );
                              }}
                              onChange={(momentDate) => {
                                let expiryDateString = "";

                                if (momentDate === null) {
                                  expiryDateString = "";
                                } else {
                                  expiryDateString = momentDate.format(
                                    dateFormats.YYYYMMDD_SLASH
                                  );
                                }

                                this.onchangeItemExpiry(
                                  expiryDateString,
                                  bin.code
                                );
                              }}
                              value={
                                isObjectNotEmpty(
                                  this.state.binTextDetails[bin.code]
                                ) &&
                                isObjectNotEmpty(
                                  this.state.binTextDetails[bin.code].expiryDate
                                )
                                  ? moment(
                                      this.state.binTextDetails[bin.code]
                                        .expiryDate,
                                      dateFormats.YYYYMMDD_SLASH
                                    )
                                  : undefined
                              }
                              suffixIcon={
                                <SvgIcon
                                  name="calendar-icon"
                                  viewbox="0 0 21.607 22.107"
                                />
                              }
                              format={dateFormats.MM_DD_YYYY}
                              allowClear
                            />
                          </div>

                          {/* <label> </label> */}

                          {selectedDestinationLocations.map((rowDetails) => (
                            <div className="date-col">
                              <DatePicker
                                disabled={true}
                                format={dateFormats.MM_DD_YYYY}
                                allowClear
                                suffixIcon={
                                  <SvgIcon
                                    name="calendar-icon"
                                    viewbox="0 0 21.607 22.107"
                                  />
                                }
                                value={
                                  isObjectEmpty(rowDetails.expiryDate)
                                    ? undefined
                                    : moment(
                                        rowDetails.expiryDate,
                                        dateFormats.YYYYMMDD_SLASH
                                      )
                                }
                              />
                            </div>
                          ))}
                        </Col>
                        <Col
                          className="colums d-flex justify-content-center"
                          xs="2"
                        >
                          <div>
                            <Button
                              onClick={() => {
                                if (
                                  this.state.binTextDetails[bin.code]
                                    .quantity !== 0
                                ) {
                                  let expiryDate = isObjectEmpty(
                                    this.state.binTextDetails[bin.code]
                                      .expiryDate
                                  )
                                    ? ""
                                    : this.state.binTextDetails[bin.code]
                                        .expiryDate;

                                  this.props.updateDestinationLocations(
                                    this.props.selectedStockItem.store_code,
                                    bin,
                                    Number(
                                      this.state.binTextDetails[bin.code]
                                        .quantity
                                    ),
                                    false,
                                    expiryDate
                                  );
                                  this.resetBinEditableDetails(bin.code);
                                }
                              }}
                              className="plus-btn d-flex  justify-content-center"
                            >
                              <SvgIcon name="plus" viewbox="0 0 13 12" />
                            </Button>
                            {selectedDestinationLocations.map(
                              (rowDetails, index) => (
                                <Button
                                  onClick={() => {
                                    this.props.removeDestinationBinRow(
                                      index,
                                      this.props.selectedStockItem.store_code,
                                      bin
                                    );
                                  }}
                                  className=" delete-btn d-flex justify-content-center"
                                >
                                  <SvgIcon name="trash" viewbox="0 0 31.5 36" />
                                </Button>
                              )
                            )}
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                );
              })}
            </div>
          </div>
          <div className="floorplan-footer">
            <div className="footer-top  py-2 d-flex">
              <Form.Item label="UnAssigned Qty" className="w-50 mb-0 mr-2">
                <Input
                  value={
                    this.props.totalItemsToTransfer -
                    this.props.totalItemsAssigned
                  }
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="Assigned Qty"
                className="w-50 mb-0 justify-content-end d-flex"
              >
                <Input value={this.props.totalItemsAssigned} disabled />
              </Form.Item>
            </div>
            <Button type="primary" block onClick={this.handleCancel}>
              SUBMIT
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default FloorPlansBox;
