import { isLiveMode } from "../common/methods";

export const baseServerAdress = isLiveMode()
  ? {
      API: ``,

      baseURL: `http://tekweld.promoerp.tekweld.com/`,
      schemaName: "TEKW1122",
    }
  : {
      API: ``,
      baseURL: `http://tekwtest.promoerp.tekweld.com/`,
      schemaName: "TEKWTEST",
    };
