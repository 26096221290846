import React, { Component } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tooltip,
  Menu
} from "antd";
import { SvgIcon } from "../../components/common";
import FloorPlaneView from "./floor-modal";
import AddAttachmentModal from "./AddAttachmentModal";
//import AutoCompleteView from "./AutoComplete";
// import AddQuantityModal from './AddAttachmentModal';
import {
  dataFormatterMMDDYYYY,
  dataFormatterMMDDYYYY2,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../common/utils";
import { componentPaths } from "../../constants/component-paths";
import {
  createLoadingSelector,
  getShipmentNumberListAction,
  getOpenPurchaseLineListAction,
  getShipmentLineListAction,
  // getBarCodeShipmentLineListAction,
  submitPurchaseReceiveAction,
  getAcountPeriodCodeAction,
  resetStoreState,
  setStoreState,
  createErrorMessageSelector,
  getWarehouseListAction,
  getPalletBarcodeSlipAction,
  getInnerCartonsAction,
  getMasterCartonsAction,
} from "../../redux/actions";
import { serverApis } from "../../constants/server-apis";
import { connect } from "react-redux";
import { loggedInUserDetail } from "../../common/methods";
import { showAlert } from "../../common/alert-messages";
import { resetActions } from "../../constants/request";
import "./index.less";
import confirm from "antd/lib/modal/confirm";
import AddQuantityModal from "./AddQuantityModal";
import ViewAttachmentModal from "./ViewAttachmentModal";
import { baseServerAdress } from "../../constants/server-address";
class ReceivingDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiveDetails: isObjectEmpty(this.props.location.state)
        ? {
            // vendorDetails: { name: "Vendor_name" },
            // receiveType: { description: "type_receive" },
            // receiveDate: "2022-11-30T10:29:39.995Z",
          }
        : JSON.parse(this.props.location.state),
      selectedShipment: {},
      shipmentLineList: [],
      textBarCode: "",
      uploadedImages: [],
      receivedShippingLineDetails: {},
      receivedShippingQuantities: {},
      barCodeShipmentLineList: [],
      selectedBarCodeShipment: {},
      textSearchBar: "",
      defaultFloorLocation: {},
      receivedTypeOfShippingLineByScan: {},
      selectedFloorLocations: {},
    };
  }

  showDefaultFloorAlert = (binDetails) => {
    // if (showDefaultSelectionAlert) {
    confirm({
      title: "Floor Location",
      content: `Do you want to set ${binDetails.value} for all POs ?`,
      // cancelButtonProps: { hidden: true },
      okText: "YES",
      cancelText: "NO",
      onOk: () => {
        this.setState({ defaultFloorLocation: binDetails });
      },
    });
    // }
  };

  onSelectFloorLocation = (
    floorLocation,
    binDetails,
    quantity,
    shipmentLine
  ) => {
    let selectedFloorLocations = this.state.selectedFloorLocations;
    selectedFloorLocations[shipmentLine.rowKey] = floorLocation;

    this.setState({ selectedFloorLocations: { ...selectedFloorLocations } });

    if (isObjectEmpty(floorLocation)) {
      this.onChangeReceiveQuanity(quantity, shipmentLine.rowKey);
    } else {
      this.addReceviedShippingDetails(
        binDetails,
        quantity,
        shipmentLine,
        false,
        "",
        true
      );
    }
  };

  filteredShipmentLineList = () => {
    return this.state.shipmentLineList.filter(
      (shipmentLine) =>
        shipmentLine.catalog_item_code
          .toLowerCase()
          .includes(this.state.textSearchBar.toLowerCase()) ||
        shipmentLine.po_no
          .toLowerCase()
          .includes(this.state.textSearchBar.toLowerCase()) ||
        (isObjectNotEmpty(shipmentLine.po_barcode) &&
          shipmentLine.po_barcode
            .toLowerCase()
            .includes(this.state.textSearchBar.toLowerCase())) ||
        (isObjectNotEmpty(shipmentLine.barcode) &&
          shipmentLine.barcode
            .toLowerCase()
            .includes(this.state.textSearchBar.toLowerCase()))
    );
  };

  onChangeReceiveQuanity = (quantity, rowKey, defaultSelectionCallBack) => {
    let receivedShippingQuantities = this.state.receivedShippingQuantities;
    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;

    receivedShippingLineDetails[rowKey] = [];
    receivedShippingQuantities[rowKey] = quantity;

    // let selectedFloorLocations = this.state.selectedFloorLocations;
    // selectedFloorLocations[rowKey] = "";

    this.setState(
      {
        receivedShippingQuantities: receivedShippingQuantities,
        receivedShippingLineDetails: receivedShippingLineDetails,
        // selectedFloorLocations,
      },
      () => {
        // if (defaultSelectionCallBack) {
        //   defaultSelectionCallBack();
        // }
      }
    );
  };

  removeReceivedBinRow = (indexBinRow, shipmentLine, binDetails) => {
    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;

    let recevedLineArry = receivedShippingLineDetails[shipmentLine.rowKey];

    let receivedBinLocation = recevedLineArry.find(
      (binLocation) => binLocation.bin.code === binDetails.code
    );

    receivedBinLocation.selectedQuantities.splice(indexBinRow, 1);
    receivedShippingLineDetails[shipmentLine.rowKey] = [...recevedLineArry];

    this.setState({
      receivedShippingLineDetails: { ...receivedShippingLineDetails },
    });
  };

  addReceviedShippingDetails = (
    binDetails,
    quantity,
    shipmentLine,
    removeLocation = false,
    expiryDate = ""
  ) => {
    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;
    let receivedTypeOfShippingLineByScan =
      this.state.receivedTypeOfShippingLineByScan;

    if (receivedTypeOfShippingLineByScan[shipmentLine.rowKey]) {
      receivedTypeOfShippingLineByScan[shipmentLine.rowKey] = false;
      receivedShippingLineDetails[shipmentLine.rowKey] = [];
    }

    if (removeLocation) {
      receivedShippingLineDetails[shipmentLine.rowKey] =
        receivedShippingLineDetails[shipmentLine.rowKey].filter(
          (location) => location.bin.code !== binDetails.code
        );
    } else if (quantity === 0) {
      if (
        isObjectNotEmpty(this.state.selectedFloorLocations[shipmentLine.rowKey])
      ) {
        receivedShippingLineDetails[shipmentLine.rowKey] = [
          {
            bin: { ...binDetails },
            selectedQuantities: [],
            catalog_item_code: shipmentLine.catalog_item_code,
          },
        ];
      } else if (receivedShippingLineDetails[shipmentLine.rowKey]) {
        let recevedLineArry = receivedShippingLineDetails[shipmentLine.rowKey];
        recevedLineArry.unshift({
          bin: { ...binDetails },
          selectedQuantities: [],
          catalog_item_code: shipmentLine.catalog_item_code,
        });
      } else {
        receivedShippingLineDetails[shipmentLine.rowKey] = [
          {
            bin: { ...binDetails },
            selectedQuantities: [],
            catalog_item_code: shipmentLine.catalog_item_code,
          },
        ];
      }
    } else {
      let recevedLineArry = receivedShippingLineDetails[shipmentLine.rowKey];

      if (
        isObjectNotEmpty(this.state.selectedFloorLocations[shipmentLine.rowKey])
      ) {
        receivedShippingLineDetails[shipmentLine.rowKey] = [
          {
            bin: { ...binDetails },
            selectedQuantities: [
              {
                selectedQuantity: quantity,
                expiryDate: expiryDate,
              },
            ],
            catalog_item_code: shipmentLine.catalog_item_code,
          },
        ];
      } else {
        let receivedBinLocation = recevedLineArry.find(
          (binLocation) => binLocation.bin.code === binDetails.code
        );

        receivedBinLocation.selectedQuantities.unshift({
          selectedQuantity: quantity,
          expiryDate: expiryDate,
        });
        receivedShippingLineDetails[shipmentLine.rowKey] = [...recevedLineArry];
      }
    }

    this.setState({
      receivedShippingLineDetails: { ...receivedShippingLineDetails },
      receivedTypeOfShippingLineByScan: { ...receivedTypeOfShippingLineByScan },
    });
  };

  addReceviedShippingDetailsByScan = (binDetails, quantity, shipmentLine) => {
    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;

    let receivedTypeOfShippingLineByScan =
      this.state.receivedTypeOfShippingLineByScan;

    if (!this.state.receivedTypeOfShippingLineByScan[shipmentLine.rowKey]) {
      receivedTypeOfShippingLineByScan[shipmentLine.rowKey] = true;
      receivedShippingLineDetails[shipmentLine.rowKey] = [];
    }

    if (isObjectEmpty(receivedShippingLineDetails[shipmentLine.rowKey])) {
      receivedShippingLineDetails[shipmentLine.rowKey] = [
        {
          bin: { ...binDetails },
          selectedQuantities: [],
          catalog_item_code: shipmentLine.catalog_item_code,
        },
      ];
    }

    let recevedLineArry = receivedShippingLineDetails[shipmentLine.rowKey];

    let receivedBinLocation = recevedLineArry.find(
      (binLocation) => binLocation.bin.code === binDetails.code
    );

    if (isObjectNotEmpty(receivedBinLocation)) {
      receivedBinLocation.selectedQuantities.unshift({
        selectedQuantity: quantity,
        expiryDate: shipmentLine.expiry_date,
      });
      receivedShippingLineDetails[shipmentLine.rowKey] = [...recevedLineArry];
    } else {
      receivedShippingLineDetails[shipmentLine.rowKey] = [
        {
          bin: { ...binDetails },
          selectedQuantities: [
            {
              selectedQuantity: quantity,
              expiryDate: shipmentLine.expiry_date,
            },
          ],
          catalog_item_code: shipmentLine.catalog_item_code,
        },

        ...recevedLineArry,
      ];
    }

    this.setState({
      receivedShippingLineDetails: { ...receivedShippingLineDetails },
      receivedTypeOfShippingLineByScan: { ...receivedTypeOfShippingLineByScan },
    });
  };

  removeReceviedShippingDetails = (binDetails, shipmentLine) => {
    let selectedFloorLocations = this.state.selectedFloorLocations;
    selectedFloorLocations[shipmentLine.rowKey] = "";

    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;
    if (isObjectNotEmpty(receivedShippingLineDetails[shipmentLine.rowKey])) {
      const filterList = receivedShippingLineDetails[
        shipmentLine.rowKey
      ].filter((receiveLine) => binDetails.code !== receiveLine.bin.code);
      receivedShippingLineDetails[shipmentLine.rowKey] = [...filterList];
    }

    this.setState({
      receivedShippingLineDetails: { ...receivedShippingLineDetails },
      selectedFloorLocations,
    });
  };

  isReceiveTypeDirect = () => {
    return this.state.receiveDetails.receiveType.value === "D";
  };

  componentDidMount() {
    if (isObjectEmpty(this.state.receiveDetails)) {
      this.props.navigate(componentPaths.RECEIVING);
      return;
    }

    if (this.isReceiveTypeDirect()) {
      this.props.getOpenPurchaseLineListAction(
        this.state.receiveDetails.vendorDetails.code
      );
    } else {
      this.props.getShipmentNumberListAction(
        this.state.receiveDetails.vendorDetails.id
      );
    }

    this.props.getAcountPeriodCodeAction();
    this.props.getWarehouseListAction();
  }

  componentWillUnmount() {
    this.props.resetStoreState(serverApis.getShipmentNumberList.actionName);
    this.props.resetStoreState(serverApis.getShipmentLineList.actionName);
    this.props.resetStoreState(serverApis.getAccountPeriodCode.actionName);
  }

  resetAllData(extraState = {}) {
    this.setState({
      shipmentLineList: [],
      selectedShipment: {},
      textBarCode: "",
      receivedShippingQuantities: {},
      receivedShippingLineDetails: {},
      receivedTypeOfShippingLineByScan: {},

      ...extraState,
    });
  }

  componentDidUpdate(prevProps) {
    if (isObjectNotEmpty(this.props.shipmentLineList)) {
      let shipmentLineList = this.props.shipmentLineList;
      let receivedShippingQuantities = {};
      let receivedShippingLineDetails = {};
      let receivedTypeOfShippingLineByScan = {};
      for (let index = 0; index < shipmentLineList.length; index++) {
        const element = shipmentLineList[index];

        if (this.isReceiveTypeDirect()) {
          element.shipment_item_qty = element.item_qty;

          element.po_no = element.trans_no;
          element.po_serial_no = element.serial_no;
        }

        //element.shipment_item_qty.toString();
        receivedShippingQuantities[index] =
          element.shipment_item_qty.toString(); // "0";
        element.rowKey = index;

        receivedShippingLineDetails[index] = [];
        receivedTypeOfShippingLineByScan[index] = false;
      }
      this.setState({
        shipmentLineList: [...shipmentLineList],
        textBarCode: "",
        receivedShippingQuantities: receivedShippingQuantities,
        receivedShippingLineDetails: receivedShippingLineDetails,
        receivedTypeOfShippingLineByScan: {
          ...receivedTypeOfShippingLineByScan,
        },
      });
      this.props.resetStoreState(serverApis.getShipmentLineList.actionName);
    }

    if (isObjectNotEmpty(this.props.barCodeShipmentLineList)) {
      let shipmentLineList = this.props.barCodeShipmentLineList;

      let receivedShippingQuantities = {};
      let shippmentTransNumber = "";
      let isPoLineAlreadyExist = false;

      for (let index = 0; index < shipmentLineList.length; index++) {
        const poLine = shipmentLineList[index];
        receivedShippingQuantities[poLine.po_barcode] =
          poLine.shipment_item_qty.toString();
        poLine.rowKey = poLine.po_barcode;
        shippmentTransNumber = poLine.trans_no;
        isPoLineAlreadyExist = this.state.shipmentLineList.some(
          (poLineExisting) => poLineExisting.po_barcode === poLine.po_barcode
        );
      }

      if (isObjectEmpty(this.state.selectedShipment)) {
        if (shipmentLineList.length > 1) {
          this.setState({
            showBarCodeShipmentSelectionPopup: true,
            barCodeShipmentLineList: [...shipmentLineList],
          });
        } else {
          const selectedShipment = this.props.shipmentNumberList.find(
            (poLine) => poLine.trans_no === shippmentTransNumber
          );
          if (isObjectNotEmpty(selectedShipment)) {
            this.setState({
              shipmentLineList: [...shipmentLineList],
              selectedShipment: selectedShipment,
              receivedShippingQuantities: receivedShippingQuantities,
            });
          }
        }
      } else {
        if (isPoLineAlreadyExist) {
          //Scroll to existing item.
          this.setState({
            textBarCode: "",
          });
        } else {
          this.setState({
            textBarCode: "",
            shipmentLineList: [
              ...shipmentLineList,
              ...this.state.shipmentLineList,
            ],
            receivedShippingQuantities: {
              ...receivedShippingQuantities,
              ...this.state.receivedShippingQuantities,
            },
          });
        }
      }

      this.props.resetStoreState(
        serverApis.getBarCodeShipmentLineList.actionName
      );
    }

    if (isObjectNotEmpty(this.props.errorMessageBarCode)) {
      this.setState({
        textBarCode: "",
      });
      this.props.setStoreState(resetActions.ERROR_MESSAGES_RESET_ALL);
    }

    if (isObjectNotEmpty(this.props.submitPurchaseReceiveSuccessMessage)) {
      showAlert.success("Items Received Successfully!");
      this.setState({
        shipmentLineList: [],
        textBarCode: "",
        receivedShippingQuantities: {},
        receivedShippingLineDetails: {},
        selectedShipment: {},
        uploadedImages: [],
      });
      this.props.resetStoreState(serverApis.submitPurchaseReceive.actionName);

      if (this.isReceiveTypeDirect()) {
        this.props.getOpenPurchaseLineListAction(
          this.state.receiveDetails.vendorDetails.code
        );
      } else {
        this.props.getShipmentNumberListAction(
          this.state.receiveDetails.vendorDetails.id
        );
      }
    }
    //new
    if (isObjectNotEmpty(this.props.vendorPalletBarcode)) {
      this.props.resetStoreState(serverApis.getPalletBarcode.actionName);
      var pdfFile = this.props.vendorPalletBarcode.file;
      var url =
        baseServerAdress.baseURL +
        `/reports/${baseServerAdress.schemaName}/` +
        pdfFile;
      window.open(url);
    }
    if (isObjectNotEmpty(this.props.vendorInnerCartons)) {
      this.props.resetStoreState(serverApis.getInnerCartons.actionName);
      var pdfFile = this.props.vendorInnerCartons.file;
      var url =
        baseServerAdress.baseURL +
        `/reports/${baseServerAdress.schemaName}/` +
        pdfFile;
      window.open(url);
    }
    if (isObjectNotEmpty(this.props.vendorMasterCartons)) {
      var pdfFile = this.props.vendorMasterCartons.file;
      var url =
        baseServerAdress.baseURL +
        `/reports/${baseServerAdress.schemaName}/` +
        pdfFile;
      window.open(url);
      this.props.resetStoreState(serverApis.getMasterCartons.actionName);
    }
  }

  removeShipmentLine = (shipmentLine) => {
    let shipmentLines = this.state.shipmentLineList.filter(
      (line) => line.rowKey !== shipmentLine.rowKey
    );

    let receivedShippingQuantities = this.state.receivedShippingQuantities;
    let receivedShippingLineDetails = this.state.receivedShippingLineDetails;

    delete receivedShippingLineDetails[shipmentLine.rowKey];
    delete receivedShippingQuantities[shipmentLine.rowKey];

    this.setState({
      shipmentLineList: [...shipmentLines],
      receivedShippingQuantities: receivedShippingQuantities,
      receivedShippingLineDetails: receivedShippingLineDetails,
    });
  };

  addUploadedImage = (imageRequestObject) => {
    let uploadedImages = this.state.uploadedImages;
    uploadedImages.push(imageRequestObject);

    this.setState({ uploadedImages: uploadedImages });
  };

  removeUploadedImage = (index) => {
    let uploadedImages = this.state.uploadedImages;
    uploadedImages.splice(index, 1);

    this.setState({ uploadedImages: [...uploadedImages] });
  };

  getPalletAssigned = (shipmentLine) => {
    let palletAssign = 0;

    const array = isObjectNotEmpty(
      this.state.receivedShippingLineDetails[shipmentLine.rowKey]
    )
      ? this.state.receivedShippingLineDetails[shipmentLine.rowKey]
      : [];

    for (let index = 0; index < array.length; index++) {
      const binLocation = array[index];

      for (
        let indexBinRow = 0;
        indexBinRow < binLocation.selectedQuantities.length;
        indexBinRow++
      ) {
        const quantityDetails = binLocation.selectedQuantities[indexBinRow];
        palletAssign =
          palletAssign +
          Number(quantityDetails.selectedQuantity) /
            Number(shipmentLine.pcs_per_pallet);
      }
    }
    return palletAssign.toFixed(3);
  };

  getTotalAssignQtyForShipmentLLine = (shipmentLine) => {
    let totalAssignedQty = 0;

    const receiveLines =
      this.state.receivedShippingLineDetails[shipmentLine.rowKey];

    if (isObjectNotEmpty(receiveLines)) {
      for (let indexLine = 0; indexLine < receiveLines.length; indexLine++) {
        const receiveLine = receiveLines[indexLine];

        for (
          let indexBinRow = 0;
          indexBinRow < receiveLine.selectedQuantities.length;
          indexBinRow++
        ) {
          const quantityDetails = receiveLine.selectedQuantities[indexBinRow];
          totalAssignedQty =
            totalAssignedQty + Number(quantityDetails.selectedQuantity);
        }
      }
    }
    return totalAssignedQty;
  };

  isAssignReceiveQtyMatch = () => {
    //  let totalReceiveQty = 0;

    const array = this.state.shipmentLineList;
    let totalAssignedQtyAllShipment = 0;

    let match = array.every((shipmentLine) => {
      let totalAssignedQty = 0;
      let totalRequiredQty = Number(
        this.state.receivedShippingQuantities[shipmentLine.rowKey]
      );

      const receiveLines =
        this.state.receivedShippingLineDetails[shipmentLine.rowKey];

      if (isObjectNotEmpty(receiveLines)) {
        for (let indexLine = 0; indexLine < receiveLines.length; indexLine++) {
          const receiveLine = receiveLines[indexLine];

          for (
            let indexBinRow = 0;
            indexBinRow < receiveLine.selectedQuantities.length;
            indexBinRow++
          ) {
            const quantityDetails = receiveLine.selectedQuantities[indexBinRow];
            totalAssignedQty =
              totalAssignedQty + Number(quantityDetails.selectedQuantity);
          }
        }
      }

      totalAssignedQtyAllShipment =
        totalAssignedQtyAllShipment + totalAssignedQty;
      return totalAssignedQty < totalRequiredQty + 1;
    });

    return match && totalAssignedQtyAllShipment > 0;
    // &&
    // this.state.shipmentLineList.length ===
    //   Object.keys(this.state.receivedShippingLineDetails).length
  };

  getOtherReceiveLines = (rowKey) => {
    let otherReceiveLines = [];
    for (const key in this.state.receivedShippingLineDetails) {
      if (Number(rowKey) !== Number(key)) {
        const receiveLines = this.state.receivedShippingLineDetails[key];
        otherReceiveLines = otherReceiveLines.concat(receiveLines);
      }
    }
    return otherReceiveLines;
  };

  callBackDefaultFloorSelection = (value, shipmentLine) => {
    if (isObjectNotEmpty(this.state.defaultFloorLocation)) {
      this.onSelectFloorLocation(
        this.state.defaultFloorLocation.value,
        this.state.defaultFloorLocation.binDetails,
        value,
        shipmentLine
      );
    }
  };

  updateReceiveQuantity = (value, shipmentLine) => {
    if (
      Number(value) > Number(shipmentLine.shipment_item_qty) ||
      isNaN(Number(value))
    ) {
      return;
    }

    let callBack = () => {
      this.callBackDefaultFloorSelection(value, shipmentLine);
    };

    this.onChangeReceiveQuanity(value, shipmentLine.rowKey, callBack);
  };

  handleDropdown = (key) => {
    const { id } = this.state.selectedShipment;
    if (key === serverApis.getMasterCartons.actionName) {
      this.props.getMasterCartonsAction(id);
    } else if (key === serverApis.getInnerCartons.actionName) {
      this.props.getInnerCartonsAction(id);
    } else if (key === serverApis.getPalletBarcode.actionName) {
      this.props.getPalletBarcodeSlipAction(id);
    } else {
      showAlert.error("Shipment Id not found");
    }
  };
  render() {
    const items = [
      {
        label: "All Master Cartons",
        key: serverApis.getMasterCartons.actionName,
      },
      {
        label: "All Inner Cartons",
        key: serverApis.getInnerCartons.actionName,
      },
      {
        label: "All Pallet",
        key: serverApis.getPalletBarcode.actionName,
      },
    ];
    
    const menu = (
      <Menu onClick={(e) => this.handleDropdown(e.key)}>
        {items.map((item) => (
          <Menu.Item key={item.key}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );
    
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <Modal
          title="Select Shipment Number"
          maskClosable={false}
          open={this.state.showBarCodeShipmentSelectionPopup}
          onOk={() => {
            if (isObjectEmpty(this.state.selectedBarCodeShipment)) {
              showAlert.error("Please select shipment number!");
              return;
            }

            const shipmentLines = this.state.barCodeShipmentLineList.filter(
              (shipmentLine) =>
                shipmentLine.trans_no ===
                this.state.selectedBarCodeShipment.trans_no
            );

            const selectedShipment = this.props.shipmentNumberList.find(
              (shipmentNumberDetails) =>
                shipmentNumberDetails.trans_no ===
                this.state.selectedBarCodeShipment.trans_no
            );

            let receivedShippingQuantities = {};

            for (let index = 0; index < shipmentLines.length; index++) {
              const shipmentLine = shipmentLines[index];
              receivedShippingQuantities[shipmentLine.po_barcode] =
                shipmentLine.shipment_item_qty.toString();
              shipmentLine.rowKey = shipmentLine.po_barcode;
            }

            this.setState({
              shipmentLineList: shipmentLines,
              selectedShipment: selectedShipment,
              receivedShippingQuantities: receivedShippingQuantities,
              showBarCodeShipmentSelectionPopup: false,
              barCodeShipmentLineList: [],
              selectedBarCodeShipment: {},
              textBarCode: "",
            });
          }}
          onCancel={() => {
            showAlert.success("cancel Called");
            this.setState({
              showBarCodeShipmentSelectionPopup: false,
              barCodeShipmentLineList: [],
              selectedBarCodeShipment: {},
              textBarCode: "",
            });
          }}
        >
          <div className="shipment-line mt-3">
            <h5> Shipment Number </h5>
            <div className="shipment-input-wrapper">
              <Form.Item label="" className="mr-2 w-50">
                <Select
                  placeholder="Select Shipment Number"
                  suffixIcon={
                    <SvgIcon name="select-arrow" viewbox="0 0 19.124 12.357" />
                  }
                  onSelect={(item, selectedOption) => {
                    this.setState({
                      selectedBarCodeShipment: selectedOption.shipment,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  value={
                    isObjectNotEmpty(this.state.selectedBarCodeShipment)
                      ? this.state.selectedBarCodeShipment.trans_no
                      : ""
                  }
                >
                  {this.state.barCodeShipmentLineList.map((item) => (
                    <Select.Option
                      title={item.trans_no}
                      key={item.trans_no}
                      value={
                        isObjectNotEmpty(item.trans_no) ? item.trans_no : ""
                      }
                      shipment={item}
                    >
                      {isObjectNotEmpty(item.trans_no) ? item.trans_no : ""}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Modal>

        <div className="common-section-container">
          <h2 className="main-title">
            {" "}
            Receiving{" "}
            {/* <span className="title-info">
              {this.state.receiveDetails.vendorDetails.name}
            </span> */}
          </h2>
          <div className="receive-detail-wrapper">
            <div className="receive-body">
              <div className="receive-detail-data">
                <div className="receive-data">
                  <label> Vendor </label>
                  <h4> {this.state.receiveDetails.vendorDetails.name}</h4>
                </div>
                <div className="receive-data">
                  <label> Receive Type </label>
                  <h4> {this.state.receiveDetails.receiveType.description}</h4>
                </div>
                <div className="receive-data">
                  <label> Receive Date </label>
                  <h4>
                    {dataFormatterMMDDYYYY(
                      this.state.receiveDetails.receiveDate
                    )}
                  </h4>
                  <Button
                    className="edit-icons"
                    onClick={() =>
                      this.props.navigate(componentPaths.RECEIVING)
                    }
                  >
                    <SvgIcon name="pencil" viewbox="0 0 30.849 30.849" />
                  </Button>
                </div>
              </div>
              {/* <div className="receive-detail-data">
                <div className="receive-data">
                  <label> Type </label>
                  <h4> {this.state.receiveDetails.receiveType.description} </h4>
                </div>
                <div className="receive-data">
                  <label> Date </label>
                  <h4>
                    {dataFormatterMMDDYYYY(
                      this.state.receiveDetails.receiveDate
                    )}
                  </h4>
                  <Button
                    className="edit-icons"
                    onClick={() =>
                      this.props.navigate(componentPaths.RECEIVING)
                    }
                  >
                    <SvgIcon name="pencil" viewbox="0 0 30.849 30.849" />
                  </Button>
                </div>
                <div className="receive-data">
                  <label> PO Number </label>
                  <h4> 143208 </h4>
                </div>
                <div className="receive-data">
                  <label> Item # </label>
                  <h4> Screen Clean Wipes </h4>
                </div>
              </div> */}
              <div
                className={
                  this.isReceiveTypeDirect()
                    ? "search-wrapper my-3"
                    : "search-wrapper my-3"
                }
              >
                <Form.Item
                  label=""
                  // className={this.isReceiveTypeDirect() ? "mr-2 w-50" : ""}
                >
                  <Input
                    placeholder="Search for PO Number / Item # / Barcode"
                    suffix={<SvgIcon name="search" viewbox="0 0 12 13" />}
                    value={this.state.textSearchBar}
                    onChange={(e) =>
                      this.setState({ textSearchBar: e.target.value })
                    }
                  />
                </Form.Item>
                {this.isReceiveTypeDirect() && (
                  // <Form.Item label="" >className=" w-50">
                  <Form.Item label="">
                    <Input
                      prefix={
                        <SvgIcon name="qr-icon" viewbox="0 0 35 16.848" />
                      }
                      placeholder="Scan Barcode"
                      value={this.state.textBarCode}
                      onChange={(event) =>
                        this.setState({ textBarCode: event.target.value })
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // this.props.getBarCodeShipmentLineListAction(
                          //   this.state.textBarCode,
                          //   this.state.receiveDetails.vendorDetails.id,
                          //   isObjectNotEmpty(this.state.selectedShipment)
                          //     ? this.state.selectedShipment.trans_no
                          //     : ""
                          // );

                          this.setState(
                            {
                              textBarCode: "",
                              textSearchBar: this.state.textBarCode,
                            },
                            () => {
                              let shipmentLine =
                                this.state.shipmentLineList.find(
                                  (shipmentLine) =>
                                    shipmentLine.po_barcode ===
                                      this.state.textSearchBar ||
                                    shipmentLine.barcode ===
                                      this.state.textSearchBar
                                );

                              if (isObjectNotEmpty(shipmentLine)) {
                                this.onChangeReceiveQuanity(
                                  shipmentLine.shipment_item_qty,
                                  shipmentLine.rowKey
                                );
                              } else {
                                showAlert.error("Item not found!");
                              }
                            }
                          );
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              {!this.isReceiveTypeDirect() && (
                <div className="shipment-line mt-3">
                  <h5> Shipment Number </h5>
                  <div className="shipment-input-wrapper">
                    <Form.Item label="" className="mr-2 w-50">
                      <Select
                        placeholder="Select Shipment Number"
                        suffixIcon={
                          <SvgIcon
                            name="select-arrow"
                            viewbox="0 0 19.124 12.357"
                          />
                        }
                        onSelect={(item, selectedOption) => {
                          this.resetAllData({
                            selectedShipment: selectedOption.shipment,
                          });

                          this.props.getShipmentLineListAction(
                            selectedOption.shipment.id
                          );
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        value={
                          isObjectNotEmpty(this.state.selectedShipment)
                            ? this.state.selectedShipment.trans_no
                            : ""
                        }
                      >
                        {this.props.shipmentNumberList.map((item) => (
                          <Select.Option
                            title={item.trans_no}
                            key={item.trans_no}
                            value={
                              isObjectNotEmpty(item.trans_no)
                                ? item.trans_no
                                : ""
                            }
                            shipment={item}
                          >
                            {isObjectNotEmpty(item.trans_no)
                              ? item.trans_no
                              : ""}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="" className=" w-50">
                      <Input
                        prefix={
                          <SvgIcon name="qr-icon" viewbox="0 0 35 16.848" />
                        }
                        placeholder="Scan Barcode"
                        value={this.state.textBarCode}
                        onChange={(event) =>
                          this.setState({ textBarCode: event.target.value })
                        }
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            // this.props.getBarCodeShipmentLineListAction(
                            //   this.state.textBarCode,
                            //   this.state.receiveDetails.vendorDetails.id,
                            //   isObjectNotEmpty(this.state.selectedShipment)
                            //     ? this.state.selectedShipment.trans_no
                            //     : ""
                            // );

                            this.setState(
                              {
                                textBarCode: "",
                                textSearchBar: this.state.textBarCode,
                              },
                              () => {
                                let shipmentLine =
                                  this.state.shipmentLineList.find(
                                    (shipmentLine) =>
                                      shipmentLine.po_barcode ===
                                      this.state.textSearchBar
                                  );

                                if (isObjectNotEmpty(shipmentLine)) {
                                  this.onChangeReceiveQuanity(
                                    shipmentLine.shipment_item_qty,
                                    shipmentLine.rowKey
                                  );
                                } else {
                                  showAlert.error("Item not found!");
                                }
                              }
                            );
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}

              {this.filteredShipmentLineList().map((shipmentLine, index) => (
                <div key={index} className="shipment-line-detail mt-3">
                  <Button
                    className="shipment-close-icon"
                    onClick={() => {
                      this.removeShipmentLine(shipmentLine);
                    }}
                  >
                    <SvgIcon name="close" viewbox="0 0 13.426 13.423" />
                  </Button>
                  <div className="shipment-line-detail-top">
                    <ul className="mb-0 pl-0 ">
                      <li>
                        <label> PO#</label>
                        <h5> {shipmentLine.po_no}</h5>
                      </li>
                      <li>
                        <label> Item# </label>
                        <h5> {shipmentLine.catalog_item_code}</h5>
                      </li>
                      <li>
                        {/* <label> Descriptions </label>
                        <h5>
                          {shipmentLine.item_description}
                           <span className="read-more-btn"> Read More </span> 
                        </h5>
                        */}
                        <Tooltip
                          title={shipmentLine.item_description}
                          className="info-tooltip"
                        >
                          <Button>
                            <SvgIcon name="info-icon" viewbox="0 0 20 21" />
                          </Button>
                        </Tooltip>
                      </li>
                    </ul>
                  </div>
                  <div className="shipment-line-detail-inner">
                    <ul className="mb-0 pl-0 ">
                      <li>
                        <label>Shipment Item Qty</label>
                        <Form.Item label="" className="w-50 ml-2">
                          <Input
                            value={shipmentLine.shipment_item_qty}
                            disabled
                          />
                        </Form.Item>
                      </li>
                      <li>
                        <AddQuantityModal
                          itemCode={shipmentLine.catalog_item_code}
                          itemId={shipmentLine.catalog_item_id}
                          onAddQuantity={(quantity, binDetails) => {
                            this.addReceviedShippingDetailsByScan(
                              binDetails,
                              quantity,
                              shipmentLine
                            );
                          }}
                          warehouseList={this.props.warehouseList}
                        />
                        <Form.Item label="" className="w-50">
                          <Input
                            disabled={true}
                            value={this.getTotalAssignQtyForShipmentLLine(
                              shipmentLine
                            )}
                            onChange={(event) => {
                              this.updateReceiveQuantity(
                                event.target.value,
                                shipmentLine
                              );
                            }}
                          />
                        </Form.Item>
                      </li>
                      <li>
                        <label> Pallets Required </label>
                        <Form.Item label="" className="w-50 ml-2">
                          <Input
                            value={
                              isObjectNotEmpty(
                                this.state.receivedShippingQuantities[
                                  shipmentLine.rowKey
                                ]
                              )
                                ? Number(
                                    this.state.receivedShippingQuantities[
                                      shipmentLine.rowKey
                                    ] / shipmentLine.pcs_per_pallet
                                  ).toFixed(3)
                                : ""
                            }
                            disabled
                          />
                        </Form.Item>
                      </li>
                    </ul>
                  </div>
                  <div className="shipment-location mt-3">
                    <div className="">
                      <label>Locations</label>

                      {isObjectNotEmpty(
                        this.state.receivedShippingLineDetails[
                          shipmentLine.rowKey
                        ]
                      ) &&
                        this.state.receivedShippingLineDetails[
                          shipmentLine.rowKey
                        ].map((receiveLine, indexLine) =>
                          receiveLine.selectedQuantities.map(
                            (quantityDetails, indexBinRow) => (
                              <h6
                                className="shipment-location-selected"
                                key={`${indexLine} ${indexBinRow}`}
                              >
                                {}
                                {receiveLine.bin.code}
                                &nbsp;&nbsp;&nbsp;
                                {quantityDetails.selectedQuantity}
                                &nbsp;&nbsp;&nbsp;
                                {dataFormatterMMDDYYYY2(
                                  quantityDetails.expiryDate
                                )}
                                <SvgIcon
                                  onClick={() => {
                                    this.removeReceivedBinRow(
                                      indexBinRow,
                                      shipmentLine,
                                      receiveLine.bin
                                    );
                                  }}
                                  name="close"
                                  viewbox="0 0 13.426 13.423"
                                />
                              </h6>
                            )
                          )
                        )}
                      <div className="d-flex align-items-center mt-1 ">
                        {/* <AutoCompleteView
                          warehouseList={this.props.warehouseList.filter(
                            (warehouse) =>
                              isObjectNotEmpty(warehouse.floor_location)
                          )}
                          totalReceiveQuantity={
                            isObjectNotEmpty(
                              this.state.receivedShippingQuantities[
                                shipmentLine.rowKey
                              ]
                            )
                              ? Number(
                                  this.state.receivedShippingQuantities[
                                    shipmentLine.rowKey
                                  ]
                                )
                              : 0
                          }
                          addReceviedShippingDetails={
                            this.addReceviedShippingDetails
                          }
                          shipmentLine={shipmentLine}
                          selectedFloorLocations={
                            this.state.selectedFloorLocations
                          }
                          onSelectFloorLocation={this.onSelectFloorLocation}
                          onChangeReceiveQuanity={this.onChangeReceiveQuanity}
                          rowIndex={index}
                          showDefaultFloorAlert={this.showDefaultFloorAlert}
                        />
                        / */}
                        <FloorPlaneView
                          otherReceiveLines={this.getOtherReceiveLines(
                            shipmentLine.rowKey
                          )}
                          resetSelectedLocations={() => {
                            let receivedShippingLineDetails =
                              this.state.receivedShippingLineDetails;
                            let receivedTypeOfShippingLineByScan =
                              this.state.receivedTypeOfShippingLineByScan;

                            if (
                              receivedTypeOfShippingLineByScan[
                                shipmentLine.rowKey
                              ]
                            ) {
                              receivedShippingLineDetails[shipmentLine.rowKey] =
                                [];
                              receivedTypeOfShippingLineByScan[index] = false;
                            } else {
                            }
                            this.setState({
                              receivedShippingLineDetails: {
                                ...receivedShippingLineDetails,
                              },
                              receivedTypeOfShippingLineByScan: {
                                ...receivedTypeOfShippingLineByScan,
                              },
                            });
                          }}
                          totalReceiveQuantity={
                            isObjectNotEmpty(
                              this.state.receivedShippingQuantities[
                                shipmentLine.rowKey
                              ]
                            )
                              ? Number(
                                  this.state.receivedShippingQuantities[
                                    shipmentLine.rowKey
                                  ]
                                )
                              : 0
                          }
                          palletRequired={
                            isObjectNotEmpty(
                              this.state.receivedShippingQuantities[
                                shipmentLine.rowKey
                              ]
                            )
                              ? Number(
                                  this.state.receivedShippingQuantities[
                                    shipmentLine.rowKey
                                  ] / shipmentLine.pcs_per_pallet
                                ).toFixed(3)
                              : ""
                          }
                          palletAssigned={this.getPalletAssigned(shipmentLine)}
                          shipmentLine={shipmentLine}
                          addReceviedShippingDetails={
                            this.addReceviedShippingDetails
                          }
                          removeReceviedShippingDetails={
                            this.removeReceviedShippingDetails
                          }
                          receivedShippingLines={
                            isObjectNotEmpty(
                              this.state.receivedShippingLineDetails[
                                shipmentLine.rowKey
                              ]
                            )
                              ? this.state.receivedShippingLineDetails[
                                  shipmentLine.rowKey
                                ]
                              : []
                          }
                          removeReceivedBinRow={this.removeReceivedBinRow}
                        />
                        {/* <Button className="ml-2 location-btn">Location</Button> */}
                      </div>
                    </div>
                    <div className="pt-2 d-flex justify-content-end align-items-center">
                      <label>
                        Pallets <br /> Assigned
                      </label>
                      <Form.Item label="" className="mb-0 ml-2">
                        <Input
                          value={this.getPalletAssigned(shipmentLine)}
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="receive-footer mt-auto pb-0 pt-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <AddAttachmentModal
                    addUploadedImage={this.addUploadedImage}
                    uploadedImages={this.state.uploadedImages}
                  />
                  {isObjectNotEmpty(this.state.selectedShipment) && (
                    <div className="mr-2">
                      <ViewAttachmentModal
                        selectedShipment={this.state.selectedShipment}
                      />
                    </div>
                  )}
                </div>
                {isObjectNotEmpty(this.state.selectedShipment) && (
                  <div>
                    <Dropdown
                      overlayClassName="printbarcode-menu"
                      overlay={menu}
                      placement="topRight"
                    >
                      <Button size="small" className="printbarcode-flot-btn">
                        Print Barcode
                      </Button>
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className="attchaed-files-list">
                {this.state.uploadedImages.map((uploadedImage, indexImage) => (
                  <div className="file-list-items" key={indexImage}>
                    <div>{uploadedImage.attachment_file_name}</div>
                    <Button
                      onClick={() => this.removeUploadedImage(indexImage)}
                    >
                      <SvgIcon name="trash" viewbox="0 0 31.5 36" />
                    </Button>
                  </div>
                ))}
              </div>
              <div className="mt-1">
                <Button
                  className="primary-btn w-100"
                  disabled={!this.isAssignReceiveQtyMatch()}
                  onClick={() => {
                    const userDetails = loggedInUserDetail();
                    let purchaseReceiveLines = [];
                    let purchaseReceive = {};
                    let totalItemQuantity = 0;
                    let totalPalletsRequired = 0;
                    this.state.shipmentLineList.forEach(
                      (shipmentLine, index) => {
                        if (
                          // Number(
                          //   this.state.receivedShippingQuantities[
                          //     shipmentLine.rowKey
                          //   ]
                          // ) > 0 &&
                          this.getTotalAssignQtyForShipmentLLine(shipmentLine) >
                          0
                        ) {
                          if (isObjectEmpty(purchaseReceive)) {
                            purchaseReceive = {
                              vendor_id: shipmentLine.vendor_id,
                              vendor_code:
                                this.state.receiveDetails.vendorDetails.code,
                              location_id: "",
                              location_code: "",
                              tracking_no: shipmentLine.shipment_tracking_no,
                              term_code: "",
                              trans_type:
                                this.state.receiveDetails.receiveType.value,
                              ref_trans_no: shipmentLine.trans_no,
                              ref_shipping_code: shipmentLine.shipping_code,
                              ref_ship_date: shipmentLine.ship_date,
                              ext_ref_no: "",
                              ext_ref_date: "",
                              trans_no: "",
                              trans_date: this.state.receiveDetails.receiveDate,
                              trans_flag: "A",
                              remarks: "",
                              item_qty: shipmentLine.shipment_item_qty,
                              total_pallet_required:
                                shipmentLine.total_pallet_required,
                              account_period_code: this.props.accountPeriodCode,
                              ref_trans_bk: shipmentLine.trans_bk,
                              ref_trans_date: shipmentLine.trans_date,
                              post_flag: "P",
                              store_code: "",
                              company_id: userDetails.company_id,
                              updated_by: userDetails.id,
                              updated_by_code: userDetails.user_cd,
                              created_by: userDetails.id,
                              id: "",
                              // lock_version: 0,
                            };

                            if (this.isReceiveTypeDirect()) {
                              purchaseReceive.tracking_no = "";
                              purchaseReceive.ref_trans_no = "";
                              purchaseReceive.ref_trans_bk = "";
                              purchaseReceive.ref_trans_date = "";
                            }
                          }

                          this.state.receivedShippingLineDetails[
                            shipmentLine.rowKey
                          ].forEach((receiveLine) => {
                            receiveLine.selectedQuantities.forEach(
                              (quantityDetails) => {
                                let purchaseReceiveLine = {
                                  ref_trans_id: shipmentLine.id,
                                  ref_trans_no: shipmentLine.trans_no,
                                  ref_trans_bk: shipmentLine.trans_bk,
                                  ref_trans_date: shipmentLine.trans_date,
                                  ref_po_no: shipmentLine.po_no,
                                  ref_po_serial_no: shipmentLine.po_serial_no,
                                  catalog_item_id: shipmentLine.catalog_item_id,
                                  catalog_item_code:
                                    shipmentLine.catalog_item_code,
                                  item_type: shipmentLine.item_type,
                                  item_description:
                                    shipmentLine.item_description,
                                  ref_qty: shipmentLine.shipment_item_qty,
                                  item_qty: quantityDetails.selectedQuantity,
                                  item_price: shipmentLine.shipment_item_price,
                                  shipment_tracking_no:
                                    shipmentLine.shipment_tracking_no,
                                  location_id: receiveLine.bin.id,
                                  location_code: receiveLine.bin.code,
                                  shipping_code: shipmentLine.shipping_code,
                                  ship_date: shipmentLine.ship_date,
                                  vendor_sku_no: shipmentLine.vendor_sku_no,
                                  total_pallet_required:
                                    Number(quantityDetails.selectedQuantity) /
                                    Number(shipmentLine.pcs_per_pallet),
                                  pcs_per_pallet: shipmentLine.pcs_per_pallet,
                                  expiry_date: quantityDetails.expiryDate,
                                  po_barcode: shipmentLine.po_barcode,
                                  company_id: userDetails.company_id,
                                  trans_bk: "",
                                  trans_no: "",
                                  trans_date: "",
                                  trans_flag: "A",
                                  id: "",
                                  serial_no: "",
                                  ref_serial_no: shipmentLine.serial_no,
                                };

                                if (this.isReceiveTypeDirect()) {
                                  purchaseReceiveLine.ref_po_no =
                                    shipmentLine.trans_no;
                                  purchaseReceiveLine.ref_po_serial_no =
                                    shipmentLine.serial_no;
                                  purchaseReceiveLine.ref_qty =
                                    shipmentLine.open_qty;
                                  purchaseReceiveLine.item_price =
                                    shipmentLine.item_price;
                                  purchaseReceiveLine.open_qty_for_receiving =
                                    shipmentLine.open_qty_for_receiving;
                                  purchaseReceiveLine.po_barcode =
                                    shipmentLine.barcode;

                                  //  keys to be blank

                                  purchaseReceiveLine.ref_trans_no = "";
                                  purchaseReceiveLine.ref_trans_bk = "";
                                  purchaseReceiveLine.ref_trans_date = "";
                                  purchaseReceiveLine.ref_serial_no = "";
                                }

                                purchaseReceiveLine.item_amt =
                                  Number(purchaseReceiveLine.item_price) *
                                  Number(purchaseReceiveLine.item_qty);
                                purchaseReceiveLine.net_amt =
                                  Number(purchaseReceiveLine.item_price) *
                                  Number(purchaseReceiveLine.item_qty);

                                totalItemQuantity =
                                  Number(quantityDetails.selectedQuantity) +
                                  totalItemQuantity;
                                totalPalletsRequired =
                                  Number(
                                    purchaseReceiveLine.total_pallet_required
                                  ) + totalPalletsRequired;
                                purchaseReceiveLines.push(purchaseReceiveLine);
                              }
                            );
                          });
                        }
                      }
                    );
                    purchaseReceive["purchase_receive_lines"] =
                      purchaseReceiveLines;
                    purchaseReceive["purchase_receive_attachments"] =
                      this.state.uploadedImages;

                    purchaseReceive.item_qty = totalItemQuantity;
                    purchaseReceive.total_pallet_required =
                      totalPalletsRequired;

                    const requestObject = {
                      purchase_receives: [purchaseReceive],
                    };

                    this.props.submitPurchaseReceiveAction(requestObject);
                  }}
                >
                  Finish Receiving
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const errorSelector = createErrorMessageSelector([
  serverApis.getBarCodeShipmentLineList.actionName,
]);

const loadingSelector = createLoadingSelector([
  serverApis.getOpenPurchaseLineList.actionName,
  serverApis.getShipmentLineList.actionName,
  serverApis.getShipmentNumberList.actionName,
  serverApis.getBarCodeShipmentLineList.actionName,
  serverApis.submitPurchaseReceive.actionName,
  serverApis.getPalletBarcode.actionName,
  serverApis.getInnerCartons.actionName,
  serverApis.getMasterCartons.actionName,
]);

const mapStateToProps = (state) => ({
  shipmentLineList: state.shipmentLineList,
  shipmentNumberList: state.shipmentNumnberList,
  barCodeShipmentLineList: state.barCodeShipmentLineList,
  submitPurchaseReceiveSuccessMessage:
    state.submitPurchaseReceiveSuccessMessage,
  accountPeriodCode: state.accountPeriodCode,
  isFetchingData: loadingSelector(state),
  errorMessageBarCode: errorSelector(state),
  warehouseList: state.warehouseList,
  vendorPalletBarcode: state.vendorPalletBarcode,
  vendorInnerCartons: state.vendorInnerCartons,
  vendorMasterCartons: state.vendorMasterCartons,
});

export default connect(mapStateToProps, {
  getShipmentNumberListAction,
  getOpenPurchaseLineListAction,
  getShipmentLineListAction,
  // getBarCodeShipmentLineListAction,
  submitPurchaseReceiveAction,
  getAcountPeriodCodeAction,
  resetStoreState,
  setStoreState,
  getWarehouseListAction,
  getPalletBarcodeSlipAction,
  getInnerCartonsAction,
  getMasterCartonsAction,
})(ReceivingDetailView);
