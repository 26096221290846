import { showAlert } from "../../common/alert-messages";
import { isObjectEmpty } from "../../common/utils";
import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const productionPickInboxListReducer = (state = [], action) => {
  const actionName = serverApis.productionPickInboxList.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      if (isObjectEmpty(action.json.sales_orders)) {
        showAlert.error("No records found!");
      }
      return [...state, ...action.json.sales_orders];

    case getResetAction(actionName):
      return [];
    default:
      return state;
  }
};

export const productionPickInboxScanItemReducer = (state = {}, action) => {
  const actionNamePick = serverApis.productionPickInboxScanItem.actionName;
  const actionNameUnPick = serverApis.productionUnPickInboxScanItem.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionNamePick, requestStatus.SUCCESS):
    case getActionNameWithStatus(actionNameUnPick, requestStatus.SUCCESS):
      if (isObjectEmpty(action.json.sales_orders)) {
        showAlert.error("No records found!");
        return {};
      }
      return { ...action.json.sales_orders[0] };

    case getResetAction(actionNamePick):
      return {};
    default:
      return state;
  }
};
