import React, { Component } from "react";
import { Button, Form, Select, DatePicker, Spin } from "antd";
import { SvgIcon } from "../../../components/common";
import { componentPaths } from "../../../constants/component-paths";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  getVendorListAction,
  getReceiveTypeListAction,
  resetStoreState,
} from "../../../redux/actions";
import { serverApis } from "../../../constants/server-apis";
import { dataFormatterMMDDYYYY, isObjectNotEmpty } from "../../../common/utils";
import { dateFormats } from "../../../constants";
import moment from "moment";

class ReceivingTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVendor: {},
      selectedReceiveType: {},
      selectedReceiveDate: moment(new Date()),
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.props.getReceiveTypeListAction();
  }

  isSelectedShippingVendor = () => {
    return this.state.selectedReceiveType.value === "S";
  };

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={() =>
            this.props.navigate(componentPaths.RECEIVING_DETAIL, {
              state: JSON.stringify({
                vendorDetails: this.state.selectedVendor,
                receiveType: this.state.selectedReceiveType,
                receiveDate: this.state.selectedReceiveDate,
              }),
            })
          }
        >
          <Form.Item
            label="Receive Type"
            name="Receive Type"
            rules={[{ required: true, message: "Please Select Receive Type!" }]}
          >
            <Select
              placeholder="Select Receive Type"
              suffixIcon={
                <SvgIcon name="select-arrow" viewbox="0 0 19.124 12.357" />
              }
              onSelect={(item, selectedOption) => {
                this.setState(
                  {
                    selectedReceiveType: selectedOption.receiveType,
                    selectedVendor: {},
                  },
                  () => {
                    this.formRef.current.resetFields(["vendor"]);
                    this.props.resetStoreState(
                      serverApis.getVendorList.actionName
                    );
                    this.props.getVendorListAction(
                      this.isSelectedShippingVendor()
                    );
                  }
                );
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {this.props.receiveTypeList.map((item) => (
                <Select.Option
                  title={item.description}
                  key={item.description}
                  value={
                    isObjectNotEmpty(item.description) ? item.description : ""
                  }
                  receiveType={item}
                >
                  {isObjectNotEmpty(item.description) ? item.description : ""}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Vendor"
            name={"vendor"}
            rules={[{ required: true, message: "Please select Vendor!" }]}
          >
            {/* <div className="d-flex"> */}
            <Select
              suffixIcon={
                <SvgIcon name="select-arrow" viewbox="0 0 19.124 12.357" />
              }
              placeholder="Select Vendor"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.code.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionFilterProp="children"
              showSearch
              onSelect={(item, selectedOption) => {
                this.setState({ selectedVendor: selectedOption.vendor });
              }}
            >
              {this.props.vendorList.map((item) => (
                <Select.Option
                  code={item.code}
                  title={item.name}
                  key={item.name}
                  value={isObjectNotEmpty(item.name) ? item.name : ""}
                  vendor={item}
                >
                  {isObjectNotEmpty(item.name) ? item.name : ""}
                </Select.Option>
              ))}
            </Select>
            {/* <div className="search-icons">
            <SvgIcon name="search-icon" viewbox="0 0 21.687 26.848" />
          </div> */}
            {/* </div> */}
          </Form.Item>

          {/* <Form.Item label="PO Number" name="PO Number">
            <Input />
          </Form.Item>
          <Form.Item label="Item Number" name="Item Number">
            <Input />
          </Form.Item> */}

          <Form.Item
            label="Receive Date"
            name="Receive Date"
            // rules={[{ required: true, message: "Please Select Receive Date!" }]}
          >
            {/* <div className="d-flex"> */}
            <DatePicker
              name="activity_dt"
              allowClear={false}
              onChange={(date, dateString) => {
                this.setState({
                  selectedReceiveDate: date,
                });
              }}
              format={dateFormats.MM_DD_YYYY}
              defaultValue={this.state.selectedReceiveDate}
            />
            {/* <div className="search-icons">
                <SvgIcon name="calendar-icon" viewbox="0 0 21.607 22.107" />
              </div> */}
            {/* </div> */}
          </Form.Item>
          <div className="mt-3">
            <Button
              className="primary-btn w-100"
              // onClick={() =>
              //   this.props.navigate(componentPaths.RECEIVING_DETAIL)
              // }
              htmlType="submit"
            >
              Next
            </Button>
          </div>
        </Form>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getVendorList.actionName,
  serverApis.getReceiveTypeList.actionName,
]);

const mapStateToProps = (state) => ({
  vendorList: state.vendorList,
  receiveTypeList: state.receiveTypeList,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getVendorListAction,
  getReceiveTypeListAction,
  resetStoreState,
})(ReceivingTab);
