export const USER_DETAIL_KEY = "USER_PROMO_RECEIVE";
export const NAVIGATION_REFERENCE = "NAVIGATION_REFERENCE";

export const loaderClass = {
  FULL_SCREEN: "modalfullscreen-loader",
};

export const requestCreatorKey = {
  REQUEST_ACTION_TYPE: "actionType",
  REQUEST_URL: "requestURL",
  REQUEST_HEADER: "requestHeader",
  REQUEST_PAYLOAD: "requestPayload",
  REQUEST_METHOD: "requestMethod",
  SHOW_ERROR_FROM_TRANSACTION: "showErrorFromTransaction",
};
export const requestContentType = {
  APPLICATION_JSON: "application/json",
};

export const requestMethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const dateFormats = {
  MM_DD_YYYY: "MM-DD-YYYY",
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYYMMDD_SLASH: "YYYY/MM/DD",
};

export const dataType = {
  STRING: "String",
  NUMBER: "Number",
  DATE: "Date",
};
