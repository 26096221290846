import React, { Component } from "react";
import { Button, Modal } from "antd";
import { SvgIcon, Row, Col } from "../../../../components/common";
import { connect } from "react-redux";
import { saveProductionUnPickInboxAction } from "../../../../redux/actions";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";
import "./index.less";

class UnPickedConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  render() {
    return (
      <>
        {this.props.showReloadButton ? (
          <SvgIcon
            className="refresh-icon"
            name="refresh"
            viewbox="0 0 18.84 21.499"
            onClick={this.showModal}
          />
        ) : (
          <Button
            className="primary-btn bottom-btn"
            disabled={isObjectEmpty(this.props.selectedOrder)}
            type="primary"
            block
            form="my-form"
            onClick={this.showModal}
          >
            UNPICK
            {isObjectNotEmpty(this.props.selectedOrder) ? ` (1)` : ""}
          </Button>
        )}
        <Modal
          title="Confirmation"
          className="pickedconfirmation-modal"
          centered
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          footer={null}
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="card">
            <div className="card-body">
              <Row>
                <Col>
                  <p>
                    Are you sure you want to Unpick{" "}
                    <b>{this.props.selectedOrder.picked_qty}</b> items you’ve
                    picked against Job{" "}
                    <b># {this.props.selectedOrder.trans_no}</b>
                  </p>
                </Col>
              </Row>
              <Row className="bottom-action">
                <Col>
                  <Button onClick={this.handleCancel}>CANCEL</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.saveProductionUnPickInboxAction(
                        this.props.selectedOrder.sales_order_id
                      );
                      this.handleCancel();
                    }}
                  >
                    UNPICK
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(null, {
  saveProductionUnPickInboxAction,
})(UnPickedConfirmationModal);
