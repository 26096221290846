import React, { Component } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { Button, Modal, Input, Checkbox, Form, DatePicker } from "antd";
import { SvgIcon, Row, Col } from "../../../components/common";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";
import { isFlagTrueY } from "../../../common/methods";
// import { dateFormats } from "../../../../constants";
import "./index.less";
import { dateFormats } from "../../../constants";

class FloorPlansBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      binTextDetails: {},
    };
  }

  initialTextDetails = () => {
    return {
      selectedQuantity: 0,
      expiryDate: "",
    };
  };

  onchangeItemExpiry = (date, binCode) => {
    let binTextDetails = this.state.binTextDetails;

    if (isObjectEmpty(binTextDetails[binCode])) {
      binTextDetails[binCode] = { expiryDate: date };
    } else {
      binTextDetails[binCode].expiryDate = date;
    }
    this.setState({ binTextDetails });
  };

  resetBinEditableDetails = (binCode) => {
    let binTextDetails = this.state.binTextDetails;

    binTextDetails[binCode] = { ...this.initialTextDetails() };
    this.setState({ binTextDetails });
  };

  onchangeItemQty = (qty, binCode) => {
    let binTextDetails = this.state.binTextDetails;

    if (isObjectEmpty(binTextDetails[binCode])) {
      binTextDetails[binCode] = { selectedQuantity: qty };
    } else {
      binTextDetails[binCode].selectedQuantity = qty;
    }
    this.setState({ binTextDetails });
  };

  showModal = () => {
    if (isFlagTrueY(this.props.containerDetails.valid_section_aisle)) {
      this.setState({ isModalOpen: true });
    }
  };

  handleOk = () => {
    this.oncloseFloorPlanBox();
    this.setState({ isModalOpen: false });
  };

  oncloseFloorPlanBox = () => {
    if (this.props.onCloseFloorPlansBox) {
      this.props.onCloseFloorPlansBox();
    }
  };

  handleCancel = () => {
    this.oncloseFloorPlanBox();
    this.setState({ isModalOpen: false });
  };

  isBinBlocked = (binDetails) => {
    // allowed mixed items change
    // const isOtherItemAllocated = this.props.otherReceiveLines.some(
    //   (otherReceiveLine) => {
    //     return (
    //       isObjectEmpty(binDetails.catalog_item_code) &&
    //       binDetails.code === otherReceiveLine.bin.code &&
    //       binDetails.catalog_item_code !== otherReceiveLine.catalog_item_code
    //     );
    //   }
    // );

    // allowed mixed items change
    // const otherItemFilled =
    //   isObjectNotEmpty(binDetails.catalog_item_code) &&
    //   binDetails.catalog_item_code !==
    //     this.props.shipmentLine.catalog_item_code;

    const availableQuantityZero = Number(binDetails.available_stock_qty) === 0;

    return availableQuantityZero; // allowed mixed items change || otherItemFilled || isOtherItemAllocated;
  };

  getBinClassName = (binDetails, selected) => {
    if (this.isBinBlocked(binDetails)) {
      return "box-col red-box";
    } else if (selected) {
      return "box-col blue-box-checked";
    } else if (
      binDetails.catalog_item_code === this.props.shipmentLine.catalog_item_code
    ) {
      return "box-col green-box";
    } else {
      return "box-col";
    }
  };

  pad = (num, size) => {
    var s = "         " + Number(num).toFixed(3);
    return s.substring(s.length - size);
  };

  componentDidUpdate() {
    if (
      isObjectNotEmpty(this.props.selectedRack) &&
      this.props.selectedRack.rackLocationTitle ===
        this.getRackLocationTitle() &&
      !this.state.isModalOpen
    ) {
      this.showModal();
    }
  }

  getRackLocationTitle = () => {
    return `${this.props.containerDetails.aisle}-${this.props.containerDetails.section}`;
  };

  render() {
    const rackLocationTitle = this.getRackLocationTitle();

    let totaAssignedQty = 0;
    this.props.receivedShippingLines.forEach((binLocation) => {
      binLocation.selectedQuantities.forEach((quantityDetails) => {
        totaAssignedQty =
          totaAssignedQty + Number(quantityDetails.selectedQuantity);
      });
    });
    return (
      <React.Fragment
        key={`${this.props.containerDetails.aisle}-
            ${this.props.containerDetails.section}`}
      >
        <div className={this.props.className} onClick={this.showModal}>
          {isFlagTrueY(this.props.containerDetails.valid_section_aisle)
            ? this.pad(this.props.counts, 10)
            : "."}
          <span className="floor-title">{rackLocationTitle}</span>
        </div>
        {this.state.isModalOpen && (
          <Modal
            title={`Available bins in ${rackLocationTitle} : ${this.props.containerDetails.bins.length}`}
            className="floor-plan-model-bins"
            centered
            open={this.state.isModalOpen}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
            maskClosable={false}
          >
            <div className="floor-wrapper">
              <div className="floor-head-wrapper floorheadwrapper-bins d-flex">
                <Checkbox className="checkbox-cus" checked={true}>
                  Contains {this.props.shipmentLine.catalog_item_code}
                </Checkbox>
                <Checkbox className="checkbox-cus" checked={false}>
                  N Pallets' Space Available
                </Checkbox>
                <Checkbox
                  className="notavailabe-check checkbox-cus"
                  checked={false}
                >
                  Not Available
                </Checkbox>
              </div>
              <div className="bins-heading mt-2">
                <Row className="bins-heading-row mx-3 py-2">
                  <Col className="colums pl-2" xs="4">
                    <span>Location</span>
                  </Col>
                  <Col className="colums text-center" xs="3">
                    <span>Qty</span>
                  </Col>
                  <Col className="colums text-center" xs="3">
                    <span>Exp. Date</span>
                  </Col>
                </Row>
              </div>
              <div className="floorplan-list-wrapper">
                {this.props.containerDetails.bins.map((bin) => {
                  const receivedLocationDetails = this.props.receivedShippingLines.find(
                    (line) => line.bin.code === bin.code
                  );

                  return (
                    <Row key={bin.code}>
                      <Col className="colums" xs="4" key={"existingLine"}>
                        <div
                          className="box-row"
                          onClick={
                            !this.isBinBlocked(bin)
                              ? isObjectEmpty(receivedLocationDetails)
                                ? () => {
                                    this.props.addReceviedShippingDetails(
                                      bin,
                                      0,
                                      this.props.shipmentLine
                                    );
                                  }
                                : () => {
                                    this.props.removeReceviedShippingDetails(
                                      bin,
                                      this.props.shipmentLine
                                    );
                                  }
                              : () => {}
                          }
                        >
                          <div
                            className={this.getBinClassName(
                              bin,
                              isObjectNotEmpty(receivedLocationDetails)
                            )}
                          >
                            <div className="counts">
                              {bin.available_stock_qty}
                            </div>
                            <span>{bin.bin}</span>
                          </div>
                        </div>
                      </Col>
                      {isObjectNotEmpty(receivedLocationDetails) && (
                        <>
                          <Col className="colums" xs="3" key={"receiveLine"}>
                            <div className="value-row">
                              <div className="value-col">
                                {/* <label>{bin.bin}</label> */}
                                <Input
                                  value={
                                    isObjectEmpty(
                                      this.state.binTextDetails[bin.code]
                                    )
                                      ? 0
                                      : this.state.binTextDetails[bin.code]
                                          .selectedQuantity
                                  }
                                  onChange={(event) => {
                                    let number = Number(event.target.value);
                                    if (
                                      number >
                                        Number(bin.available_stock_qty) ||
                                      isNaN(number)
                                    ) {
                                      return;
                                    }
                                    this.onchangeItemQty(number, bin.code);
                                  }}
                                />
                              </div>
                            </div>
                            {receivedLocationDetails.selectedQuantities.map(
                              (quantityDetails, indexKey) => (
                                <div key={indexKey} className="value-row">
                                  <div className="value-col">
                                    {/* <label>{bin.bin}</label> */}
                                    <Input
                                      disabled={true}
                                      value={quantityDetails.selectedQuantity}
                                      onChange={(event) => {
                                        if (
                                          Number(event.target.value) >
                                            Number(bin.available_stock_qty) ||
                                          isNaN(Number(event.target.value))
                                        ) {
                                          return;
                                        }
                                        this.props.addReceviedShippingDetails(
                                          bin,
                                          event.target.value,
                                          this.props.shipmentLine
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </Col>
                          <Col className="colums" xs="3">
                            <div className="date-col">
                              {/* <label>Expiry Date </label> */}
                              <DatePicker
                                disabledDate={(current) => {
                                  // Can not select days before today and today
                                  return (
                                    // current &&
                                    current < dayjs().endOf(-1, "day")
                                  );
                                }}
                                onChange={(momentDate) => {
                                  let expiryDateString = "";

                                  if (momentDate === null) {
                                    expiryDateString = "";
                                  } else {
                                    expiryDateString = momentDate.format(
                                      dateFormats.YYYYMMDD_SLASH
                                    );
                                  }

                                  this.onchangeItemExpiry(
                                    expiryDateString,
                                    bin.code
                                  );
                                }}
                                value={
                                  isObjectNotEmpty(
                                    this.state.binTextDetails[bin.code]
                                  ) &&
                                  isObjectNotEmpty(
                                    this.state.binTextDetails[bin.code]
                                      .expiryDate
                                  )
                                    ? moment(
                                        this.state.binTextDetails[bin.code]
                                          .expiryDate,
                                        dateFormats.YYYYMMDD_SLASH
                                      )
                                    : undefined
                                }
                                suffixIcon={
                                  <SvgIcon
                                    name="calendar-icon"
                                    viewbox="0 0 21.607 22.107"
                                  />
                                }
                                // format={dateFormats.MM_DD_YYYY}
                                allowClear
                              />
                            </div>

                            {receivedLocationDetails.selectedQuantities.map(
                              (rowDetails) => (
                                <div className="date-col">
                                  <DatePicker
                                    disabled={true}
                                    format={dateFormats.MM_DD_YYYY}
                                    allowClear
                                    suffixIcon={
                                      <SvgIcon
                                        name="calendar-icon"
                                        viewbox="0 0 21.607 22.107"
                                      />
                                    }
                                    value={
                                      isObjectEmpty(rowDetails.expiryDate)
                                        ? undefined
                                        : moment(
                                            rowDetails.expiryDate,
                                            dateFormats.YYYYMMDD_SLASH
                                          )
                                    }
                                  />
                                </div>
                              )
                            )}

                            {/* <label> </label> */}

                            {/* {selectedDestinationLocations.map((rowDetails) => (
                            <div className="date-col">
                              <DatePicker
                                disabled={true}
                                format={dateFormats.MM_DD_YYYY}
                                allowClear
                                suffixIcon={
                                  <SvgIcon
                                    name="calendar-icon"
                                    viewbox="0 0 21.607 22.107"
                                  />
                                }
                                value={
                                  isObjectEmpty(rowDetails.expiryDate)
                                    ? undefined
                                    : moment(
                                        rowDetails.expiryDate,
                                        dateFormats.YYYYMMDD_SLASH
                                      )
                                }
                              />
                            </div>
                          ))} */}
                          </Col>

                          <Col
                            className="colums d-flex justify-content-center"
                            xs="2"
                          >
                            <div>
                              <Button
                                className="plus-btn d-flex  justify-content-center"
                                onClick={() => {
                                  if (
                                    this.state.binTextDetails[bin.code]
                                      .selectedQuantity !== 0
                                  ) {
                                    let expiryDate = isObjectEmpty(
                                      this.state.binTextDetails[bin.code]
                                        .expiryDate
                                    )
                                      ? ""
                                      : this.state.binTextDetails[bin.code]
                                          .expiryDate;

                                    this.props.addReceviedShippingDetails(
                                      bin,
                                      Number(
                                        this.state.binTextDetails[bin.code]
                                          .selectedQuantity
                                      ),
                                      this.props.shipmentLine,
                                      false,
                                      expiryDate
                                    );

                                    this.resetBinEditableDetails(bin.code);
                                  }
                                }}
                              >
                                <SvgIcon name="plus" viewbox="0 0 13 12" />
                              </Button>
                              {receivedLocationDetails.selectedQuantities.map(
                                (_, binRowIndex) => (
                                  <Button
                                    className=" delete-btn d-flex justify-content-center"
                                    onClick={() => {
                                      this.props.removeReceivedBinRow(
                                        binRowIndex,
                                        this.props.shipmentLine,
                                        bin
                                      );
                                    }}
                                  >
                                    <SvgIcon
                                      name="trash"
                                      viewbox="0 0 31.5 36"
                                    />
                                  </Button>
                                )
                              )}
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                })}
              </div>
            </div>
            <div className="floorplan-footer">
              <div className="footer-top  py-2 d-flex">
                <Form.Item label="UnAssigned Qty" className="w-50 mb-0 mr-2">
                  <Input
                    value={this.props.totalReceiveQuantity - totaAssignedQty}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Assigned Qty"
                  className="w-50 mb-0 justify-content-end d-flex"
                >
                  <Input value={totaAssignedQty} disabled />{" "}
                </Form.Item>
              </div>
              <Button type="primary" block onClick={this.handleCancel}>
                confirm
              </Button>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default FloorPlansBox;
