import React from "react";
import { Navigate } from "react-router-dom";

// Layout Types

// Route Views
import LoginView from "./containers/Login";
import {
  componentPaths,
  componentPathsPrefix,
} from "./constants/component-paths";
import Receiving from "./containers/Receiving";
import ReceivingDetailView from "./containers/ReceivingDetail";

export const publicRoutes = [
  {
    path: componentPathsPrefix + "/",
    exact: true,
    component: () => <Navigate to={componentPaths.LOGIN} />,
  },
  {
    path: componentPaths.LOGIN,
    exact: true,
    component: LoginView,
  },
];

export const privateRoutes = [
  {
    path: componentPaths.RECEIVING,
    component: Receiving,
  },
  {
    path: componentPaths.RECEIVING_DETAIL,
    component: ReceivingDetailView,
  },
];
