import { message } from "antd";
import confirm from "antd/lib/modal/confirm";

export const showAlert = {
  success: (string, duration = 2) => {
    message.success(string, duration);
  },
  error: (string, duration = 2) => {
    message.error(string, duration);
  },
  confirm: (string, title = "Success") => {
    confirm({
      title: title,
      content: string,
      cancelButtonProps: { hidden: true },
    });
  },
};
