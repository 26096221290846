import React from "react";
import SvgSprite from "./utils/SvgSpriteLoader";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.less";

import svgFile from "./assets/images/svg/svg-sprite.svg";

import { privateRoutes, publicRoutes } from "./routes";
import { componentPaths } from "./constants/component-paths";
import { isUserLoggedIn } from "./common/methods";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const PrivateRoute = ({ children }) => {
    let isAuthenticated = isUserLoggedIn();
    return isAuthenticated ? children : <Navigate to={componentPaths.LOGIN} />;
  };
  return (
    <>
      <SvgSprite url={svgFile} />
      <Routes>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={
                <route.component navigate={navigate} location={location} />
              }
            />
          );
        })}
        {privateRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={
                <PrivateRoute>
                  <route.component navigate={navigate} location={location} />
                </PrivateRoute>

                // <PrivateRoute route={route}>
                //   <route.component navigate={navigate} location={location} />
                // </PrivateRoute>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to={componentPaths.LOGIN} />} />
      </Routes>
    </>
  );
};

export default App;
