import { componentPaths } from "./component-paths";
import { requestMethodType } from "./request";

export const serverApis = {
  login: {
    actionName: "login",
    serverUrl: `/setup/main/main_user_login`,
    componentPath: componentPaths.LOGIN,
    apiMethodType: requestMethodType.POST,
  },
  logout: {
    actionName: "logout",
    serverUrl: `/setup/session/destroy`,
    apiMethodType: requestMethodType.POST,
  },
  getVendorList: {
    actionName: "getVendorList",
    serverUrl: `/setup/setup/fetch_info_for_action`,
    apiMethodType: requestMethodType.POST,
  },
  getReceiveTypeList: {
    actionName: "getReceiveTypeList",
    serverUrl: `/setup/type/list_type_subtype_values`,
    apiMethodType: requestMethodType.POST,
  },
  getShipmentNumberList: {
    actionName: "getShipmentNumberList",
    serverUrl: `/purchase/purchase_receive/list_pending_vendor_shipments_for_purchase_receiving`,
    apiMethodType: requestMethodType.POST,
  },
  getShipmentLineList: {
    actionName: "getShipmentLines",
    serverUrl: `/purchase/purchase_receive/list_pending_shipment_po_lines`,
    apiMethodType: requestMethodType.POST,
  },
  getOpenPurchaseLineList: {
    actionName: "getOpenPurchaseLineList",
    serverUrl: `/purchase/vendor_shipment/list_open_purchase_orders_for_shipment_to_receive`,
    apiMethodType: requestMethodType.POST,
  },
  getBarCodeShipmentLineList: {
    actionName: "getBarCodeShipmentLineList",
    serverUrl: `/purchase/purchase_receive/list_pending_po_lines_using_barcode`,
    apiMethodType: requestMethodType.POST,
  },
  getAttachmentTypeList: {
    actionName: "getAttachmentTypeList",
    serverUrl: `/setup/type/list_type_subtype_values`,
    apiMethodType: requestMethodType.POST,
  },
  getFloorPlan: {
    actionName: "getFloorPlan",
    serverUrl: `/inventory/inventory/catalog_item_pallet_calculation`,
    apiMethodType: requestMethodType.POST,
  },
  uploadAttachment: {
    actionName: "uploadAttachment",
    serverUrl: `/purchase/purchase_receive/upload_purchase_receive_attachments`,
    apiMethodType: requestMethodType.POST,
  },
  submitPurchaseReceive: {
    actionName: "submitPurchaseReceive",
    serverUrl: `/purchase/purchase_receive/create_purchase_receives`,
    apiMethodType: requestMethodType.POST,
  },
  getAccountPeriodCode: {
    actionName: "getAccountPeriodCode",
    serverUrl: `/setup/setup/fetch_info_for_action`,
    apiMethodType: requestMethodType.POST,
  },
  getStockItemList: {
    actionName: "getStockItemList",
    serverUrl: `/setup/setup/fetch_info_for_action`,
    apiMethodType: requestMethodType.POST,
  },
  getStockItemInfo: {
    actionName: "getStockItemInfo",
    serverUrl: `/setup/setup/fetch_info_for_action`,
    apiMethodType: requestMethodType.POST,
  },
  getStockItemLocations: {
    actionName: "getStockItemLocations",
    serverUrl: `/inventory/inventory_report/stock_report`,
    apiMethodType: requestMethodType.POST,
  },
  submitLocationTransfer: {
    actionName: "submitLocationTransfer",
    serverUrl: `/inventory/inventory/create_inventory_transaction_transfers`,
    apiMethodType: requestMethodType.POST,
  },
  getWarehouseList: {
    actionName: "getWarehouseList",
    serverUrl: `/inventory/warehouse/list_warehouses`,
    apiMethodType: requestMethodType.POST,
  },
  productionPickInboxList: {
    actionName: "productionPickInboxList",
    serverUrl: `/production/production_direct/production_pick_inbox`,
    apiMethodType: requestMethodType.POST,
  },
  productionPickInboxScanItem: {
    actionName: "productionPickInboxScan",
    serverUrl: `/production/production_direct/production_pick_inbox`,
    apiMethodType: requestMethodType.POST,
  },
  productionUnPickInboxList: {
    actionName: "productionUnPickInboxList",
    serverUrl: `/production/production_direct/production_unpick_inbox`,
    apiMethodType: requestMethodType.POST,
  },
  productionUnPickInboxScanItem: {
    actionName: "productionUnPickInboxScanItem",
    serverUrl: `/production/production_direct/production_unpick_inbox`,
    apiMethodType: requestMethodType.POST,
  },
  getAvailableStockQty: {
    actionName: "getAvailableStockQty",
    serverUrl: `/inventory/inventory/get_on_stock_qty_by_trans_no`,
    apiMethodType: requestMethodType.POST,
  },
  getMultipleItemAvailableStockQty: {
    actionName: "getMultipleItemAvailableStockQty",
    serverUrl: `/production/production_direct/fetch_order_store_location`,
    apiMethodType: requestMethodType.POST,
  },
  saveProductionPickInbox: {
    actionName: "saveProductionPickInbox",
    serverUrl: `/production/production_direct/production_pick_save`,
    apiMethodType: requestMethodType.POST,
  },
  saveProductionUnPickInbox: {
    actionName: "saveProductionUnPickInbox",
    serverUrl: `/production/production_direct/production_unpick_save`,
    apiMethodType: requestMethodType.POST,
  },
  getAllBinLocationsList: {
    actionName: "getAllBinLocationsList",
    serverUrl: `/setup/setup/fetch_info_for_action`,
    apiMethodType: requestMethodType.POST,
  },
  getAllItemsInBin: {
    actionName: "getAllItemsInBin",
    serverUrl: `/inventory/inventory_report/stock_report`,
    apiMethodType: requestMethodType.POST,
  },
  getBinLocationByBarcode: {
    actionName: "getBinLocationByBarcode",
    serverUrl: `/inventory/warehouse/fetch_warehouse_location`,
    apiMethodType: requestMethodType.POST,
  },
  getShippmentAttachmentList: {
    actionName: "getShippmentAttachmentList",
    serverUrl: `/setup/attachment/list_attachments`,
    apiMethodType: requestMethodType.POST,
  },
};
