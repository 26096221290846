export const resetActions = {
  RESET_ALL_STATES: "RESETALLSTATES",
  ERROR_MESSAGES_RESET_ALL: "ERRORMESSAGESRESETALL",
};

export const requestCreatorKey = {
  REQUEST_ACTION_TYPE: "actionType",
  REQUEST_URL: "requestURL",
  REQUEST_HEADER: "requestHeader",
  REQUEST_PAYLOAD: "requestPayload",
  REQUEST_METHOD: "requestMethod",
  SHOW_ERROR_FROM_TRANSACTION: "showErrorFromTransaction",
};

export const requestContentType = {
  APPLICATION_JSON: "application/json",
};

export const requestMethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const requestStatus = {
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};
