//////////  COMMON REDUCERS   /////////////

import { requestStatus, resetActions } from "../../constants/request";

export const errorReducer = (state = {}, action) => {
  const { type, json } = action;
  if (type === resetActions.ERROR_MESSAGES_RESET_ALL) {
    return {};
  }

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName.split("_")[1]]:
      requestState === requestStatus.FAILURE ? json : "",
  };
};

export const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName.split("_")[1]]: requestState === requestStatus.REQUEST,
  };
};

//////////  COMMON REDUCERS   /////////////
