import React, { Component } from "react";
import { Button, Modal, Divider } from "antd";
import "./index.less";
import { SvgIcon } from "../../../../components/common";
import {
  dataFormatterMMDDYYYY,
  dataFormatterMMDDYYYY2,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../../common/utils";

class TransferredSuccessfully extends Component {
  handleCancel = () => {
    if (this.props.closeConfirmPopup) {
      this.props.closeConfirmPopup();
    }
  };

  render() {
    return (
      <Modal
        title="Transferred Successfully"
        className="transferred-successfully-model"
        centered
        open={true}
        onOk={this.handleCancel}
        onCancel={this.handleCancel}
        footer={null}
        closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
        maskClosable={false}
      >
        <div className="success-wrapper">
          <div className="success-upper">
            {Object.keys(this.props.itemsTransferDetails).map((itemCode) => (
              <div key={itemCode} className="card">
                <div className="card-header">
                  <div className="header-col">
                    <label>Item #</label>
                    <p>{itemCode}</p>
                  </div>
                  <div className="header-col">
                    <label>Item Name</label>
                    <p>
                      {
                        this.props.itemsTransferDetails[itemCode]
                          .selectedStockItem.name
                      }
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <div className="locations-list">
                    <div className="locations-row">
                      <div className="colum1">
                        <h4 className="items-title">Aisle Locations (From)</h4>{" "}
                      </div>
                      <div className="colum2">
                        <h4 className="items-title">Total Quantity</h4>
                      </div>
                      <div xs="3" className="colum3">
                        <h4 className="items-title">Transferred </h4>
                      </div>
                    </div>
                    <div className="location-list-scroll">
                      {this.props.itemsTransferDetails[
                        itemCode
                      ].selectedSourceLocations.map((sourceLocation) => (
                        <div
                          key={sourceLocation.location_code}
                          className="locations-row"
                        >
                          <div className="colum1">
                            <h5>{sourceLocation.location_code}</h5>
                            {isObjectNotEmpty(sourceLocation.expiry_date) && (
                              <p>
                                Expiring on{" "}
                                {dataFormatterMMDDYYYY(
                                  sourceLocation.expiry_date
                                )}
                              </p>
                            )}
                          </div>
                          <div className="colum2">
                            <h5>{Number(sourceLocation.on_hand_qty)}</h5>
                          </div>
                          <div xs="3" className="colum3">
                            <h5>{sourceLocation.selectedQuantity}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Divider>
                    <div className="divider-arrow">
                      <SvgIcon name="arrow-down2" viewbox="0 0 9 15" />
                    </div>
                  </Divider>
                  <div className="locations-list">
                    <div className="locations-row">
                      <div className="colum1">
                        <h4 className="items-title">Aisle Locations (To)</h4>{" "}
                      </div>
                      <div className="colum2">
                        <h4 className="items-title"> Quantity</h4>
                      </div>
                    </div>
                    <div className="location-list-scroll">
                      {Object.values(
                        this.props.itemsTransferDetails[itemCode]
                          .selectedDestinationLocations
                      ).map((destinationBin) =>
                        isObjectEmpty(destinationBin)
                          ? ""
                          : destinationBin.map((destinationLocation) => (
                              <div
                                key={destinationLocation.bin.code}
                                className="locations-row"
                              >
                                <div className="colum1">
                                  <h5>{destinationLocation.bin.code}</h5>
                                  {isObjectNotEmpty(
                                    destinationLocation.expiryDate
                                  ) && (
                                    <p>
                                      Expiring on{" "}
                                      {dataFormatterMMDDYYYY2(
                                        destinationLocation.expiryDate
                                      )}
                                    </p>
                                  )}
                                </div>
                                <div className="colum2">
                                  <h5>
                                    {Number(
                                      destinationLocation.selectedQuantity
                                    )}
                                  </h5>
                                </div>
                                {/* <div xs="3" className="colum3">
                          <Input value={destinationLocation.selectedQuantity} />
                        </div> */}
                              </div>
                            ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="success-footer">
            <Button type="primary" block onClick={this.handleCancel}>
              TRANSFER another item
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TransferredSuccessfully;
