import React, { Component } from "react";
import {
  Button,
  Modal,
  Input,
  Checkbox,
  Form,
  Slider,
  Spin,
  AutoComplete,
  Select,
} from "antd";
import { SvgIcon, Row, Col } from "../../../components/common";
import FloorPlansBox from "../FloorPlansBox";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  getFloorPlanAction,
  getWarehouseListAction,
  resetStoreState,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../../common/utils";
import { getFormatedFloorPlan, isFlagTrueY } from "../../../common/methods";
import "./index.less";

const marks = {
  1: ".35",
  25: ".50",
  35: ".75",
  50: "1",
  65: "1.5",
  75: "2",
  100: "3",
};

class FloorPlaneView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      inputValue: 1,
      warehouse: "",
      selectedRack: {},
      floorPlan: [],
    };
  }

  showModal = () => {
    this.props.resetSelectedLocations();

    this.setState({ isModalOpen: true }, () => {
      if (isObjectEmpty(this.props.warehouseList)) {
        this.props.getWarehouseListAction();
      }
    });
  };

  getFloorPlan = (warehouse = this.state.warehouse) => {
    const requestObject = {
      catalog_item_code: this.props.shipmentLine.catalog_item_code,
      warehouse_code: warehouse,
    };
    this.props.getFloorPlanAction(requestObject);
  };

  handleCancel = () => {
    this.props.resetStoreState(serverApis.getFloorPlan.actionName);
    this.setState({ isModalOpen: false, warehouse: "", selectedRack: {} });
  };

  onCloseFloorPlansBox = () => {
    this.setState({ selectedRack: {} });
  };

  onChange = (newValue) => {
    if (isNaN(newValue)) {
      return;
    }

    this.setState({ inputValue: newValue });
  };

  getFloorPlanBoxName = (containerDetails) => {
    const alreadySelected = containerDetails.bins.some((bin) =>
      this.props.receivedShippingLines.some(
        (receivedLine) => receivedLine.bin.code === bin.code
      )
    );

    if (!isFlagTrueY(containerDetails.valid_section_aisle)) {
      return "red-box";
    } else if (alreadySelected) {
      return "blue-box-checked";
    } else if (isFlagTrueY(containerDetails.item_already_placed)) {
      return "green-box";
    } else {
      return "";
    }
  };

  render() {
    let floorPlan = [];

    let floorPlanRacks = [];

    if (this.state.isModalOpen) {
      floorPlan = getFormatedFloorPlan(
        this.props.floorPlan,
        this.props.shipmentLine.catalog_item_code
      );
      floorPlan.forEach((row) => {
        if (isObjectNotEmpty(row.floor_plan_rows)) {
          row.floor_plan_rows.forEach((rack) => {
            floorPlanRacks.push({
              ...rack,
              rackLocationTitle: `${rack.aisle}-${rack.section}`,
            });
          });
        }
      });
    }

    return (
      <>
        <Button
          className="check-floor-map-btn"
          onClick={() => this.showModal()}
          disabled={this.props.totalReceiveQuantity === 0}
        >
          Select Aisle Location
        </Button>
        {this.state.isModalOpen && (
          <Modal
            title="Floor Plan"
            className="floor-model"
            centered
            open={this.state.isModalOpen}
            onOk={this.handleCancel}
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
            maskClosable={false}
          >
            <Spin spinning={this.props.isFetchingData} size="large">
              <div className="floor-wrapper">
                <div className="floor-head-wrapper">
                  <Row>
                    <Col>
                      <AutoComplete
                        options={this.props.warehouseList.map((warehouse) => {
                          return {
                            value: warehouse.code,
                          };
                        })}
                        placeholder="Select warehouse"
                        onSelect={(value) => {
                          this.setState({ warehouse: value });
                          this.getFloorPlan(value);
                        }}
                        value={this.state.warehouse}
                      />
                    </Col>
                    <Col>
                      <Select
                        placeholder="Select Rack"
                        className="bin-select"
                        onSelect={(item, selectedOption) => {
                          this.setState({
                            selectedRack: selectedOption.rack,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.selectedRack.rackLocationTitle}
                        showSearch
                        disabled={isObjectEmpty(this.state.warehouse)}
                      >
                        {floorPlanRacks.map((rack) => (
                          <Select.Option
                            title={rack.rackLocationTitle}
                            key={rack.rackLocationTitle}
                            value={rack.rackLocationTitle}
                            rack={rack}
                          >
                            {rack.rackLocationTitle}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center mt-2 pt-1">
                    <Checkbox
                      className="floorplan-list checkbox-cus ml-0"
                      checked={true}
                    >
                      Contains {this.props.shipmentLine.catalog_item_code} Block
                    </Checkbox>
                    <Checkbox
                      className="floor-checkbox checkbox-cus ml-0"
                      checked={false}
                    >
                      N Pallets' Space Available
                    </Checkbox>
                    <Checkbox
                      className="floor-checkbox checkbox-cus checkbox-red ml-0"
                      checked={false}
                    >
                      Not Available
                    </Checkbox>
                  </div>
                </div>
                <div className="floorplan-list-wrapper">
                  <div
                    style={{
                      zoom: this.state.inputValue,
                    }}
                  >
                    {isObjectNotEmpty(floorPlan) &&
                      floorPlan.map((columns, columnIndex) => (
                        <div className="floorplan-list" key={columnIndex}>
                          {columns.floor_plan_rows.map(
                            (containerDetails, rowIndex) => (
                              <FloorPlansBox
                                key={rowIndex + "" + columnIndex}
                                className={this.getFloorPlanBoxName(
                                  containerDetails
                                )}
                                otherReceiveLines={this.props.otherReceiveLines}
                                totalReceiveQuantity={
                                  this.props.totalReceiveQuantity
                                }
                                counts={`${containerDetails.pallet_available}`}
                                containerDetails={containerDetails}
                                addReceviedShippingDetails={
                                  this.props.addReceviedShippingDetails
                                }
                                removeReceviedShippingDetails={
                                  this.props.removeReceviedShippingDetails
                                }
                                shipmentLine={this.props.shipmentLine}
                                receivedShippingLines={
                                  this.props.receivedShippingLines
                                }
                                onCloseFloorPlansBox={this.onCloseFloorPlansBox}
                                selectedRack={this.state.selectedRack}
                                removeReceivedBinRow={
                                  this.props.removeReceivedBinRow
                                }
                              />
                            )
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="floorplan-footer">
                <div className="slider-with-sign">
                  <div className="icons">-</div>
                  <Slider
                    tooltipVisible={false}
                    marks={marks}
                    step={0.25}
                    min={0.25}
                    max={3}
                    defaultValue={50}
                    onChange={this.onChange}
                    value={this.state.inputValue}
                  />
                  <div className="icons">+</div>
                </div>
                <div className="footer-top  py-2 d-flex">
                  <Form.Item
                    label="Pallets Assigned "
                    className="w-50 mb-0 mr-2"
                  >
                    <Input value={this.props.palletAssigned} disabled />
                  </Form.Item>
                  <Form.Item
                    label="Pallets Required "
                    className="w-50 mb-0 justify-content-end d-flex"
                  >
                    <Input value={this.props.palletRequired} disabled />
                  </Form.Item>
                </div>
                <Button type="primary" block onClick={this.handleCancel}>
                  Aisle Assignment Complete
                </Button>
              </div>
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getFloorPlan.actionName,
]);

const mapStateToProps = (state) => ({
  floorPlan: state.floorPlan,
  warehouseList: state.warehouseList,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getFloorPlanAction,
  getWarehouseListAction,
  resetStoreState,
})(FloorPlaneView);
