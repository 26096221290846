import React, { Component } from "react";
import { Button, Modal, Select, Form, Input, Spin } from "antd";
import { SvgIcon } from "../../../components/common";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  getAttachmentTypeListAction,
  uploadAttachmentAction,
  resetStoreState,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { isObjectNotEmpty } from "../../../common/utils";
import { showAlert } from "../../../common/alert-messages";
import { loggedInUserDetail } from "../../../common/methods";
import "./index.less";

const { TextArea } = Input;

class AddAttachmentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      selectedAttachmentType: {},
      imageUploadRequestObject: {},
      comment: "",
    };
  }

  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
      selectedAttachmentType: {},
      imageUploadRequestObject: {},
      comment: "",
    });
  };

  addImage = (event) => {
    const imageUploadRequestObject = {
      Filename: event.target.files[0].name,
      Filedata: event.target.files[0],
      request_type: "json",
    };

    this.setState({ imageUploadRequestObject: imageUploadRequestObject });
  };

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.uploadAttachmentSuccessMessage)) {
      const userDetails = loggedInUserDetail();
      const uploadedImageObject = {
        attachment_type: this.state.selectedAttachmentType.value,
        attachment_file_name: this.state.imageUploadRequestObject.Filename,
        comment: this.state.comment,
        trans_flag: "A",
        attachment_file_path: "",
        serial_no: 101,
        company_id: userDetails.company_id,
        updated_by: userDetails.id,
        created_by: userDetails.id,
        id: "",
        lock_version: 0,
        updated_by_code: userDetails.user_cd,
        is_updated: "Y",
      };

      this.props.addUploadedImage(uploadedImageObject);
      showAlert.success(this.props.uploadAttachmentSuccessMessage);
      this.props.resetStoreState(serverApis.uploadAttachment.actionName);
      this.handleCancel();
    }
  }

  componentDidMount() {
    this.props.getAttachmentTypeListAction();
  }

  render() {
    return (
      <>
        <div className="attached-btn-row mr-3" onClick={this.showModal}>
          <Button className="attached-btn">
            <SvgIcon name="file-attached" viewbox="0 0 18 18" />
            {this.props.uploadedImages.length > 0 && (
              <span className="notification">
                {this.props.uploadedImages.length}
              </span>
            )}
          </Button>
          <span className="add-text"> Add attachment </span>
        </div>
        <Modal
          title="Purchase Receive Attachment"
          className="add-attachment-modal"
          centered
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          footer={
            <Button type="primary" block htmlType="submit" form="my-form">
              {" "}
              Upload
            </Button>
          }
          destroyOnClose
          maskClosable={false}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="file-attach-wrapper">
              <Form
                id="my-form"
                layout="vertical"
                onFinish={() => {
                  this.props.uploadAttachmentAction(
                    this.state.imageUploadRequestObject
                  );
                }}
              >
                <Form.Item
                  label="Type"
                  className="mb-4"
                  name={"attachment_type"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Attachment Type!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Attachment Type"
                    suffixIcon={
                      <SvgIcon
                        name="select-arrow"
                        viewbox="0 0 19.124 12.357"
                      />
                    }
                    onSelect={(item, selectedOption) => {
                      this.setState({
                        selectedAttachmentType: selectedOption.attachmentType,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {this.props.attachmentTypeList.map((item) => (
                      <Select.Option
                        title={item.description}
                        key={item.description}
                        value={item.description}
                        attachmentType={item}
                      >
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"file"}
                  label="File"
                  className="fileuplaod-wrapper mb-4"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Attachment File!",
                    },
                  ]}
                  valuePropName="files"
                >
                  <Input
                    type="file"
                    onChange={this.addImage}
                    suffix={
                      <>
                        <SvgIcon name="file" viewbox="0 0 17 20" />
                        <span className="browse-text">Browse</span>
                      </>
                    }
                    prefix={
                      <>
                        {isObjectNotEmpty(
                          this.state.imageUploadRequestObject
                        ) && (
                          <div className="file-name">
                            {this.state.imageUploadRequestObject.Filename}
                          </div>
                        )}
                      </>
                    }
                  />
                </Form.Item>

                <Form.Item label="Comments" name={"comment"}>
                  <TextArea
                    rows="5"
                    onChange={(event) => {
                      this.setState({ comment: event.target.value });
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getAttachmentTypeList.actionName,
  serverApis.uploadAttachment.actionName,
]);

const mapStateToProps = (state) => ({
  attachmentTypeList: state.attachmentTypeList,
  uploadAttachmentSuccessMessage: state.uploadAttachmentSuccessMessage,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getAttachmentTypeListAction,
  uploadAttachmentAction,
  resetStoreState,
})(AddAttachmentModal);
