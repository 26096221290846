import React, { Component } from "react";
import { Button, Modal, Spin } from "antd";
import { SvgIcon, Row, Col } from "../../../../components/common";
import UnPickedConfirmationModal from "../UnPickedConfirmationModal";
import { createLoadingSelector } from "../../../../redux/actions";
import { serverApis } from "../../../../constants/server-apis";
import { connect } from "react-redux";
import { isObjectEmpty } from "../../../../common/utils";
import "./index.less";
class PickedSuccessModal extends Component {
  componentDidUpdate() {
    if (this.props.isModalOpen && isObjectEmpty(this.props.selectedOrders)) {
      this.props.onCloseSuccessPopup();
    }
  }

  render() {
    return (
      <Modal
        title="Picked Successfully"
        className="startpicking-modal startpicking-success"
        centered
        open={this.props.isModalOpen}
        onCancel={this.props.onCloseSuccessPopup}
        closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
        footer={
          <Button
            type="primary"
            block
            form="my-form"
            onClick={this.props.onCloseSuccessPopup}
          >
            Continue Picking
          </Button>
        }
        destroyOnClose
        maskClosable={false}
      >
        <Spin spinning={this.props.isFetchingData} size="large">
          {this.props.isModalOpen &&
            this.props.selectedOrders.map((order) => (
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col xs="3" className="colum">
                      <label>JOB #</label>
                      <p>{order.trans_no}</p>
                    </Col>
                    <Col xs="4" className="colum">
                      <label>Order Qty</label>
                      <p>{order.line_item_qty}</p>
                    </Col>
                    <Col xs="3" className="colum">
                      <label>Picked Qty</label>
                      <p>{order.picked_qty}</p>
                    </Col>
                    {/* <Col xs="3" className="colum">
                      <label>Location</label>
                      <p>{order.selectedLocation.location_code}</p>
                    </Col> */}
                    <Col xs="2" className="colum text-center">
                      <UnPickedConfirmationModal
                        showReloadButton={true}
                        selectedOrder={order}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
        </Spin>
      </Modal>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.saveProductionUnPickInbox.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps)(PickedSuccessModal);
