import React, { Component } from "react";
import { Button, Modal, Spin } from "antd";
import { SvgIcon } from "../../../components/common";
import { serverApis } from "../../../constants/server-apis";
import {
  createLoadingSelector,
  getShippmentAttachmentListAction,
  resetStoreState,
} from "../../../redux/actions";
import { connect } from "react-redux";
import "./index.less";
import { baseServerAdress } from "../../../constants/server-address";
import { downloadFile, isLiveMode } from "../../../common/methods";

class ViewAttachmentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
    this.props.getShippmentAttachmentListAction(this.props.selectedShipment.id);
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.resetStoreState(
      serverApis.getShippmentAttachmentList.actionName
    );
  };

  render() {
    return (
      <>
        <div className="attached-btn" onClick={this.showModal}>
          <Button className="attached-btn">
            <SvgIcon name="file" viewbox="0 0 17 20" />
          </Button>
          <span className="add-text"> View attachment </span>
        </div>
        <Modal
          title="View Shipment Attachment"
          className="add-attachment-modal"
          centered
          open={this.state.isModalOpen}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          footer={
            <Button type="primary" block onClick={this.handleCancel}>
              close
            </Button>
          }
          destroyOnClose
          maskClosable={false}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="file-attach-wrapper">
              {this.props.shippmentAttachmentList.map((attachment) => (
                <div className="file-attach-inner">
                  {attachment.file_name}
                  <Button
                    className="download-attachment-btn"
                    onClick={() => {
                      downloadFile(
                        baseServerAdress.baseURL +
                          `/attachments/${baseServerAdress.schemaName}/vendor_shipment/` +
                          attachment.file_name,
                        "attachment"
                      );
                    }}
                  >
                    <SvgIcon name="download-icon" viewbox="0 0 100 94" />
                  </Button>
                </div>
              ))}
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getShippmentAttachmentList.actionName,
]);

const mapStateToProps = (state) => ({
  shippmentAttachmentList: state.shippmentAttachmentList,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getShippmentAttachmentListAction,
  resetStoreState,
})(ViewAttachmentModal);
