import React, { Component } from "react";
import { Button, Modal, Form, Collapse, Input, Checkbox, Spin } from "antd";
import { SvgIcon, Row, Col } from "../../../../components/common";
import {
  dataFormatterMMDDYYYY,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../../common/utils";
import {
  createLoadingSelector,
  getMultipleItemAvailableStockQtyAction,
  saveProductionPickInbox,
  resetStoreState,
} from "../../../../redux/actions";
import { serverApis } from "../../../../constants/server-apis";
import { connect } from "react-redux";
import { loggedInUserDetail } from "../../../../common/methods";

import "./index.less";
import ScanQuantityModal from "../../TransferLocationTab/ScanQuantityModal";

const { Panel } = Collapse;
class StartPickingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      activeCollapseKeys: ["0"],
    };
  }

  showModal = () => {
    this.setState(
      {
        isModalOpen: true,
      },
      () => {
        this.props.selectedOrders.forEach((order) => {
          this.props.getMultipleItemAvailableStockQtyAction(order);
        });
      }
    );
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.resetStoreState(
      serverApis.getMultipleItemAvailableStockQty.actionName
    );
  };

  confirmDelete = (order) => {
    Modal.confirm({
      title: "Do you want to delete these items?",
      icon: <SvgIcon name="info" viewbox="0 0 33 33" />,
      okText: "Yes",
      cancelText: "Cancel",
      className: "delete-items-modal",
      okType: "danger",
      onOk: () => this.props.deleteSelectedOrder(order),
    });
  };

  getAvailableStockQty = (transNo) => {
    if (
      isObjectNotEmpty(this.props.availableStockQtyObject) &&
      isObjectNotEmpty(this.props.availableStockQtyObject[transNo])
    ) {
      return this.props.availableStockQtyObject[transNo].availableItemlocations;
    } else {
      return [];
    }
  };

  getCatalogItemCodes = (transNo) => {
    if (
      isObjectNotEmpty(this.props.availableStockQtyObject) &&
      isObjectNotEmpty(this.props.availableStockQtyObject[transNo])
    ) {
      return this.props.availableStockQtyObject[transNo].catalogItemCodes;
    } else {
      return [];
    }
  };

  getLocationsForCatalogItemCode = (code, transNo) => {
    let locations = this.getAvailableStockQty(transNo).filter(
      (location) => code === location.catalog_item_code
    );
    return locations;
  };

  componentDidUpdate() {
    if (isObjectEmpty(this.props.selectedOrders) && this.state.isModalOpen) {
      this.handleCancel();
    }

    if (
      isObjectNotEmpty(this.props.saveProductionPickInboxSuccessMessage) &&
      this.state.isModalOpen
    ) {
      this.handleCancel();
    }
  }

  isFinishPickingEnable = () => {
    let arryItemQtyGreater = [];

    this.props.selectedOrders.forEach((selectedOrder) => {
      let totalSelectedOrderItemQty = 0;
      selectedOrder.selectedLocations.forEach((selectedLocation) => {
        totalSelectedOrderItemQty =
          totalSelectedOrderItemQty + Number(selectedLocation.pickedQty);
      });

      arryItemQtyGreater.push(
        totalSelectedOrderItemQty >= Number(selectedOrder.line_item_qty) * 0.9
      );
    });

    return arryItemQtyGreater.every((value) => value);
  };

  getPickedQtyForVariant = (order, catalogItemCode) => {
    let pickedQty = 0;

    order.selectedLocations.forEach((location) => {
      if (location.catalog_item_code === catalogItemCode) {
        pickedQty = pickedQty + Number(location.pickedQty);
      }
    });

    return pickedQty;
  };

  render() {
    const deleteItem = (order) => (
      <SvgIcon
        name="trash"
        viewbox="0 0 31.5 36"
        onClick={(event, e) => {
          event.stopPropagation();
          this.confirmDelete(order);
        }}
      />
    );
    return (
      <>
        <Button
          disabled={isObjectEmpty(this.props.selectedOrders)}
          className="primary-btn bottom-btn"
          //htmlType="submit"
          block
          onClick={() => this.showModal()}
        >
          Start picking{" "}
          {isObjectNotEmpty(this.props.selectedOrders)
            ? `(${this.props.selectedOrders.length})`
            : ""}
        </Button>
        <Modal
          title="START PICKING"
          className="startpicking-modal"
          centered
          open={this.state.isModalOpen}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 13.426 13.423" />}
          // footer={<PickedSuccessModal />}
          footer={
            <Button
              className="primary-btn bottom-btn"
              block
              disabled={!this.isFinishPickingEnable()}
              onClick={() => {
                let pickedOrders = [];
                const userDetails = loggedInUserDetail();

                this.props.selectedOrders.forEach((order) => {
                  let picked_qty = 0;

                  let salesOrderLocations = order.selectedLocations.map(
                    (selectedLocation) => {
                      picked_qty =
                        picked_qty + Number(selectedLocation.pickedQty);
                      return {
                        ...selectedLocation,
                        sales_order_id: order.sales_order_id,
                        item_qty: selectedLocation.pickedQty,
                        updated_by_code: userDetails.updated_by_code,
                        id:
                          Number(selectedLocation.id) === 0
                            ? null
                            : selectedLocation.id,
                      };
                    }
                  );

                  this.props.availableStockQtyObject[
                    order.trans_no
                  ].availableItemlocations.forEach((location) => {
                    if (
                      Number(location.id) > 0 &&
                      salesOrderLocations.every(
                        (selectedLocation) =>
                          selectedLocation.id !== location.id
                      )
                    ) {
                      salesOrderLocations.push({
                        ...location,
                        sales_order_id: order.sales_order_id,
                        item_qty: 0,
                        updated_by_code: userDetails.updated_by_code,
                      });
                    }
                  });

                  pickedOrders.push({
                    ...order,
                    sales_order_locations: salesOrderLocations,
                    picked_qty: picked_qty,
                  });
                  order.picked_qty = picked_qty;
                });

                this.props.saveProductionPickInbox(pickedOrders);
              }}
            >
              Finish picking
            </Button>
            // <PickedUpSuccessfullyModal />
          }
          destroyOnClose
          maskClosable={false}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <Form id="my-form" layout="vertical">
              <div>
                <Collapse
                  activeKey={this.state.activeCollapseKeys}
                  //accordion
                  onChange={(activeCollapseKeys) => {
                    // let activeCollapseKeys = this.state.activeCollapseKeys;
                    // if (activeCollapseKeys.includes(key)) {
                    //   activeCollapseKeys = activeCollapseKeys.filter(
                    //     (e) => e !== key
                    //   );
                    // } else {
                    //   activeCollapseKeys.push(key);
                    // }
                    this.setState({ activeCollapseKeys: activeCollapseKeys });
                  }}
                >
                  {this.props.selectedOrders.map((order, index) => {
                    let totalOrderItemQty = 0;
                    const selectedLocationIdentifierKeys = isObjectNotEmpty(
                      order.selectedLocations
                    )
                      ? order.selectedLocations.map((location) => {
                          totalOrderItemQty =
                            totalOrderItemQty + Number(location.pickedQty);
                          return (
                            location.location_id +
                            location.catalog_item_code +
                            location.selectedIndex
                          );
                        })
                      : [];

                    return (
                      <Panel
                        header={
                          <Row>
                            <Col>
                              <label>JOB #</label>
                              <p>{order.trans_no}</p>
                            </Col>
                            <Col>
                              <label>Order Qty</label>
                              <p>{order.line_item_qty}</p>
                            </Col>
                            <Col>
                              <label>Picked Qty</label>
                              <p>{totalOrderItemQty}</p>
                            </Col>
                          </Row>
                        }
                        key={index.toString()}
                        extra={deleteItem(order)}
                      >
                        <div className="card">
                          <div className="card-header">
                            <Row>
                              <Col>
                                <label>Imprint Type</label>
                                <p>{order.catalog_attribute_value_code}</p>
                              </Col>
                              <Col>
                                <label>Ship Date</label>
                                <p>{dataFormatterMMDDYYYY(order.ship_date)}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Default Location</label>
                                <p>{order.location_code}</p>
                              </Col>
                              <Col className="cus-col">
                                <label>Item #</label>
                                <p>{order.catalog_item_code}</p>
                              </Col>
                            </Row>
                            <Row>
                              {isObjectNotEmpty(
                                order.all_multi_description
                              ) && (
                                <Col xs="8" className="cus-col">
                                  <label>Multi Order Options</label>
                                  <p>{order.all_multi_description}</p>
                                </Col>
                              )}
                            </Row>
                          </div>
                          <div className="card-body">
                            <h4>Aisle Locations</h4>
                            <Collapse>
                              {this.getCatalogItemCodes(order.trans_no).map(
                                (catalogItemCode) => {
                                  return (
                                    <Panel
                                      header={
                                        catalogItemCode +
                                        " Picked Qty : " +
                                        this.getPickedQtyForVariant(
                                          order,
                                          catalogItemCode
                                        )
                                      }
                                      key={catalogItemCode}
                                    >
                                      <div className="px-2">
                                        <Checkbox.Group
                                          value={selectedLocationIdentifierKeys}
                                        >
                                          {this.getLocationsForCatalogItemCode(
                                            catalogItemCode,
                                            order.trans_no
                                          ).map(
                                            (
                                              stockLocation,
                                              stockLocationIndex
                                            ) => {
                                              const locationIdentifierkey =
                                                stockLocation.location_id +
                                                stockLocation.catalog_item_code +
                                                stockLocationIndex;

                                              let selectedLocation = order.selectedLocations.find(
                                                (location) =>
                                                  location.location_id ===
                                                    stockLocation.location_id &&
                                                  location.catalog_item_code ===
                                                    stockLocation.catalog_item_code &&
                                                  location.selectedIndex ===
                                                    stockLocationIndex
                                              );

                                              return (
                                                <Checkbox
                                                  key={locationIdentifierkey}
                                                  value={locationIdentifierkey}
                                                  stockLocation={stockLocation}
                                                  onChange={(e) => {
                                                    this.props.onSelectAvailableLocation(
                                                      e.target.checked,
                                                      e.target.stockLocation,
                                                      order,
                                                      catalogItemCode,
                                                      stockLocationIndex
                                                    );
                                                  }}
                                                >
                                                  <div className="al-list">
                                                    <div className="colum1">
                                                      <p>
                                                        {" "}
                                                        {
                                                          stockLocation.location_code
                                                        }
                                                      </p>
                                                      {isObjectNotEmpty(
                                                        stockLocation.expiry_date
                                                      ) && (
                                                        <label>
                                                          Expiring on{" "}
                                                          {dataFormatterMMDDYYYY(
                                                            stockLocation.expiry_date
                                                          )}
                                                        </label>
                                                      )}
                                                    </div>
                                                    <div className="colum2">
                                                      <p>Qty</p>
                                                      <label>
                                                        {
                                                          stockLocation.stock_qty
                                                        }
                                                      </label>
                                                    </div>
                                                    {/* <div className="colum3">
                                                    <Form.Item>
                                                      <Input />
                                                    </Form.Item>
                                                  </div> */}
                                                    <div className="colum3 startpik-col3">
                                                      <Form.Item>
                                                        <div className="startpik-col3-inner">
                                                          <ScanQuantityModal
                                                            disabled={isObjectEmpty(
                                                              selectedLocation
                                                            )}
                                                            itemCode={
                                                              stockLocation.catalog_item_code
                                                            }
                                                            itemId={
                                                              stockLocation.catalog_item_id
                                                            }
                                                            onAddQuantity={(
                                                              quantity
                                                            ) => {
                                                              this.props.onChangePickedQty(
                                                                quantity,
                                                                selectedLocation,
                                                                order,
                                                                stockLocationIndex
                                                              );
                                                            }}
                                                          />
                                                          <Input
                                                            disabled={isObjectEmpty(
                                                              selectedLocation
                                                            )}
                                                            value={
                                                              isObjectEmpty(
                                                                selectedLocation
                                                              )
                                                                ? ""
                                                                : selectedLocation.pickedQty
                                                            }
                                                            onChange={(event) => {
                                                              if (
                                                                Number(
                                                                  event.target
                                                                    .value
                                                                ) >
                                                                  Number(
                                                                    stockLocation.stock_qty
                                                                  ) ||
                                                                isNaN(
                                                                  Number(
                                                                    event.target
                                                                      .value
                                                                  )
                                                                )
                                                              ) {
                                                                return;
                                                              }

                                                              this.props.onChangePickedQty(
                                                                event.target
                                                                  .value,
                                                                selectedLocation,
                                                                order,
                                                                stockLocationIndex
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </Form.Item>
                                                    </div>{" "}
                                                  </div>
                                                </Checkbox>
                                              );
                                            }
                                          )}
                                        </Checkbox.Group>
                                      </div>
                                    </Panel>
                                  );
                                }
                              )}
                            </Collapse>
                          </div>
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getMultipleItemAvailableStockQty.actionName,
  serverApis.saveProductionPickInbox.actionName,
]);

const mapStateToProps = (state) => ({
  saveProductionPickInboxSuccessMessage:
    state.saveProductionPickInboxSuccessMessage,
  availableStockQtyObject: state.availableStockQtyObject,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  getMultipleItemAvailableStockQtyAction,
  saveProductionPickInbox,
  resetStoreState,
})(StartPickingModal);
