import { combineReducers } from "redux";
import { resetActions } from "../../constants/request";
import { accountPeriodCodeReducer } from "./account-period-code-reducer";
import { attachmentTypeListReducer } from "./attachment-type-list-reducer";
import { barCodeShipmentLineListReducer } from "./bar-code-shipment-lines-reducer";
import { loadingReducer, errorReducer } from "./common-reducers";
import { floorPlanReducer } from "./floor-plan-reducer";
import { loginReducer, logoutReducer } from "./login-logout-reducer";
import { availableStockQtyObjectReducer } from "./multiple-item-available-stock-qty-list-reducer";
import {
  productionPickInboxListReducer,
  productionPickInboxScanItemReducer,
} from "./production-pick-inbox-list-reducer";
import { productionUnPickInboxListReducer } from "./production-unpick-inbox-list-reducer";
import { receiveTypeListReducer } from "./receive-type-list-reducer";
import { saveProductionPickInboxReducer } from "./save-production-pick-inbox-list-reducer";
import { saveProductionUnPickInboxReducer } from "./save-production-unpick-inbox-list-reducer";
import { shipmentLineListReducer } from "./shipment-lines-reducer";
import { shipmentNumnberListReducer } from "./shipment-number-list-reducer";
import { stockItemInfoReducer } from "./stock-item-info-reducer";
import { stockItemListReducer } from "./stock-item-list-reducer";
import { stockItemLocationsReducer } from "./stock-item-locations-reducer";
import { submitLocationTransferReducer } from "./submit-location-transfer-reducer";
import { submitPurchaseReceiveReducer } from "./submit-purchase-receive-reducer";
import { uploadAttachmentReducer } from "./upload-attachment-reducer";
import { vendorListReducer } from "./vendor-list-reducer";
import { warehouseListReducer } from "./warehouse-list-reducer";
import { allBinLocationsListReducer } from "./all-bin-locations-list-reducer";
import { allItemsInBinReducer } from "./all-items-in-bin";
import { binLocationByBarcodeReducer } from "./bin-location-by-barcode-reducer";
import { shippmentAttachmentListReducer } from "./shipment-attachment-list-reducer";
import { vendorPalletBarcodeReducer } from "./vendor-pallet-barcode-reducer";
import { vendorInnerCartonsReducer } from "./vendor-inner-cartons-reducer";
import { vendorMasterCartonsReducer } from "./vendor-master-cartons-reducer";

// Combine all reducers.
const appReducer = combineReducers({
  loadingApis: loadingReducer,
  errorMessages: errorReducer,
  loginDetails: loginReducer,
  logoutSuccessMessage: logoutReducer,
  vendorList: vendorListReducer,
  receiveTypeList: receiveTypeListReducer,
  shipmentNumnberList: shipmentNumnberListReducer,
  shipmentLineList: shipmentLineListReducer,
  barCodeShipmentLineList: barCodeShipmentLineListReducer,
  attachmentTypeList: attachmentTypeListReducer,
  uploadAttachmentSuccessMessage: uploadAttachmentReducer,
  floorPlan: floorPlanReducer,
  submitPurchaseReceiveSuccessMessage: submitPurchaseReceiveReducer,
  accountPeriodCode: accountPeriodCodeReducer,
  stockItemList: stockItemListReducer,
  stockItemInfo: stockItemInfoReducer,
  stockItemLocations: stockItemLocationsReducer,
  submitLocationTransferSuccessMessage: submitLocationTransferReducer,
  warehouseList: warehouseListReducer,
  productionPickInboxList: productionPickInboxListReducer,
  availableStockQtyObject: availableStockQtyObjectReducer,
  saveProductionPickInboxSuccessMessage: saveProductionPickInboxReducer,
  productionUnPickInboxList: productionUnPickInboxListReducer,
  saveProductionUnPickInboxSuccessMessage: saveProductionUnPickInboxReducer,
  productionPickInboxScanItem: productionPickInboxScanItemReducer,
  allBinLocationsList: allBinLocationsListReducer,
  allItemsInBin: allItemsInBinReducer,
  binLocationByBarcode: binLocationByBarcodeReducer,
  shippmentAttachmentList: shippmentAttachmentListReducer,
  vendorPalletBarcode: vendorPalletBarcodeReducer,
  vendorInnerCartons: vendorInnerCartonsReducer,
  vendorMasterCartons: vendorMasterCartonsReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.

  if (action.type === resetActions.RESET_ALL_STATES) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
