import { isObjectNotEmpty } from "../../common/utils";
import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const stockItemInfoReducer = (state = {}, action) => {
  const actionName = serverApis.getStockItemInfo.actionName;
  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return isObjectNotEmpty(action.json.catalog_items)
        ? action.json.catalog_items[0]
        : {};

    case getResetAction(actionName):
      return {};
    default:
      return state;
  }
};
