import {
  getActionNameWithStatus,
  getResetAction,
} from "../../constants/actions-methods";
import { requestStatus } from "../../constants/request";
import { serverApis } from "../../constants/server-apis";

export const shipmentLineListReducer = (state = [], action) => {
  const actionName = serverApis.getShipmentLineList.actionName;
  const actionNameOpenPurchase = serverApis.getOpenPurchaseLineList.actionName;

  switch (action.type) {
    case getActionNameWithStatus(actionName, requestStatus.SUCCESS):
      return action.json.po_lines;

    case getActionNameWithStatus(actionNameOpenPurchase, requestStatus.SUCCESS):
      return action.json.orders;

    case getResetAction(actionName):
    case getResetAction(actionNameOpenPurchase):
      return [];
    default:
      return state;
  }
};
