import React from "react";
import classNames from "classnames";
import { Table } from "antd";
import { Resizable } from "react-resizable";

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

class CustomTable extends React.Component {
  components = {
    header: {
      cell: ResizeableTitle,
    },
  };
  handleResize = (index) => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const classes = classNames(this.props.className, "common-table");
    const columns = this.props.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    return (
      <Table
        className={classes}
        rowKey={this.props.rowKey}
        components={this.components}
        rowSelection={this.props.rowSelection}
        rowClassName={this.props.rowClassName}
        onChange={this.props.onChange}
        columns={columns}
        dataSource={this.props.dataSource}
        onRow={(record) => ({
          onClick: () => {
            if (this.props.onRow) {
              this.props.onRow(record);
            }
          },
        })}
        scroll={this.props.scroll}
        bordered={true}
        loading={this.props.loading}
        pagination={this.props.pagination}
        showSorterTooltip={false}
        tableLayout={"fixed"}
        expandable={this.props.expandable}
      />
    );
  }
}

export default CustomTable;
